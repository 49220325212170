<template>
    <plain-admin title="" :withMenu="false" x-class="w-full pb-20 pt-2">
        <div class="md:h-[80vh] w-full md:mx-auto md:w-2/3 flex justify-center">
            <form @submit.prevent="signup($event)">
                <div class="bg-white rounded-xl py-4 px-8 text-black">
                    <h3 class="text-xl text-center mb-6">Meld je aan</h3>

                    <div class="mb-6" :class="{hidden: started}">
                        Hallo, <br /><br />
Leuk dat je mee wilt doen met onze vragenlijst over bedrijfje spelen. Jij bent benieuwd of jouw bedrijf bedrijfje speelt. En dat ten opzichte van andere bedrijven in NL. Dat gaat lukken. Het enige wat je hoeft te doen is onderstaande vragenlijst in te vullen. Jouw naam en e-mailadres blijven alleen voor ons zichtbaar. Wij gebruiken je mailadres om jou de uitslag toe te sturen hoe jouw bedrijf scoort ten opzichte van andere bedrijven. En je naam om je persoonlijk aan te spreken. Als er meer collega's van jou de vragenlijst invullen (met dezelfde bedrijfsnaam dus), dan bundelen we de scores rondom jullie bedrijf en pakken het gemiddelde. Dus leuk om je collega's uit te nodigen om deze vragenlijst ook in te vullen. That's it. Verder geen addertjes of prijsjes of aanbiedingen. Wij vinden dit interessant. En jij hopelijk ook. <br/><br />
Het duurt 10-15 minuten om de vragenlijst in te vullen. Succes.

                        <div class="flex justify-center">
                            <x-button @click="() => started = true" xClass="mx-auto mt-6">Start</x-button>
                        </div>
                    </div>

                    <template v-if="started">
                        <div class="mb-4">
                            <label class="block" :class="{ 'text-red-500': error['name']}">Voor- en achternaam</label>
                            <input
                                v-model="name"
                                placeholder="Voor- en achternaam"
                                class="block bg-gray-100 rounded-full w-input h-input py-2 px-4"
                                id="name"
                            />
                            <span class="text-red-700 italic" v-if="error['name']">
                                <p v-for="(msg, idx) in error['name']" :key="idx">{{ msg }}</p>
                            </span>
                            <span class="italic">Je naam wordt dus niet gebruikt of publiekelijk getoond, alleen voor ons om je persoonlijk aan te spreken.</span>
                        </div>
                        <div class="mb-4">
                            <label class="block" :class="{ 'text-red-500': error['name']}">E-mailadres</label>
                            <input
                                v-model="email"
                                placeholder="E-mailadres"
                                class="block bg-gray-100 rounded-full w-input h-input py-2 px-4"
                                id="email"
                            />
                            <span class="text-red-700 italic" v-if="error['email']">
                                <p style="margin: 0;" v-for="(msg, idx) in error['email']" :key="idx">{{ msg }}</p>
                            </span>
                            <span class="italic">Je e-mailadres wordt alleen gebruikt om jou een mail met de resultaten toe te sturen, verder krijgt niemand het te zien.</span>
                        </div>

                        <div class="mb-4">
                            <label class="block" :class="{ 'text-red-500': error['name']}">Bedrijfsnaam</label>
                            <input
                                v-model="company"
                                placeholder="Bedrijfsnaam"
                                class="block bg-gray-100 rounded-full w-input h-input py-2 px-4"
                                id="company"
                            />
                            <span class="text-red-700 italic" v-if="error['company']">
                                <p style="margin: 0;" v-for="(msg, idx) in error['company']" :key="idx">{{ msg }}</p>
                            </span>
                            <span class="italic">Jouw bedrijfsnaam gebruiken we dus ter vergelijk met anderen. Als collega's ook de vragenlijst invullen, dan bundelen we de resultaten voor jouw bedrijf.</span>
                        </div>
                        <div class="card-footer text-center">
                            <x-button @click="signup" x-class="md:inline-block mb-2 md:mb-0 md:mr-2">Aanmelden</x-button>
                        </div>
                    </template>
                </div>
            </form>
        </div>
    </plain-admin>
</template>

<script>
import axios from 'axios'
export default {
    name: 'sentiment-register',

    data: () => ({
        name: null,
        email: null,
        company: null,

        error: {
            name: null,
            email: null,
            company: null,
        },

        started: false
	}),

    beforeRouteEnter: function (route, from, next) {
        if ('7YYkqyDE1FUPL5TS' != route.query.token)
            next('404')

        next()
    },

    methods: {
        signup(event) {
            event.preventDefault()
            let data = {
                name: this.name,
                email: this.email,
                company: this.company,
            }

            axios.post(this.$appSettings.API_URL + 'meeting/signup?token=7YYkqyDE1FUPL5TS', data).then((response) => {
                if (201 == response.status) {
                    this.$store.setAt(response.data.at, 3600)
                    this.$store.setUser(response.data.user)
                    this.$root.msg = 'Bedankt voor het aanmelden!'
                    this.$root.getSettings()
                    this.$router.push({name: 'sentiment-dashboard', query: {
                        go: 1
                    }})
                }
            }, (error) => {
                console.log(error.response.data)
                if (typeof error.response.data.errors == 'object') {
                    Object.entries(error.response.data.errors).map((el) => {
                        this.error[el[0]] = el[1]
                    })
                }
            })
        }
    }
}
</script>