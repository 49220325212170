import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=4b50c6be&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=js"
export * from "./Profile.vue?vue&type=script&lang=js"
import style0 from "./Profile.vue?vue&type=style&index=0&id=4b50c6be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b50c6be",
  null
  
)

export default component.exports