<template>
    <button
        @click.prevent="click"
        class="ml-auto bottom-10 h-10 md:h-12 font-semibold text-base text-white block box-border rounded-lg bg-sentiment-blue px-4 min-w-button hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200"
        :class="xClass"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'x-button',

    props: ['x-class'],

    methods: {
        click: function ($event) {
            this.$emit('click', $event)
        }
    }
}
</script>