import Vue from 'vue'

import FormField from './FormField'
import Additional from './Additional'
import Text from './Text'
import Smiley from './Smiley'
import TextArea from './TextArea'
import Select from './Select'
import Radio from './Radio'
import Checkbox from './Checkbox'
import Number from './Number'
import File from './File'
import Group from './Group'
import Employees from './Employees'
import Scale from './Scale'


Vue.component('FormField', FormField)
Vue.component('AdditionalField', Additional)
Vue.component('TextField', Text)
Vue.component('SmileyField', Smiley)
Vue.component('TextareaField', TextArea)
Vue.component('SelectField', Select)
Vue.component('RadioField', Radio)
Vue.component('CheckboxField', Checkbox)
Vue.component('NumberField', Number)
Vue.component('UploadField', File)
Vue.component('GroupField', Group)
Vue.component('EmployeesField', Employees)
Vue.component('ScaleField', Scale)

