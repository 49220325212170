<template>
  <Admin :title="isNew ? 'Nieuw bedrijf toevoegen' : (company != null ? `${company.name} bewerken` : 'Bedrijvenbeheer')" :hasTooltips="true" x-class="relative">
    <template>
      <div>
        <x-organisation-card :org="company" v-if="company !== null" />
        <x-admin-card :admin="company.admin" v-if="company !== null" x-class="mt-10" />
      </div>

      <!--Buttons for creating comnpany-->
      <div class="fixed right-6 bottom-6 w-max" v-if="isNew">
        <x-button-blue @click="$router.push({name: 'toscani-company-index'})" x-class="[@media(min-width:885px)]:inline-block mb-2 [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-4">Annuleren</x-button-blue>
        <x-button-green @click="submit" x-class="[@media(min-width:885px)]:inline-block [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-2">Opslaan</x-button-green>
      </div>

      <!--Buttons for editing comnpany-->
      <div class="[@media(min-width:450px)]:flex [@media(min-width:450px)]:justify-end [@media(max-width:884px)]:-mt-[140px] -mt-[95px]" v-if="!isNew">
        <div class="w-full [@media(min-width:450px)]:w-max mt-10 [@media(min-width:450px)]:flex [@media(min-width:450px)]:justify-end">
          <x-button-blue @click="$router.push({name: 'toscani-company-index'})" x-class="[@media(min-width:450px)]:inline-block mb-2 [@media(min-width:450px)]:mb-0 [@media(min-width:450px)]:mr-4">Annuleren</x-button-blue>
          <x-button-green @click="submit" x-class="[@media(min-width:450px)]:inline-block [@media(min-width:450px)]:mb-0">Opslaan</x-button-green>
        </div>
      </div>

      <!--Settings only show when editing a company-->
      <div>
        <x-settings-card :settings="company.settings" x-class="mt-6" v-if="!isNew" />
      </div>
    </template>

  </Admin>
</template>

<script>
import axios from "axios"
import Loader from "@/components/Loader"

export default {
  name: "toscani-company-edit",

  data() {
    return {
      isNew: false,
      errors: {
        logo: undefined,
        name: undefined,
        'address.street': undefined,
        'address.number': undefined,
        'address.postcode': undefined,
        'address.city': undefined,
      },
      logo: null,
      company: {
        admin: {
          firstname: null,
          lastname: null,
          email: null,
        },
        company_id: null,
        name: null,
        street: null,
        number: null,
        postcode: null,
        city: null,
        email: null,
        phone: null,
        projects: [],
        employees: [],
      },
      newEmployee: {

      },
      loading: [
        false,
        false,
        false,
        false,
        false,
      ],
    };
  },

  components: {
    Loader
  },

  created: function () {
    let _this = this;
    this.reader = new FileReader();

    this.reader.onloadend = function () {
      _this.logo = _this.reader.result;
    };

//    axios.get('/pillar').then(response => _this.pillars = response.data)

    if (this.$route.params.id != "create") {
      axios.get(`toscani/company/${this.$route.params.id}`).then(
        (response) => {
          this.company = response.data;
          this.logo = this.company.logo;
          this.loading = Object.keys(this.company.settings).fill(false)

          if ('undefined' == typeof response.data.address || null === response.data.address) {
            this.company.address = {
              street: null,
              number: null,
              postcode: null,
              city: null,
            }
          }
        }, () => {
          console.error('Something went wrong!', 'Toscani/Company/Edit')
        }
      );
    } else {
      this.isNew = true;
    }
  },

  computed: {
    filteredOrganizations: function () {
      let organizations = [];
      for (let idx in this.organizations) {
        organizations.push(this.organizations[idx]);
      }
      return organizations;
    },

    avatar: function () {
      let style = {
        backgroundImage: 'url("/assets/img/avatar-np.jpg")'
      }

      if (this.logo) {
        style.backgroundImage = `url(${this.logo}`
      }

      return style
    },

    isLoading: function () {
        return this.loading
    },

    user: function () {
      return this.company.employees.length > 0 ? this.company.employees[0] : {}
    }
  },

  methods: {
    upload(event, idx) {
      this.$refs['setting-file-' + idx][0].click()
    },

    // isLoading(idx) {
    //     console.log(idx + ' loading?')
    //     return this.loading[idx] !== undefined && this.loading[idx]
    // },

    // previewImg: function () {
    //   let file = this.$refs.file.files[0];
    //   if (file) {
    //     this.reader.readAsDataURL(this.$refs.file.files[0]);
    //   }
    // },

    organizationCreated: function () {
      this.$notify({
        type: "success",
        title: "Organisatie aangemaakt",
      });

      this.$router.push({name: 'toscani-company-index'})
    },

    organizationUpdated: function () {
      this.$notify({
        type: "success",
        title: "Organisatie geüpdatet",
      });

      this.$router.push({name: 'toscani-company-index'})
    },

    buildFormData: function (formData, data, parent = "") {
      for (let key in data) {
        if (typeof data[key] == "object") {
          this.buildFormData(formData, data[key], key);
        } else {
          formData.append(parent != "" ? `${parent}[${key}]` : key, data[key]);
        }

      }
    },

    submit: function (toIndex = true) {
      let data = Object.assign({}, this.company)

      if (data.projects && typeof data.projects[0] != 'number')
        data.projects = data.projects.map(el => el.id)

      if (data.employees && typeof data.employees[0] != 'number')
        data.employees = data.employees.map(el => el.id)

      data.employees.push(this.newEmployee)

      if (data.company_id === null) {
        delete data.company_id
      }

      if (this.isNew) {
        return axios.post("toscani/company", data).then((response) => {
          if (toIndex)
            this.organizationCreated(response)
          return response
        }, this.error);
      } else {
        return axios.put(`toscani/company/${this.company.id}`, data).then((response) => {
          if (toIndex)
            this.organizationUpdated(response)
          return response
        }, this.error);
      }
    },

    error: function (error) {
      if (413 == error.response.status) {
        this.errors.logo = 'Logo is te groot om te uploaden. Verklein het logo, of kies wat anders.'
        this.logo = null
      }
      else
        this.errors = Object.assign({logo: undefined}, error.response.data.errors);
    },

    findProject(term, loading) {
      let params = new URLSearchParams()
      params.append('forOrg', true)
      params.append('filter[name]', term)
      for (let idx in this.company.pillars) {
        params.append('filter[pillar][]', this.company.pillars[idx].id ?? this.company.pillars[idx])
      }
      loading(true)
      axios.get(`/admin/project`, {params: params}).then(response => {
        this.projects = response.data
        loading(false)
      })
    },

    addProject(project) {
      this.company.projects.push(project)
    },

    removeProject(project) {
      let idx = this.company.projects.findIndex(el => el.id == project.id)
      if (confirm(`Weet je zeker dat deze organisatie geen toegang meer mag hebben tot ${project.name}?`))
        this.company.projects.splice(idx, 1)
    },

    findUser(search, loading) {
      loading(true)

      if ('' == search) {
        this.users = [{
          id: -1,
          name: 'Nieuwe gebruiker aanmaken' +
            '<br><small>Organisatie word eerst opgelagen</small>'
        }]

        loading(false)
        return
      }

      let params = new URLSearchParams()
      params.append('filter[name]', search)
      params.append('filter[userGroup]', 'instantiemedewerker')

      axios.get('/toscani/user', {params: params}).then(response => {
        this.users = response.data.data
        loading(false)
      })
    },

    addUser(user) {
      if (-1 == user.id) {
        this.submit(false).then(response => {
          let company_id = response.data.id
          this.$router.push({
            name:'user-edit', params: {
              id: 'create'
            }, query: {
              company: company_id
            }
          })
        })
      }
      this.company.newEmployees.push(user)
    },

    removeUser(user) {
      let idx = this.company.employees.findIndex(el => {return el.id == user.id})

      if (-1 == idx) {
        idx = this.company.newEmployees.findIndex(el => {return el.id == user.id})

        this.company.newEmployees.splice(idx, 1)
      }

      if (confirm(`Weet je zeker dat ${user.name} niet meer bij deze organisatie hoord?`)) {
        axios.delete(`/admin/company/${this.company.id}/employee/${user.id}`).then(response => {
          if (204 == response.status)
            this.company.employees.splice(idx, 1)
        })
      }
    },

    updateSetting: function (event, idx) {
      this.setLoading(idx)
      let setting = this.company.settings[idx],
          url = `toscani/company/${setting.company_id}/setting/${setting.key}`,
          val = setting.value

      console.log(setting, url, val)
      if ('file' == setting.type) {
        let formData = new FormData()
        formData.append('file', this.$refs['setting-file-' + idx][0].files[0])
        formData.append('_method', 'PUT')

        axios.post(url, formData).then(() => {
          this.setLoading(idx, false)
        })
        return;
      }

      axios.put(url, {value: val}).then(() => {
        this.setLoading(idx, false)
      })
    },

    setLoading: function (idx, state = true) {
        this.$refs[`loader-${idx}`][0].loading = state
    }
  },
};
</script>

<style scoped>
table tr:not(tr:first-child) {
  border-top: solid 1px #ccc;
}
</style>