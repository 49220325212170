<template>
  <div id="app" class="w-full h-full">
    <router-view></router-view>
    <notifications />
  </div>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
      settings: {},
	}),

  watch: {
    '$root.settings': function (val) {
      this.settings = val
    }
  }
}
</script>