<script>
import settings from '@/AppSettings'
import MainMenu from './MainMenu';
import SuperAdminMenu from './SuperAdminMenu';

export default {
  functional: true,

  render: function (createElement, context) {
    return createElement('div', {
      class: [
        'md:flex',
        'hidden',
        'flex-col',
        'justify-between',
        'h-full'
      ]
    }, [
        createElement('mainMenu', {
          props: {
            'closeMenu': context.props.closeMenu //self.closeMenu
          }
        }),
        createElement('superAdminMenu')
      ]
    )
  }
  ,

  components: {
    'mainMenu': MainMenu,
    'superAdminMenu': SuperAdminMenu
  },

  name: 'Menu',

  data: () => ({
      roles: settings.roles
	}),

  methods: {
  }
}
</script>