import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'

import router from './router'
import store from './plugins/store'
import AppSettings from './plugins/appSettings'
import translations from './translations'
import Settings from './AppSettings'
import axios from 'axios'
import vSelect from 'vue-select'
import VueSelectComponents from 'vue-select/src/components/childComponents'
import VModal from 'vue-js-modal'
import VueTippy, { TippyComponent } from 'vue-tippy'
import TailwindPagination from './components/TailwindPagination';

/** layout */
import BaseLayout from './layout/BaseLayout'
import Admin from './layout/Admin'
import PlainAdmin from './layout/PlainAdmin'
import FooterComponent from './layout/Footer'
import Menu from './components/Menu'
import MainMenu from './components/MainMenu'
import SuperAdminMenu from './components/SuperAdminMenu'
import AppLayout from './layout/App'
import Loader from '@/components/Loader'

/** FormComponents */
import '@/components/formComponents'
import '@/components/design'
import OpenIndicator from '@/components/design/OpenIndicator'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-select/dist/vue-select.css';
import "tippy.js/themes/light.css";

import moment from 'moment'
import methods from './mixins/methods'
import ClickOutside from './directives/ClickOutside'
import './css/tailwind.css'

Vue.config.productionTip = false

axios.defaults.baseURL = Settings.API_URL
moment.locale('nl')

Vue.use(store)
Vue.use(AppSettings)
Vue.use(translations)
Vue.use(Notifications)
Vue.use(VModal, { dynamic: true, dynamicDefaults: { adaptive: true, clickToClose: true, }, injectModalsContainer: true })
Vue.use(VueTippy)

vSelect.props.components.default = () => ({
  OpenIndicator: OpenIndicator
})

vSelect.props.clearable.default = () => false

Vue.component('v-select', vSelect)
Vue.component('BaseLayout', BaseLayout)
Vue.component('Admin', Admin)
Vue.component('PlainAdmin', PlainAdmin)
Vue.component('AppLayout', AppLayout)
Vue.component('FooterComponent', FooterComponent)
Vue.component('mainMenu', MainMenu)
Vue.component('superAdminMenu', SuperAdminMenu)
Vue.component('Menu', Menu)
Vue.component('Pagination', TailwindPagination)
Vue.component('tippy', TippyComponent)
Vue.component('Loader', Loader)

Vue.mixin(methods)
Vue.directive('click-outside', ClickOutside)
Vue.filter('capitalize', (value) => {
  if (value.length > 0)
    return value.charAt(0).toUpperCase() + value.slice(1)

  return value
})

let settingTranslations = {
  logo: 'logo',
  primary: 'eerste kleur',
  secondary: 'tweede kleur',
  background_image: 'achtergrond afbeelding',
  background_color: 'achtergrond kleur',
  default_password: 'standaard wachtwoord',
  feature_salary_active: 'vergoedingsbepaling actief',
  feature_salary_form_id: 'vergoedingsbepaling formulier',
  feature_salary_duration: 'vergoedingsbepaling periode duur',
}

Vue.filter('translateSetting', (key) => {
  if (typeof settingTranslations[key] !== 'undefined')
    return settingTranslations[key]

  return key
})

window.app = new Vue({
  router,
  render: h => h(App),
  data: () => ({
      msg: null,
      pusher: null,
      showTooltipLayer: false,
      settings: {},
      bus: null,
	}),

  watch: {
    settings: function (newVal, oldVal) {
      /**
        * Set Background color
        */
      if (typeof newVal.background_color !== 'undefined' && newVal.background_color !== null) {
        if (typeof newVal.background_image == 'undefined' || null == newVal.background_image) {
          newVal.background_image = 'none'
        }

        document.documentElement.style.setProperty('--background-color', newVal.background_color)
      }

      /**
       * Set background image
       */
      // if (typeof this.$route.meta.noBg !== 'undefined' && this.$route.meta.noBg === true) {
      //   document.documentElement.style.removeProperty('--background-image')
      // } else {
        if (typeof newVal.background_image === 'undefined' || newVal.background_image === null)
          // not set, use default
          newVal.background_image = 'url(/assets/img/background.png)'
        else if('none' == newVal.background_image) {
          // set property to none
          document.documentElement.style.setProperty('--background-image', 'none')
        } else if(!/^url/.test(newVal.background_image))
          // missing url()
          newVal.background_image = 'url(' + Settings.API_URL.slice(0, -4) + 'storage/' + newVal.background_image + ')'

        document.documentElement.style.setProperty('--background-image', newVal.background_image)
      // }

      /**
       * Set logo
       */
      if (typeof newVal.logo === 'undefined' || newVal.logo === null)
        // not set, use default
        newVal.logo = '/assets/img/sentiment-logo.png'

      else if(! /^http/.test(newVal.logo)) {
        newVal.logo =  Settings.API_URL.slice(0, -4) + 'storage' +
        ('/' !== newVal.logo.charAt(0) ? '/' : '') + newVal.logo

        newVal.logo.replace('//', '/')
      }

      /**
        * Set Primary color
        */
      if (typeof newVal.primary === 'undefined' || newVal.primary === null || newVal.primary == '')
        // not set, use default
        newVal.primary = '#18AA7D'

      document.documentElement.style.setProperty('--primary', newVal.primary)

      /**
        * Set Secondary color
        */
      if (typeof newVal.secondary === 'undefined' || newVal.secondary === null || newVal.secondary == '')
        // not set, use default
        newVal.secondary = '#0A0A49'

      document.documentElement.style.setProperty('--secondary', newVal.secondary)

    }
  },

  created: function () {
    this.settings = {}

    // Request interceptor for API calls
    axios.interceptors.request.use(
      (config) => {
        config.headers = {
          'Authorization': `Bearer ${Vue.store.at}`,
          'Accept': 'application/json',
        }
        return config;
      },
      error => {
        Promise.reject(error)
    });

    // Response interceptor for API calls
    axios.interceptors.response.use((response) => {
      if( response.headers.authorization ){
        Vue.$store.setAt(response.headers.authorization.replace('Bearer ', ''), 3600)
      }

      if (201 == response.status && typeof response.data.messages != 'undefined') {
        this.$notify({
          text: response.data.messages.join('<br>'),
          duration: -1,
        })
      }

      return response
    }, (error) => {
      const originalRequest = error.config;
      if (! originalRequest._retry && originalRequest.url != '/auth/login' && typeof error.response != 'undefined' && error.response.status == 401) {
        originalRequest._retry = true;
        originalRequest.headers['Authorization'] = `Bearer ${this.$store.at}`
        return axios(originalRequest);
      }

      if (originalRequest.url != '/auth/login' && '/login' != this.$router.currentRoute.path && (typeof error.response != 'undefined' && (error.response.status === 403 || error.response.status == 401))) {
        this.$router.push('/login?redirect=' + this.$router.currentRoute.path);
      }

      let msg = 'Er is iets fout gegaan'

      if (typeof error.response != 'undefined' && 500 == error.response.status) {
        this.msg = 'Er is iets fout gegaan, neem contact op met de ontwikkelaar'
      }

      if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
        this.msg = error.response.data.message
      }

      if (typeof error.response != 'undefined' && error.response.status == 422 && typeof error.response.data.messages != 'undefined') {
        this.$notify({
          text: error.response.data.messages.join('<br>'),
          duration: -1,
        })
        return Promise.reject(error);
      }

      if (typeof error.response != 'undefined' && error.response.status == 422 && typeof error.response.data.message != 'undefined') {
        this.$notify({
          type: 'error',
          text: error.response.data.message,
        })
        return Promise.reject(error);
      }

      if (typeof error.response != 'undefined' && 404 == error.response.status) {
        this.$router.replace('/')
        this.$notify({
          type: 'error',
          // title: 'Er is iets fout gegaan',
          text: msg,
        })
        return Promise.reject(error);
      }

      this.$notify({
        type: 'error',
        title: 'Er is iets fout gegaan',
        text: msg,
      })

      return Promise.reject(error);
    });

    if (Vue.store.isLoggedIn) {
      this.getSettings()
    }
  },

  methods: {
    getSettings: function () {
      if (Vue.store.isLoggedIn) {
        axios.get('common/settings').then(response => {
          let val = response.data

          this.settings = val
        }).catch(error => {
           //drop error
        })
      }
    },

    logoff: function () {
      if (confirm('Weet je het zeker?'))
        this.$store.logoff()
    }
  }
}).$mount('#app')
