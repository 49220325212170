import Vue from 'vue'

import Form from './Form'
import RequestPassword from './RequestPassword'
import ResetPassword from './ResetPassword'
import Button from './Button'
import ButtonGreen from './ButtonGreen'
import ButtonBlue from './ButtonBlue'
import NavItem from './NavItem'
import NavItemSub from './NavItemSub'
import Icon from './Icon'
import ListItem from './ListItem'

// cards
import RegisterCard from './RegisterCard'
import RegisterAdminCard from './RegisterAdminCard'
import RegisteredCard from './RegisteredCard'
import UserCard from './UserCard'
import DepartmentsCard from './DepartmentsCard'
import EmployeesCard from './EmployeesCard'
import PeriodCard from './PeriodCard'
import CompensationCard from './CompensationCard'
import OrganisationCard from './OrganisationCard'
import AdminCard from './AdminCard'
import SettingsCard from './SettingsCard'

Vue.component('x-form', Form)
Vue.component('x-request-password', RequestPassword)
Vue.component('x-reset-password', ResetPassword)
Vue.component('x-button', Button)
Vue.component('x-button-green', ButtonGreen)
Vue.component('x-button-blue', ButtonBlue)
Vue.component('x-nav-item', NavItem)
Vue.component('x-nav-item-sub', NavItemSub)
Vue.component('x-icon', Icon)
Vue.component('x-list-item', ListItem)

// card components
Vue.component('x-register-card', RegisterCard)
Vue.component('x-register-admin-card', RegisterAdminCard)
Vue.component('x-registered-card', RegisteredCard)
Vue.component('x-user-card', UserCard)
Vue.component('x-departments-card', DepartmentsCard)
Vue.component('x-employees-card', EmployeesCard)
Vue.component('x-period-card', PeriodCard)
Vue.component('x-compensation-card', CompensationCard)
Vue.component('x-organisation-card', OrganisationCard)
Vue.component('x-admin-card', AdminCard)
Vue.component('x-settings-card', SettingsCard)