<template>
    <button
        @click.prevent="click"
        class="border-sentiment-green border-solid ml-auto bottom-10 h-10 font-semibold text-base text-sentiment-blue block box-border rounded-lg border-4 bg-white px-4 min-w-button hover:bg-sentiment-green hover:text-white ease-in-out duration-200"
        :class="xClass"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'x-button',

    props: ['x-class'],

    methods: {
        click: function ($event) {
            this.$emit('click', $event)
        }
    }
}
</script>