<template>
    <div class="box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl p-8 overscroll-none bg-white text-black" :class="xClass">
        <div class="grid [@media(min-width:1310px)]:grid-cols-3 gap-6">
          <div class="mb-4 lg:mr-4">
            <label for="firstname" class="font-bold text-base text-monsterrat mb-2 mr-2 md:mr-auto block">Voornaam *</label>
            <input type="text" id="firstname" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="admin.firstname">
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="lastname" class="font-bold text-base text-monsterrat mb-2 mr-2 md:mr-auto block">Achternaam *</label>
            <input type="text" id="lastname" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="admin.lastname">
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="email" class="font-bold text-base text-monsterrat mb-2 mr-2 md:mr-auto block">E-mailadres *</label>
            <input type="text" id="email" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="admin.email">
          </div>
        </div>
      </div>
</template>

<script>
export default {
    props: {'admin': {
      default: () => ({
        firstname: null,
        lastname: null,
        email: null,
      })
    },
    'x-class': {}
  }
}
</script>