<template>
  <PlainAdmin>
    <template slot="content">
      <div class="container">
        <div class="row">
          Jij mag hier niet komen!
        </div>
      </div>
    </template>
  </PlainAdmin>
</template>

<script>
export default {
  created: function () {
    // this.$store.logoff()
  }
}
</script>