<template>
    <svg v-if="icon == 'dashboard'" width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.52 20.407H25.6509V3.5834H2.86911V20.407H0V0.753906H28.52V20.407Z" fill="currentColor"/>
        <path d="M21.8292 24.8045V21.1909H13.9006V24.8045H0V27.634H28.52V24.8045H21.8292Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'chart'" width="29" height="36.25" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28 21.7266H0V0.808594H3.1472V18.5861H28V21.7266Z" fill="currentColor"/>
        <path d="M27.5184 1.57281C24.0688 2.96735 19.3872 4.25589 15.8256 4.34514L19.8464 6.44252L15.8368 10.1018L10.64 5.36036L4.76001 11.1058L6.96641 13.3427L10.7128 9.67783L15.8368 14.3523L21.9744 8.75744L23.716 12.9299C24.1192 9.4045 25.8216 4.86949 27.5184 1.57281Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'calendar'" width="29" height="36.25" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.00561523 9.96436H24V24.6069C24 26.4351 22.5955 27.9122 20.8608 27.9122H3.14481C1.41014 27.9122 0.00561523 26.4295 0.00561523 24.6069V9.96436Z" fill="currentColor"/>
        <path d="M22.5451 3.19215H18.6561V0.00390625H15.3211V3.19215H7.84519V0.00390625H4.51014V3.19215H1.46048C0.654698 3.19215 0 3.91675 0 4.81414V8.43715H23.9944V4.81972C24 3.92232 23.3509 3.19215 22.5451 3.19215Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'building'" width="29" height="36.25" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.2362 27.5844V0.199219H2.05481V27.5844H0V29.1905H24V27.5844H22.2362ZM13.3767 3.88692H18.633V7.69058H13.3767V3.88692ZM13.3767 9.11695H18.633V12.9206H13.3767V9.11695ZM13.3767 14.2948H18.633V18.0985H13.3767V14.2948ZM5.97235 3.88692H11.2287V7.69058H5.97235V3.88692ZM5.97235 9.11695H11.2287V12.9206H5.97235V9.11695ZM5.97235 14.2948H11.2287V18.0985H5.97235V14.2948ZM9.51734 21.7282H14.7737V27.5844H9.51734V21.7282Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'users'" width="29" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_37_775)">
            <path d="M23.4697 12.9617C24.9543 12.9617 26.1577 11.7629 26.1577 10.2842C26.1577 8.80545 24.9543 7.60669 23.4697 7.60669C21.9852 7.60669 20.7817 8.80545 20.7817 10.2842C20.7817 11.7629 21.9852 12.9617 23.4697 12.9617Z" fill="currentColor"/>
            <path d="M4.53053 12.9617C6.01507 12.9617 7.21853 11.7629 7.21853 10.2842C7.21853 8.80545 6.01507 7.60669 4.53053 7.60669C3.04599 7.60669 1.84253 8.80545 1.84253 10.2842C1.84253 11.7629 3.04599 12.9617 4.53053 12.9617Z" fill="currentColor"/>
            <path d="M6.00319 19.8172C5.60559 16.6321 8.29358 14.1108 10.8976 14.9643C11.6928 15.2264 12.6504 15.4161 13.7312 15.3938C14.7056 15.3715 15.568 15.1874 16.296 14.942C18.7544 14.1108 21.3192 16.4202 21.2016 19.4658C21.1848 19.8953 21.1176 20.3193 21 20.7265C20.8208 21.3456 20.5352 21.8532 20.2216 22.266C19.4544 23.2701 18.3232 23.761 17.1864 23.761H10.0744C8.61838 23.761 7.19598 22.9354 6.46798 21.4293C6.32238 21.1225 6.19358 20.7878 6.10398 20.4141C6.06478 20.2133 6.02559 20.0125 6.00319 19.8172Z" fill="currentColor"/>
            <path d="M7.06177 16.1579C7.13457 16.0743 7.21297 15.985 7.29137 15.9069C7.21297 15.9906 7.13457 16.0743 7.06177 16.1579Z" fill="currentColor"/>
            <path d="M6.97777 16.2584C6.87697 16.3811 6.78737 16.5094 6.70337 16.6433C6.78737 16.5038 6.87697 16.3755 6.97777 16.2584Z" fill="currentColor"/>
            <path d="M8.85935 14.9531C8.76415 14.981 8.66895 15.0144 8.57935 15.0479C8.67455 15.0088 8.76975 14.981 8.85935 14.9531Z" fill="currentColor"/>
            <path d="M5.77925 14.9308C6.32805 14.3116 6.98885 13.8319 7.70005 13.5028C7.22405 13.2462 6.68085 13.1737 6.13765 13.3577C5.70645 13.5028 5.19125 13.6143 4.61445 13.6255C3.97045 13.6366 3.40485 13.5251 2.92885 13.3689C1.38325 12.8613 -0.212751 14.3618 0.0224492 16.2528C0.0392492 16.3699 0.0560492 16.4871 0.0840493 16.6042C0.140049 16.8273 0.212849 17.0281 0.296849 17.2066C0.728049 18.0991 1.57365 18.59 2.44165 18.59H4.21685C4.34005 17.2345 4.87765 15.946 5.77925 14.9308Z" fill="currentColor"/>
            <path d="M6.0648 18.2051C6.0424 18.3111 6.0256 18.4171 6.0144 18.5231C6.0312 18.4171 6.048 18.3111 6.0648 18.2051Z" fill="currentColor"/>
            <path d="M6.38394 17.2289C6.33914 17.3238 6.29994 17.4186 6.26074 17.519C6.29994 17.4186 6.34474 17.3238 6.38394 17.2289Z" fill="currentColor"/>
            <path d="M13.5856 14.267C16.0846 14.267 18.1104 12.2491 18.1104 9.75987C18.1104 7.27066 16.0846 5.25275 13.5856 5.25275C11.0866 5.25275 9.06079 7.27066 9.06079 9.75987C9.06079 12.2491 11.0866 14.267 13.5856 14.267Z" fill="currentColor"/>
            <path d="M19.7512 15.7842C19.8296 15.8568 19.8968 15.9293 19.9696 16.0074C19.9024 15.9293 19.8296 15.8512 19.7512 15.7842Z" fill="currentColor"/>
            <path d="M20.3449 16.4648C20.2553 16.3365 20.1601 16.2193 20.0649 16.1022C20.1657 16.2193 20.2553 16.342 20.3449 16.4648Z" fill="currentColor"/>
            <path d="M19.0457 15.2543C19.1241 15.299 19.1969 15.3492 19.2753 15.3994C19.1969 15.3436 19.1241 15.299 19.0457 15.2543Z" fill="currentColor"/>
            <path d="M25.0825 13.3578C24.6513 13.5028 24.1361 13.6144 23.5593 13.6255C22.9153 13.6367 22.3497 13.5251 21.8737 13.3689C21.1905 13.1458 20.5017 13.3131 19.9585 13.7315C20.3953 13.9881 20.8041 14.3116 21.1793 14.6965C22.1817 15.734 22.7921 17.1174 22.9377 18.5956H25.6145C26.2921 18.5956 26.9641 18.3056 27.4177 17.7087C27.6025 17.4633 27.7705 17.162 27.8825 16.7939C27.9553 16.554 27.9945 16.303 28.0001 16.0464C28.0673 14.2391 26.5441 12.8613 25.0825 13.3578Z" fill="currentColor"/>
            <path d="M21.0953 18.233C21.0729 18.1326 21.0505 18.0267 21.0225 17.9318C21.0505 18.0322 21.0729 18.1326 21.0953 18.233Z" fill="currentColor"/>
            <path d="M20.9665 17.7477C20.9385 17.6529 20.8993 17.5525 20.8657 17.4633C20.9049 17.5525 20.9385 17.6473 20.9665 17.7477Z" fill="currentColor"/>
            <path d="M20.7985 17.2903C20.7537 17.1899 20.7089 17.0951 20.6641 17.0059C20.7089 17.0951 20.7593 17.1899 20.7985 17.2903Z" fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_37_775">
            <rect width="28" height="27.8906" fill="white" transform="translate(0 0.555969)"/>
        </clipPath>
        </defs>
    </svg>

    <svg v-else-if="icon == 'clipboard'" width="29" height="45.79" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.7375 4.62253H13.0759C13.2148 2.61517 11.7845 0.79816 9.91982 0.63088C8.98748 0.555892 7.99724 0.913526 7.25021 1.6461C6.45686 2.42482 6.03991 3.50349 6.10361 4.62253H1.26821C0.567508 4.62253 0 5.18782 0 5.88579V28.1917C0 28.8897 0.567508 29.455 1.26821 29.455H17.7317C18.4324 29.455 19 28.8897 19 28.1917V5.88579C19.0057 5.18782 18.4382 4.62253 17.7375 4.62253ZM7.67295 4.56485C7.62662 3.88419 7.86984 3.22661 8.35627 2.75938C8.76743 2.3556 9.32335 2.14217 9.78663 2.18832C10.6958 2.26907 11.6281 3.26122 11.5181 4.55331L12.2999 4.62253H6.89117L7.67295 4.56485ZM15.4328 10.7023H3.74671V7.83547H15.4328V10.7023Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'superadmin'" width="29" height="22.23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
        <path d="M9.41438 11.1401C12.5027 11.1401 15.0063 8.64627 15.0063 5.57003C15.0063 2.49379 12.5027 0 9.41438 0C6.32608 0 3.82251 2.49379 3.82251 5.57003C3.82251 8.64627 6.32608 11.1401 9.41438 11.1401Z" fill="currentColor"/>
        <path d="M16.8003 12.8192V15.927C16.8003 16.4947 17.0343 17.0386 17.4423 17.433L22.7102 22.513C23.1841 22.9672 23.9341 22.9612 24.3961 22.501L29.382 17.5346C29.778 17.1402 30 16.6083 30 16.0525V12.8192C30 12.2455 29.658 11.7255 29.13 11.4984L24.4021 9.46644C23.7661 9.19153 23.0402 9.19153 22.4042 9.46644L17.6763 11.4984C17.1483 11.7255 16.8003 12.2455 16.8003 12.8192Z" fill="currentColor"/>
        <path d="M16.4283 18.4792C15.7383 17.8158 15.3423 16.8834 15.3423 15.9272V12.8195C15.3423 12.5446 15.3843 12.2756 15.4563 12.0186C14.6104 11.7019 13.6804 11.6541 12.7624 11.9648C11.8624 12.2696 10.7944 12.5027 9.59447 12.5266C8.2625 12.5565 7.08052 12.3234 6.09055 12.0007C2.88062 10.9429 -0.443305 14.0566 0.0486834 17.9891C0.0786827 18.2401 0.120682 18.4792 0.18068 18.7182C0.288678 19.1784 0.444674 19.5967 0.62467 19.9733C1.52465 21.8319 3.28261 22.8539 5.07657 22.8539H13.8604C15.2643 22.8539 16.6623 22.2503 17.6103 21.0072C17.8083 20.7502 17.9883 20.4633 18.1563 20.1466L16.4283 18.4792Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'envelope'" width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.1488 24.6323H0.8512C0.3808 24.6323 0 24.2529 0 23.7844V13.1636C0 12.4552 0.7784 12.0257 1.372 12.4106C2.3968 13.0744 3.696 13.9223 5.1744 14.9096C11.1832 18.9258 12.1744 19.9243 14.168 19.8964C15.0304 19.8853 15.9656 19.6845 21.1232 16.2985C23.268 14.8873 25.1104 13.6099 26.5888 12.55C27.1824 12.1261 28 12.55 28 13.2752V23.7844C28 24.2529 27.6192 24.6323 27.1488 24.6323Z" fill="currentColor"/>
        <path d="M27.1432 4.09375H26.8408H1.1592H0.8568C0.3808 4.09375 0 4.4898 0 4.98067V8.12116C0 8.56741 0.2072 8.99134 0.5488 9.25909C3.2816 11.4122 5.2976 12.9909 6.3448 13.8108C9.9848 16.6668 11.8384 18.0893 13.5016 18.0391C13.6752 18.0335 13.8376 18.0112 14 17.9889C14.1624 18.0167 14.3248 18.0335 14.4984 18.0391C16.1616 18.0948 18.0152 16.6668 21.6552 13.8108C22.7024 12.9909 24.7184 11.4067 27.4512 9.25909C27.7928 8.99134 28 8.56741 28 8.12116V4.98067C28 4.4898 27.6192 4.09375 27.1432 4.09375Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'company'" class="inline-block" style="color: var(--primary)" width="12"
        height="15" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22.2362 27.5844V0.199219H2.05481V27.5844H0V29.1905H24V27.5844H22.2362ZM13.3767 3.88692H18.633V7.69058H13.3767V3.88692ZM13.3767 9.11695H18.633V12.9206H13.3767V9.11695ZM13.3767 14.2948H18.633V18.0985H13.3767V14.2948ZM5.97235 3.88692H11.2287V7.69058H5.97235V3.88692ZM5.97235 9.11695H11.2287V12.9206H5.97235V9.11695ZM5.97235 14.2948H11.2287V18.0985H5.97235V14.2948ZM9.51734 21.7282H14.7737V27.5844H9.51734V21.7282Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'employee'" class="inline-block" width="12.39" height="15" viewBox="0 0 19 23"
        fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
        style="color: var(--primary)"
    >
        <path
            d="M9.41444 11.1401C12.5027 11.1401 15.0063 8.64627 15.0063 5.57003C15.0063 2.49379 12.5027 0 9.41444 0C6.32614 0 3.82257 2.49379 3.82257 5.57003C3.82257 8.64627 6.32614 11.1401 9.41444 11.1401Z"
            fill="currentColor" />
        <path
            d="M 18.505894,19.388198 C 19.123003,16.921572 18.339419,13.679876 15.4563,12.0186 c -0.8459,-0.3167 -1.7759,-0.3645 -2.6939,-0.0538 -0.9,0.3048 -1.968,0.5379 -3.16793,0.5618 -1.33197,0.0299 -2.51395,-0.2032 -3.50392,-0.5259 -3.20993,-1.0578 -6.533855,2.0559 -6.0418666,5.9884 0.0299993,0.251 0.0719986,0.4901 0.1319966,0.7291 0.107998,0.4602 0.263994,0.8785 0.44399,1.2551 0.89998,1.8586 2.65794,2.8806 4.4519,2.8806 h 8.78383 c 1.4039,0 2.8019,-0.6036 3.7499,-1.8467 0.198,-0.257 0.930507,-1.395885 0.723501,-1.09115 z"
            fill="currentColor" />
    </svg>

    <svg v-else-if="icon == 'question'" width="29" height="29" viewBox="0 0 19 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.62408 19.7737C8.7113 19.7737 7.93675 19.1129 7.85911 18.2372C7.71329 16.6202 8.03144 15.2001 8.80598 14.0164C9.90057 12.3348 11.5065 11.7743 12.7961 11.323C13.8812 10.9434 14.5156 10.698 14.9266 10.0838C15.6992 8.93232 15.5364 7.33136 15.0326 6.2551C13.9343 3.90737 10.874 3.46147 10.2736 3.39521C7.66405 3.11585 4.97114 4.41775 3.23646 6.80666C2.6797 7.57132 1.57754 7.76294 0.768905 7.24003C-0.0378326 6.71175 -0.242358 5.66772 0.312511 4.90484C2.79522 1.48802 6.77209 -0.370816 10.6808 0.0607637C13.3983 0.356243 16.8203 1.7835 18.2785 4.8941C19.1004 6.65086 19.5 9.53223 17.9244 11.8782C16.8506 13.4791 15.2864 14.0271 14.0271 14.4676C12.9097 14.858 12.2583 15.1123 11.8246 15.7767C11.4591 16.3372 11.3133 17.0697 11.391 17.9543C11.4743 18.8783 10.749 19.6896 9.7718 19.7666C9.72066 19.7702 9.67143 19.772 9.62029 19.772L9.62408 19.7737Z" fill="currentColor"/>
        <path d="M9.50282 29.0018C11.354 29.0018 12.8548 27.5827 12.8548 25.8321C12.8548 24.0815 11.354 22.6624 9.50282 22.6624C7.65159 22.6624 6.15088 24.0815 6.15088 25.8321C6.15088 27.5827 7.65159 29.0018 9.50282 29.0018Z" fill="currentColor"/>
    </svg>

    <svg v-else-if="icon == 'list-done'" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 1.77152C22.9687 1.77152 27.1689 3.51116 30.3284 6.67155C33.488 9.83106 35.2285 14.0322 35.2285 18.5C35.2285 22.9678 33.4888 27.1689 30.3284 30.3284C27.1689 33.488 22.9678 35.2285 18.5 35.2285C14.0322 35.2285 9.83106 33.4888 6.67155 30.3284C3.51204 27.1689 1.77152 22.9678 1.77152 18.5C1.77152 14.0322 3.51116 9.83106 6.67155 6.67155C9.83106 3.51204 14.0322 1.77152 18.5 1.77152ZM18.5 0C8.28275 0 0 8.28275 0 18.5C0 28.7173 8.28275 37 18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28275 28.7173 0 18.5 0Z" fill="#24AD80"/>
        <path d="M14.8426 28.9786C14.1738 28.9786 13.5051 28.7235 12.9949 28.2133L5.22233 20.4416C4.20193 19.4212 4.20193 17.7666 5.22233 16.7462C6.24273 15.7258 7.89733 15.7258 8.91772 16.7462L14.8426 22.6711L27.4053 10.1083C28.4257 9.08792 30.0803 9.08792 31.1007 10.1083C32.1211 11.1287 32.1211 12.7833 31.1007 13.8037L16.6903 28.2142C16.1801 28.7243 15.5113 28.9795 14.8426 28.9795V28.9786Z" fill="#24AD80"/>
        <defs>
            <clipPath id="clip0_663_2926">
                <rect width="37" height="37" fill="white"/>
            </clipPath>
        </defs>
    </svg>

    <svg v-else-if="icon == 'list-not-done'" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4996 0C8.28258 0 0 8.28258 0 18.4996C0 28.7165 8.28258 37 18.5004 37C28.7183 37 37 28.7174 37 18.4996C37 8.2817 28.7174 0 18.4996 0ZM30.2782 30.2782C28.7483 31.808 26.9667 33.0093 24.9838 33.8475C22.931 34.7158 20.7493 35.1566 18.5004 35.1566C16.2516 35.1566 14.0699 34.7158 12.0171 33.8475C10.0333 33.0084 8.25255 31.8072 6.7227 30.2782C5.19284 28.7483 3.99157 26.9667 3.15333 24.9838C2.28506 22.931 1.8443 20.7493 1.8443 18.5004C1.8443 16.2516 2.28506 14.0699 3.15333 12.0171C3.99246 10.0333 5.19373 8.25255 6.7227 6.7227C8.25167 5.19284 10.0341 3.99157 12.0171 3.15245C14.0699 2.28418 16.2516 1.8443 18.5004 1.8443C20.7493 1.8443 22.931 2.28506 24.9838 3.15333C26.9676 3.99246 28.7483 5.19373 30.2782 6.7227C31.808 8.25255 33.0093 10.0341 33.8475 12.0171C34.7158 14.0699 35.1566 16.2516 35.1566 18.5004C35.1566 20.7493 34.7158 22.931 33.8475 24.9838C33.0084 26.9676 31.8072 28.7483 30.2782 30.2782Z" fill="#DE7C22"/>
        <path d="M9 19H27.5" stroke="#DE7C22" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg v-else-if="icon == 'compensation'" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 576 512" fill="none">
        <path fill="currentColor" d="M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z"/>
    </svg>

    <i v-else :class="icon"></i>
</template>

<script>
export default {
    props: ['icon'],

    methods: {
        colorDanger() {
            return 'list-done' == this.icon ? 'var(--primary)' : 'red'
        }
    }
}
</script>