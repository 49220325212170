<template>
  <PlainAdmin :title="title" :pagetitle="pagetitle" :xClass="mainClass">
    <slot />
  </PlainAdmin>
</template>

<script>
import Axios from 'axios'
export default {
  props: {
    'title' : {},
    'loading' : {},
    'hasTooltips' : {},
    'pagetitle' : {},
    'x-class': {
      type: [String, Object],
      default: 'pb-20',
    }
  },
  data: () => ({
      notifications: [],
      menuIsOpen: false,
	}),

  mounted: function () {
    if (this.$store.user)
      this.notifications = this.$store.user.unreadnotifications
  },

  computed: {
    showTooltipLayer() { return this.$root.showTooltipLayer },
    mainClass() {
      let xClass = {}
      let given = typeof this.xClass == 'string' ?
                this.xClass.split(' ') :
                Object.keys(this.xClass)

      for (let _class of given) {
        xClass[_class] = true
      }

      if (Object.keys(xClass).indexOf('pb-20') == -1)
        xClass['pb-20'] = true

      return xClass
    }
  },

  watch: {
    '$store.user.unreadnotifications': (newVal) => {
      this.notifications = newVal
    }
  },

  methods: {
    markAsRead(notificationId) {
      Axios.post(`/notification/${notificationId}`).then(response => {
        let idx = this.notifications.findIndex(el => el.id == notificationId)

        if (200 == response.status && idx > -1) {
          this.$store.user.unreadnotifications.splice(idx, 1)
        }
      })
    },

    openMenu($ev) {
      this.menuIsOpen =! this.menuIsOpen

      if (window.jQuery($ev.target).is('span'))
        window.jQuery($ev.target).parent().toggleClass('toggled')
      else
        window.jQuery($ev.target).toggleClass('toggled')

      window.jQuery('.wrapper').toggleClass('nav-open')
    },

    fnShowTooltipLayer() {
      this.$root.showTooltipLayer =! this.$root.showTooltipLayer
    }
  }
}
</script>