<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <select @change="changeValue($event)" :class="iClass">
                <option value="">{{ field.name }}</option>
                <option v-for="value in field.values" :key="value.id" :value="value.id ? value.id : value.value" :selected="null !== value_ && value_.id == value.id">{{ value.value }}</option>
            </select>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'SelectField',

    mixins: [ formMixin ],

    created() {
        this.$nextTick(function () {
            if (typeof this.value == 'string') {
                let idx = this.field.values.findIndex(option => option.value == this.value)

                if (idx !== -1) {
                    this.value_ = this.field.values[idx].id
                    this.$emit('value-changed', this.field, this.value_)
                }

            }
        })
    },

    methods: {
        changeValue($event) {
            if (typeof this.value_ !== 'object' || null === this.value_) {
                this.value_ = {
                    id: null
                }
            }

            this.value_.id = $event.target.value
            this.change($event)
        }
    }
}
</script>