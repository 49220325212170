<template>
    <div class="relative mt-8 [@media(min-width:885px)]:mt-12 h-screen max-h-[calc(100vh_-_28rem)] box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl p-4 [@media(min-width:885px)]:p-8 overscroll-none w-full bg-white mb-4" :class="xClass">
        <p class="font-bold text-black font-montserrat text-lg mb-4">Medewerkers</p>

        <div class="absolute bottom-0 top-0 [@media(min-width:885px)]:top-4 left-4 [@media(min-width:885px)]:left-8 right-4 [@media(min-width:885px)]:right-8 mt-12 overflow-auto">

            <div class="grid [@media(max-width:885px)]:grid-cols-1 grid-cols-2 [@media(min-width:1100px)]:grid-cols-4" v-if="employees.length > 0">
                <x-list-item v-for="employee in employees" :key="employee.id"
                    id="id" name="name" :item="employee" @edit="editEmployee(employee.id)"
                    @remove="removeEmployee(employee.id)" />
            </div>

            <p class="text-black text-base" v-else>Er zijn geen medewerkers in deze afdeling</p>

        </div>
    </div>
</template>

<script>
export default {
    props: ['employees', 'x-class'],

    methods: {
        editEmployee(id) {
            this.$router.push({
            name: 'user-edit',
            params: {
            id: id
        }
      })
        },

        removeEmployee(id) {
            this.$emit('removeEmployee', id)
        }
    }
}
</script>