<template>
  <BaseLayout ref="base" :title="title" :pagetitle="pagetitle" :xClass="xClass">
    <slot />

    <template v-if="withMenu" v-slot:menu>
      <Menu :closeMenu="() => $refs.base.closeMenuIfOpen()" />
    </template>
  </BaseLayout>
  <!-- <div class="w-full h-full">
    <slot></slot>

    <main class="absolute pt-12 px-16 pb-12 top-0 left-0">
      <p class="font-semibold text-title text-3xl  leading-title "> {{ pagetitle }}</p>

      <p class="font-semibold text-4xl text-sentiment-blue  w-sentiment ">{{ title }}</p>
    </main>

    <div class="w-full z-[-1] fixed left-0 top-0 bg-white/80 h-screen overflow-y-auto ">
        <img src="/assets/images/image2.png" alt="logo" class="left-12 bottom-4  absolute w-30 h-logo">
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'PlainLayout',

  data: () => ({
      appName: null,
      msg: null,
	}),

  props: {
    'title': {},
    'pagetitle': {},
    'with-menu': {
      type: Boolean,
      default: true,
    },
    'x-class': {
      type: [String, Object],
      default: ''
    }
  },

  computed: {
    rootMsg() {
      return this.$root.msg
    },
  }
}
</script>