<template>
    <Admin title="Bedrijven">
        <template>
            <div class="mb-8 grid grid-cols-2 gap-8">
                <input type="text" v-model="filter.name"
                    class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray"
                    @keyup="getResults()" placeholder="Zoeken op naam">
                <x-button-green @click="create()"
                    class="justify-self-end [@media(max-width:450px)]:min-w-max">Toevoegen</x-button-green>
            </div>

            <x-list-item :item="company" v-for="company in companies" :key="company.id"
                name="name" id="id" @edit="edit" :isRemoveAllowed="true" @remove="remove(company.id)"
             />

            <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination>
        </template>
    </Admin>
</template>

<script>
import Axios from "axios";

export default {
    name: "CompanyIndex",

    data() {
        return {
            laravelData: {},
            filter: {
                name: null
            }
        };
    },

    created: function () {
        this.getResults()
    },

    computed: {
        companies: function () {
            return this.laravelData.data;
        },
    },

    methods: {
        gotoEmployees(company) {
            return {
                name: 'toscani-company-user-index',
                params: {
                    company: company.id
                }
            }
        },

        create() {
            this.$router.push({
                name: 'toscani-company-edit',
                params: {
                    id: 'create'
                }
            })
        },

        getResults(page = 1) {
            let params = new URLSearchParams([['page', page]])
            params.set('onlyMain', null)

            if (this.filter.name !== null && this.filter.name != '')
                params.set('filter[name]', this.filter.name)

            Axios.get(`toscani/company`, { params: params }).then(response => {
                this.laravelData = response.data
                window.scrollTo(0, 0)
            })
        },

        edit: function (id) {
            this.$router.push({
                name: 'toscani-company-edit',
                params: {
                    id: id
                },
            })
        },

        remove: function (id) {
            let idx = this.companies.findIndex((el) => {
                return el.id == id
            })

            if (idx != -1 && confirm(`Weet je zeker dat je ${this.companies[idx].name} wilt verwijderen?`)) {
                Axios.delete(`toscani/company/${this.companies[idx].id}`).then(() => {
                    this.laravelData.data.splice(idx, 1);
                })
            }
        }
    },
};
</script>