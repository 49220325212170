<template>
    <Admin title="Vergoedingen">
        <template>
            <div class="flex flex-col px-4 py-4 gap-4 bg-white rounded-xl shadow-sentiment">
                <div :class="{'mb-2': settings.feature_compensation_active}" class="mx-4">
                    <label class=" font-bold text-base text-black block text-montserrat cursor-pointer">
                        <input type="checkbox" id="onoff" name="onoff" v-model="settings.feature_compensation_active"
                            class="mr-2 rounded bg-gray-200" value="1">Vergoedingen aan/uit
                    </label>
                </div>

                <div class="mb-4 mx-4" v-if="settings.feature_compensation_active == '1'">
                    <label class=" font-bold text-base text-black block text-montserrat mb-2">Duur periodes</label>
                    <select @input="settings.feature_compensation_duration = $event.target.value" class="bg-gray-100 rounded-full h-[36px] px-5 md:h-input text-black w-max cursor-pointer">
                        <option value="mnd" :selected="settings.feature_compensation_duration == 'mnd'">Elke maand</option>
                        <option value="4wk" :selected="settings.feature_compensation_duration == '4wk'">Elke 4 weken</option>
                        <option value="" :selected="! isNaN(settings.feature_compensation_duration)">Elke ... weken</option>
                    </select>

                    <input placeholder="aantal" type="number" :value="settings.feature_compensation_duration" @input="settings.feature_compensation_duration = $event.target.value" v-if="! isNaN(settings.feature_compensation_duration) || settings.feature_compensation_duration.length == 0" class="ml-2 bg-gray-100 rounded-full grow border-slate-400 text-center h-[36px] md:h-input text-black" />

                    <div class="text-slate-500 italic" v-if="null !== errors.feature_compensation_duration">
                        {{ errors.feature_compensation_duration }}
                    </div>
                </div>


                <div class="mb-4 mx-4" v-if="settings.feature_compensation_active">
                    <label class=" font-bold text-base text-black block text-montserrat mb-2 ">Vragenlijst om te gebruiken</label>
                    <v-select v-model="settings.feature_compensation_form_id" :options="forms"
                        :reduce="option => option.id"
                        class="bg-gray-100 rounded h-[36px] pl-4 pr-3 md:h-input text-black w-full pt-1"
                        label="name"
                        placeholder="Geen vragenlijst, maar schuifjes"
                        />
                </div>
            </div>

            <div class="flex py-4 gap-x-4 mt-6 flex-wrap" v-if="(typeof results != 'undefined')">
                <p class="basis-full font-semibold text-xl mb-2">Resultaten</p>
                <div class="basis-full h-0"></div>
                <template v-for="employee in results.results">
                    <div class="basis-full text-white text-center font-semibold bg-sentiment-blue rounded-t-2xl p-2 shadow-sentiment">{{ employee.employee.name }}</div>
                    <div class="grid [@media(max-width:555px)]:grid-cols-1 grid-cols-2 [@media(min-width:1175px)]:grid-cols-3 w-full bg-black/10 backdrop-blur-lg rounded-b-2xl shadow-sentiment mb-4 max-h-60">
                        <template v-for="period in results.periods">
                                <div class="basis-full p-3">
                                    <p class="font-semibold">{{ period.name }}</p>
                                    <p v-if="(typeof employee[period.id] != 'undefined')" class="mt-1 font-semibold text-black">&euro; {{ employee[period.id].amount }}</p>
                                    <p v-else class="text-black font-semibold">N.B.</p>
                                </div>
                        </template>
                    </div>
                    <div class="basis-full h-4">&nbsp;</div>
                </template>
            </div>

            <div class="fixed right-6 bottom-6 w-max">
                <x-button-blue @click="$router.go(-1)" x-class="[@media(min-width:885px)]:inline-block mb-2 [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-4">Annuleren</x-button-blue>
                <x-button-green @click="submit" x-class="[@media(min-width:885px)]:inline-block [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]=mr-2">Opslaan</x-button-green>
            </div>

        </template>
    </Admin>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CompensationIndex',

    data: () => ({
        'settings': {
            'feature_compensation_active': null,
            'feature_compensation_duration': null,
            'feature_compensation_form_id': '',
        },

        'forms': [],

        'results': undefined,

        'errors': {
            'feature_compensation_active': null,
            'feature_compensation_duration': null,
            'feature_compensation_form_id': '',
        }
    }),

    mounted: function () {
        let query = {
            'filter[key]': Object.keys(this.$data.settings),
            'noPaginate': '',
        }

        let params = {
            'filter[!name]': 'feature_compensation',
            'noPaginate': '',
        }

        this.$nextTick(() => {
            axios.get('common/settings', {params: query}).then(response => {
                for(let key in response.data) {
                    this.$data.settings[key] = response.data[key]
                }

                if (this.$data.settings.feature_compensation_form_id === null)
                    this.$data.settings.feature_compensation_form_id = -1

                this.$data.settings.feature_compensation_active = response.data.feature_compensation_active == '1'
            });

            axios.get('admin/compensation/result').then(response => {
                this.results = response.data
            })

            axios.get('admin/form', {params: params}).then(response => {
                this.forms = response.data
            }).then(() => {
                this.forms.unshift({
                    name: 'Geen vragenlijst, maar schuifjes',
                    id: -1,
                })
            })
        })
    },

    methods: {
        submit() {
            let error = false

            this.errors = {
                'feature_compensation_active': null,
                'feature_compensation_duration': null,
                'feature_compensation_form_id': null,
            }

            if ([null, ''].indexOf(this.settings.feature_compensation_duration) != -1) {
                error = true
                this.errors.feature_compensation_duration = 'Een waarde is verplicht'
            }

            if (error)
                return

            let params = {
                'settings': Object.assign({}, this.$data.settings)
            }

            if (this.settings.feature_compensation_form_id == -1) {
                params.settings.feature_compensation_form_id = null
            }

            axios.put('admin/compensation', params).then(response => {
                if (200 >= response.status < 300) {
                    this.$notify({
                        text: 'Instellingen aangepast!',
                        type: 'success'
                    })
                }
            });
        }
    }
}
</script>

