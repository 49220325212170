<template>
    <div class="box-shadow shadow-lg rounded-xl overscroll-none bg-white text-black" :class="iClass">
        <label v-if="!noLabel" class="font-semibold">{{ name }}</label>
        <slot name="input" :class="{'border-red-700 border': typeof error() !== 'undefined'}"/>

        <div v-if="typeof field == 'undefined' || 'employees' != field.type && typeof error() !== 'undefined'" :class="{'italic text-red-700': typeof error() !== 'undefined'}">
            {{ error() }}
        </div>

        <!-- <div>
            <a class="btn btn-sm btn-secondary float-right mr-n3" style="right: 0; width: 150px;" @click="showResults(bus.field)">Toon resultaten</a>
        </div> -->

    </div>
</template>

<script>
import ResultModal from '@/components/modals/resultsModal'
import formMixin from '@/mixins/formMixin'

export default {
    name: 'FormField',

    props: {
        'field': {},
        'idx': {},
        'errors': {},
        'value': {},
        'bus': {},
        'coworkers': {},
        'xClass': {
            type: [String, Object],
            default: ''
        },
        'name': {},
        'no-label': {
            default: false,
            type: Boolean
        },
        'bus': {}
    },

    data: () => ({
        iClass: 'mt-10 p-8',
    }),

    methods: {
        error() {
            return this.errors
        },

        showResults(field) {
            this.$modal.show({
                name: 'result-modal-runtime',
                template: `<result-modal :period="period" :field="field"></result-modal>`,
                components: { ResultModal },
                props: ['period', 'field']
            }, {
                period: this.$route.params.period_id,
                field: field
            }, {
                height: 'auto',
                'min-height': 300
            })
            //this.bus.showResults(field)
        }
    },

    mixins: [
        formMixin
    ]
}
</script>