export default {
    data: () => ({
        value_: '',
        iClass: 'w-full bg-white h-input border border-grey-700 block rounded-xl px-4',
	}),

    props: {
        'field': {},
        'idx': {},
        'errors': {},
        'value': {},
        'bus': {},
        'coworkers': {},
        'xClass': {
            type: [String, Object],
            default: ''
        },
    },

    created() {
        if (typeof this.xClass == 'object') {
            for (let key in this.xClass) {
                if (this.xClass[key]) {
                    this.iClass += ' ' + key
                }
            }
        } else {
            this.iClass += ' ' + this.xClass
        }
    },

    beforeMount() {
        this.$nextTick(() => {
            try {
                let value = JSON.parse(this.value)
                this.value_ = typeof value !== 'undefined' && null !== value &&  typeof value.id !== 'undefined' ? value.id : value

                if (typeof this.with_input_value !== 'undefined' && typeof value.extra !== 'undefined')
                    this.with_input_value = value.extra
            } catch(e) {
                this.value_ = this.value
            }
        })
    },

    watch: {
        idx(val, old) {
            if (val != old) {
                this.value_ = ''
            }
        },

        value(val) {
            this.value_ = val
        }
    },

    methods: {
        change($event) {
            this.$emit('value-changed', this.field, $event.target.value)
        }
    }
}