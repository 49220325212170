<template>
    <form action="" class="px-8 py-8 mx-auto" :class="xClass">
        <div class="mb-4 text-xl text-poppins">Wachtwoord {{ isNew ? 'instellen' : 'vergeten' }}</div>
        <div class="mb-4">
            <label class="text-base flex text-montserrat ">Nieuw wachtwoord</label>
            <input type="password" id="password" name="password"
                v-model="password"
                class="bg-gray-100 rounded-full w-full h-input py-2 px-4">
        </div>

        <div class="mb-4">
            <label class="text-base flex text-montserrat ">Nieuw wachtwoord herhalen</label>
            <input type="password" id="password_confirmation" name="password_confirmation"
                v-model="password_confirmation"
                class="bg-gray-100 rounded-full w-full h-input py-2 px-4 text-black">
        </div>

        <x-button-green title="Wachtwoord opslaan" class="mx-auto" @click="reset">Opslaan</x-button-green>

        <slot />
    </form>
</template>

<script>
export default {
    name: 'ResetPassword',

    props: {
        'x-class': {
            type: String
        },
        'isNew': {
            type: Boolean,
            default: () => false
        },
    },

    data: () => ({
        'password': null,
        'password_confirmation': null,
	}),

    methods: {
        reset: function () {
            this.$emit('reset', Object.assign({}, this.$data))
        }
    }
}
</script>