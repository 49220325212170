<template>
    <router-link :to="url" v-if="url" class="flex items-center py-2 pl-4 nav-item" :class="{ 'uppercase font-semibold text-sentiment-green': active }" @click.native="$emit('click')">
        <x-icon :icon="icon" />

        <slot></slot>

        <i v-if="active" class="fa-solid fa-circle text-sentiment-green text-xs ml-2"></i>
    </router-link>

    <p v-else class="flex items-center text-white py-2 pl-4 nav-item" :class="{ 'uppercase': active }" role="button" @click="$emit('click')">
        <x-icon :icon="icon" />

        <slot />
    </p>
</template>

<script>
export default {
    name: 'nav-item',

    props: ['active', 'url', 'icon']
}
</script>