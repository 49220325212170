/** Toscani */
import ToscaniCompanyIndex from '@/views/Toscani/Company/Index'
import ToscaniCompanyEdit from '@/views/Toscani/Company/Edit'
import ToscaniCompanyEmployeeIndex from '@/views/Toscani/Company/Employees/Index'
import ToscaniFormIndex from '@/views/Admin/Form/Index'
import ToscaniEditForm from '@/views/Admin/Form/Edit'
import ToscaniMailIndex from '@/views/Toscani/Mail/Index'
import ToscaniMailEdit from '@/views/Toscani/Mail/Edit'

export default [
  {
    path: '/toscani/company',
    component: ToscaniCompanyIndex,
    name: 'toscani-company-index',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true
    }
  },
  {
    path: '/toscani/company/:id',
    component: ToscaniCompanyEdit,
    name: 'toscani-company-edit',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/toscani/company/:company/user',
    component: ToscaniCompanyEmployeeIndex,
    name: 'toscani-company-user-index',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/toscani/form',
    component: ToscaniFormIndex,
    name: 'toscani-form-index',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: '/toscani/form/:id',
    name: 'toscani-form-edit',
    component: ToscaniEditForm,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true
    }
  },
  {
    path: '/toscani/mail',
    name: 'toscani-mail-index',
    component: ToscaniMailIndex,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true
    }
  },
  {
    path: '/toscani/mail/:id',
    name: 'toscani-mail-edit',
    component: ToscaniMailEdit,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true
    }
  },
]