<template>
    <form action="" class="px-8 py-8 mx-auto md:w-login" :class="xClass">
        <div class="mb-4">
            <label class=" font-bold text-base text-sentiment-blue flex text-montserrat mb-2"> E-mailadres</label>
            <input type="text" id=" E-mailadres" name="E-mailadres"
                v-model="email"
                class="bg-gray-100 rounded-full  w-full h-input  py-2 px-4"
                :class="{ 'ring-1 ring-red-500': typeof errors.email !== 'undefined'}">

            <span v-if="(typeof errors.email !== 'undefined')" class="text-red-500 italic">
                {{ errors.email }}
            </span>
        </div>
        <div class="mb-4">
            <label class=" font-bold text-base text-sentiment-blue flex text-montserrat mb-2 "> Wachtwoord</label>
            <input type="password" id="wachtwoord" name="wachtwoord"
                v-model="password"
                class="bg-gray-100 rounded-full items-center w-full h-input  py-2 px-4">
        </div>

        <p class="text-sentiment-green pt-8 text-base text-center m-auto ">
            <router-link class="hover:text-sentiment-blue ease-in-out duration-150" :to="{name: 'PasswordReset'}">Wachtwoord vergeten?</router-link>
        </p>

        <p class="text-sentiment-green py-8 text-base text-center m-auto">
            <router-link class="hover:text-sentiment-blue ease-in-out duration-150" :to="{name: 'Register'}">Bedrijf aanmelden</router-link>
        </p>

        <x-button-green title="inloggen" class="mx-auto shadow-sentiment" @click="login">Inloggen</x-button-green>

        <slot />
    </form>
</template>

<script>
export default {
    name: 'Form',

    props: [
        'x-class',
        'errors'
    ],

    data: () => ({
            'email': null,
            'password': null,
	}),

    methods: {
        login: function () {
            this.$emit('login', Object.values(this.$data))
        }
    }
}
</script>