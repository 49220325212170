<template>
    <Admin title="Wachtwoord aanpassen">
        <template>
            <div class="text-black box-shadow shadow-sentiment rounded-xl p-8 overscroll-none w-full bg-white mb-4">
                <form action="">
                    <div class="mb-4">
                        <label class="font-bold text-base flex text-montserrat ">Wachtwoord *</label>
                        <input type="password" id="current" v-model="current" name="current" placeholder="Wachtwoord"
                            class=" bg-gray-100 rounded-full w-input h-input py-2 px-4">
                        <div  :class="hasError('current')" v-html="getError('current')" />
                    </div>

                    <div class="mb-4">
                        <label class="font-bold text-base flex text-montserrat ">Nieuw wachtwoord *</label>
                        <input type="password" id="wachtwoord" v-model="password" name="wachtwoord" placeholder="Wachtwoord"
                            class=" bg-gray-100 rounded-full w-input h-input py-2 px-4">
                        <div class="text-slate-600 italic text-sm mt-2">
                            Wachtwoord moet minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken (~`!@#$%^&*()_-+="'&lt;&gt;?/) bevatten.
                        </div>
                        <div  :class="hasError('password')" v-html="getError('password')" />
                    </div>

                    <div class="mb-4">
                        <label class="font-bold text-base flex text-montserrat ">Wachtwoord herhalen*</label>
                        <input type="password" id="wachtwoord_confirm" v-model="password_confirm" name="wachtwoord_confirm" placeholder="Wachtwoord herhalen"
                            class=" bg-gray-100 rounded-full w-input h-input py-2 px-4">
                    </div>

                    <x-button class="w-40" @click.prevent="submit">Opslaan</x-button>
                </form>
            </div>
        </template>
    </Admin>
</template>

<script>
import axios from 'axios'

export default {
    name: 'user-profile',

    data: () => ({
        current: null,

        password: null,
        password_confirm: null,

        errors: {},
    }),

    methods: {
        hasError(field) {
            if (
                typeof this.errors !== 'undefined' &&
                typeof this.errors[field] !== 'undefined'
            ) {
                return {
                    'text-red-700': true,
                    'text-italic': true,
                    'text-xs': true,
                    'mt-2': true,
                    hidden: false,
                }
            } else {
                return {
                    hidden: true
                }
            }
        },

        getError(field) {
            if (
                typeof this.errors !== 'undefined' &&
                typeof this.errors[field] !== 'undefined'
            ) {
                return this.errors[field].join('<br>')
            }
        },

        userUpdated(response) {
            if (200 == response.status) {
                this.$root.msg = 'Gegevens aangevuld.'
                this.$router.push({ name: 'Login' })
            }
        },

        submit() {
            let data = {
                current: this.current,
                password: this.password,
                password_confirmation: this.password_confirm
            }

            axios.put(`/common/password`, data).then(response => {
                if (response.status == 200) {
                    // reset data
                    this.password = this.current = this.password_confirm = null
                    this.errors = {}

                    this.$notify({
                        text: response.data.message,
                        type: 'success'
                    })
                }
            }).catch(error => {
                if (typeof error.response !== 'undefined' &&
                    typeof error.response.data !== 'undefined' &&
                    typeof error.response.data.errors !== 'undefined'
                )
                    this.errors = error.response.data.errors
            })
        }
    },
}
</script>

<style scoped>
#content-container div:not(.card-user):not(.mx-input-wrapper):not(.form-check-inline) {
    /* margin: 0 !important; */
    padding: 0 !important;
}

.card {
    border-radius: 0;
}

#content-container :not(.icon-password) .mx-input-wrapper {
    padding: 0 !important;
}

#content-container div {
    margin-right: inherit !important;
}

#content-container div:not(.icon-password) div:not(.form-check-inline) {
    margin-right: 0 !important;
    padding: 0;
}
</style>