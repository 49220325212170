<template>
    <div>
        <h3 v-if="form">{{ form.type }}</h3>
        <div v-if="project && project.length > 0">
            <strong>Project:&nbsp;</strong>
            <span v-if="$store.user && $store.user.role != roles.ROLE_INSTANCE">
                <router-link :to="{name: 'project-edit-info', params: { id: project[0].id }}">{{ project[0].name }}</router-link>
            </span>
            <span v-else>{{project[0].name}}</span>
        </div>

        <div v-if="meeting && meeting.length > 0 && meeting[0].project">
            <strong>Project:&nbsp;</strong>
            <router-link v-if="$store.user && $store.user.role != roles.ROLE_INSTANCE" :to="{name: 'project-edit-info', params: { id: meeting[0].project.id }}">{{ meeting[0].project.name }}</router-link>
            <span v-else>{{ meeting[0].project.name }}</span>
        </div>

        <div v-if="meeting && meeting.length > 0 && meeting[0].project">
            <strong>Bijeenkomst:&nbsp;</strong>
            <router-link v-if="$store.user && $store.user.role != roles.ROLE_INSTANCE" :to="{name: 'project-edit-meeting', params: { id: meeting[0].project.id, meetingId: meeting[0].id }}">{{ meeting[0].name }}</router-link>
            <span v-else>{{ meeting[0].name }}</span>
        </div>

        <div v-if="submittedBy">
            <strong>Ingediend door:&nbsp;</strong>
            <router-link v-if="$store.user && $store.user.role != roles.ROLE_INSTANCE" :to="{name: 'user-edit', params: { id: submittedBy.id }}">{{ submittedBy.name }}</router-link>
            <span v-else>{{ submittedBy.name }}</span>
        </div>

        <div v-if="relatedTo && submittedBy.id != relatedTo.id">
            <strong>Over:&nbsp;</strong>
            <router-link v-if="$store.user && $store.user.role != roles.ROLE_INSTANCE" :to="{name: 'user-edit', params: { id: relatedTo.id }}">{{ relatedTo.name }}</router-link>
            <span v-else>{{ relatedTo.name }}</span>
        </div>

        <table class="table table-striped">
            <thead>
                <th style="width: 30%">Vraag</th>
                <th>Antwoord</th>
            </thead>
            <tbody>
                <tr v-for="field in fields" :key="field.id" :class="{'bg-white': field.type == 'smiley' }">
                    <td>{{ field.label }}</td>
                    <td v-html="getValue(field)"></td>
                </tr>
            </tbody>
        </table>

        <div v-if="$store.user && $store.user.role != roles.ROLE_INSTANCE && submittedForm && submittedForm.status == 'pending' && (submittedForm.form.pending_option || submittedForm.form.editable)">
            <a class="btn btn-primary" role="button" @click="setDone">
                <span v-if="submittedForm.form.pending_option">Registratie</span>
                <span v-else>Inzending</span>
                goedkeuren
            </a>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import smiley51 from '@/assets/smileys/5/5-1.png'
import smiley52 from '@/assets/smileys/5/5-2.png'
import smiley53 from '@/assets/smileys/5/5-3.png'
import smiley54 from '@/assets/smileys/5/5-4.png'
import smiley55 from '@/assets/smileys/5/5-5.png'
import smiley101 from '@/assets/smileys/10/10-1.png'
import smiley102 from '@/assets/smileys/10/10-2.png'
import smiley103 from '@/assets/smileys/10/10-3.png'
import smiley104 from '@/assets/smileys/10/10-4.png'
import smiley105 from '@/assets/smileys/10/10-5.png'
import smiley106 from '@/assets/smileys/10/10-6.png'
import smiley107 from '@/assets/smileys/10/10-7.png'
import smiley108 from '@/assets/smileys/10/10-8.png'
import smiley109 from '@/assets/smileys/10/10-9.png'
import smiley1010 from '@/assets/smileys/10/10-10.png'

import settings from '@/AppSettings'

let smileys = [
    smiley51,
    smiley52,
    smiley53,
    smiley54,
    smiley55,
    smiley101,
    smiley102,
    smiley103,
    smiley104,
    smiley105,
    smiley106,
    smiley107,
    smiley108,
    smiley109,
    smiley1010,
]

export default {
    name: 'SubmittedFormView',

    data: () => ({
            form: null,
            values: [],
            relatedTo: null,
            submittedBy: null,
            project: [],
            meeting: [],
            submittedForm: null,
            roles: settings.roles,
	}),

    props: ['submittedFormProp'],

    created() {
        this.submittedForm = this.submittedFormProp
        this.form = this.submittedForm.form
        this.values = this.submittedFormProp.submitted_values
        this.relatedTo = this.submittedFormProp.related_to
        this.submittedBy = this.submittedFormProp.submitted_by
        this.project = this.submittedFormProp.project
        this.meeting = this.submittedFormProp.meeting
    },

    computed: {
        fields() {
            return this.form ? this.form.own_fields : []
        }
    },

    methods: {
        getValue(field) {
            let fIdx = this.fields.findIndex(el => el.id == field.id)
            let vIdx = this.values.findIndex(el => {
                return el.field_id == field.id
            })

            if (-1 == vIdx || -1 == fIdx)
                return ''

            let checked, checkedValues, cIdx, wasChecked, checkedIdx
            switch (field.type) {
                case 'text':
                case 'textarea':
                case 'number':
                    return this.values[vIdx].value

                case 'smiley':
                    if (typeof this.fields[fIdx].values[1] == 'undefined')
                        return null

                    if (this.fields[fIdx].values[1].value == 5)
                        return '<img src="' + smileys[this.values[vIdx].value - 1] + '" class="smiley selected">'

                    return '<img src="' + smileys[5 + this.values[vIdx].value - 1] + '" class="smiley selected">'
                case 'radio':
                case 'select':
                    checkedIdx = field.values.findIndex(el => el.id == this.values[vIdx].value)
                    if (-1 == checkedIdx)
                        return ''

                    checked = field.values[checkedIdx]
                    return checked.value

                case 'checkbox':
                    checked = JSON.parse(this.values[vIdx].value)
                    checkedValues = []

                    for(cIdx in checked) {
                        wasChecked = checked[cIdx]
                        if (wasChecked) {
                            let fvIdx = field.values.findIndex(el => el.id == cIdx)
                            if (-1 != fvIdx)
                                checkedValues.push(field.values[fvIdx].value)
                        }
                    }

                    return checkedValues.length > 0 ? '- ' + checkedValues.join('<br>- ') : ''

                case 'upload':
                    return '<a href="' + settings.API_URL.replace('/api/', '') +
                        '/uploads/' + this.values[vIdx].value +
                        '?token=' + this.$store.at +
                        '" target="blank">Geüpload bestand</a>'
            }
            return this.values[vIdx].value
        },

        getField(value) {
            let idx = this.fields.findIndex(el => {
                return value.field_id == el.id
            })
            return this.fields[idx]
        },

        setDone() {
            Axios.post(`/admin/submittedForm/${this.submittedForm.id}`).then(response => {
                if (response.status == 200) {
                    this.$notify({
                        text: 'Formulier inzending goedgekeurd'
                    })
                    this.$emit('form-sent', this.submittedForm)
                }
            })
        },

    }
}
</script>

<style>
.smiley {
    height: 25px;
}
</style>