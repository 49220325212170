<template>
  <Admin title="Gebruikers">
    <template>
        <div class="mb-8 grid grid-cols-2 gap-8">
          <input type="text" v-model="filter.name" class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray" @keyup="filterUsers" placeholder="Zoeken op naam">
          <x-button-green @click="create()" class="justify-self-end [@media(max-width:450px)]:min-w-max">Toevoegen</x-button-green>
        </div>

        <div class="md:grid [@media(max-width:1400px)]:grid-cols-2 grid-cols-4">
          <x-list-item v-for="(user, index) in users" :key="user.id" :item="user"
            id="id" name="name" @edit="edit" @remove="remove(index)"
            :isRemoveAllowed="($store.user.is_admin) && user.id != $store.user.id" @click="remove(index)" />

          <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination>
        </div>
    </template>
  </Admin>
</template>

<script>
import Axios from 'axios'
import * as Settings from '@/AppSettings'

const abortController = new AbortController()

export default {
  name: 'UserList',

  data: () => ({
      laravelData: {},
      page: 1,
      filter: {
        name: '',
        userGroup: null,
        inactive: null,
      },

      sort: {
        name: null,
        username: null,
        role: null,
      },

      settings: null,
	}),

  created: function () {
    this.settings = Settings

    // Axios.get('admin/userGroup').then(response => {
    //   this.roles = response.data.map(el => {
    //     return el.charAt(0).toUpperCase(0) + el.substring(1)
    //   })
    // })

    // Axios.get('admin/pillar?all').then(response => {
    //   this.pillars = response.data
    // })

    this.getResults()
  },

  methods: {
    create() {
      this.$router.push({
        name: 'user-edit',
        params: {
          id: 'create'
        }
      })
    },

    edit(id) {
      this.$router.push({
        name: 'user-edit',
        params: {
          id: id
        }
      })
    },

    remove(idx) {
      if (confirm(`Weet je zeker dat je '${this.users[idx].name}' wilt verwijderen?`))
        Axios.delete(`admin/user/${this.users[idx].id}`)
          .then((response) => {
            if (response.status == 202)
              this.laravelData.data.splice(idx, 1)
          })
    },

    filterByEnter(event) {
      if (event.keyCode === 13) {
        this.getResults()
      }
    },

    doSort(field) {
      let keys = Object.keys(this.sort)
      for (let idx in keys) {
        if (field == keys[idx])
          continue

        this.sort[keys[idx]] = null
      }
      this.sort[field] = this.sort[field] == null ? 1 : (this.sort[field] == -1 ? null : -1)
      this.getResults()
    },

    filterUsers() {
      if (this.filter.name.length > 3 || this.filter.name.length == 0){
        abortController.abort()

        this.getResults()
      }
    },

    getResults(page = 1) {
      this.page = page
      let _this = this
      let filter = Object.keys(this.filter).map(function (key) {
        if (_this.filter[key] == '' || _this.filter[key] == null)
          return

        if ('inactive' == key) {
          return `inactive=1`
        }

        return `filter[${key}]=${_this.filter[key]}`
      }).filter(function (val) {
        return val != '' && val != '&'
      }).join('&').replace('&&', '&');
      let sort = Object.keys(this.sort).map(function (key) {
        if (_this.sort[key] == '' || _this.sort[key] == null)
          return

        return `sort[${key}]=${_this.sort[key]}`
      }).filter(function (val) {
        return val != '' && val != '&'
      }).join('&').replace('&&', '&');

      let url = `admin/user?page=${page}`
      if (filter != '' && filter != '&')
        url += `&${filter}`
      if (sort != '' && sort != '&')
        url += `&${sort}`

      return Axios.get(url.replace('&&', '&')).then((response) => {
        this.laravelData = response.data
      })
    }
  },

  computed: {
    users: function () {
      return this.laravelData.data
    }
  }
}
</script>

<style>
.filter .vs__selected {
  background-color: transparent !important;
  padding: 0px !important;
  line-height: 1.4 !important;
  color: initial !important;
  font-weight: initial !important;
}
</style>