<template>
    <Admin title="Afdelingen">
        <template>
            <div class="mb-8 grid grid-cols-2 gap-8">
                <input type="text" v-model="filter.name"
                    class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray"
                    @keyup="filterDepartments" placeholder="Zoeken op naam">

                <x-button-green @click="create()" class="justify-self-end [@media(max-width:450px)]:min-w-max">Toevoegen</x-button-green>
            </div>

            <div class="md:grid [@media(max-width:1400px)]:grid-cols-2  grid-cols-4" v-if="departments && departments.length > 0">
                <template v-for="(department, index) in departments">
                    <x-list-item :key="index" :item="department" id="id" @edit="edit" @remove="remove"
                        :isRemoveAllowed="($store.user.is_admin) && department.employees.length == 0">
                        {{ department.name + settling(department) }}
                    </x-list-item>
                </template>

                <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination>
            </div>
        </template>
    </Admin>
</template>

<script>
import axios from 'axios';

const abortController = new AbortController()

export default {
    name: 'department-index',

    data: () => ({
            laravelData: {
                data: []
            },
            filter: {
                name: null
            }
	}),

    created: function () {
        this.getResults()
    },

    computed: {
        departments: function () {
            return this.laravelData.data;
        },
    },

    methods: {
        create() {
            this.$router.push({
                name: 'department-edit',
                params: {
                    id: 'create'
                }
            })
        },

        filterDepartments() {
            if (this.filter.name.length > 3 || this.filter.name.length == 0) {
                abortController.abort()

                this.getResults()
            }
        },

        getResults: function (page = 1) {
            let params = new URLSearchParams
            if (page != 1)
                params.append('page', page)

            if (typeof this.filter.name == 'string' && this.filter.name.length > 3) {
                params.append('filter[name]', this.filter.name)
            }

            axios.get('admin/department', {params: params}).then(response => {
                this.laravelData = response.data
            })
        },

        settling(department) {
            return department.company && department.company.company_id ?
                ' (' + department.company.name + ')' :
                ''
        },

        edit(id) {
            this.$router.push({
                name: 'department-edit',
                params: {
                    id: id
                }
            })
        },

        remove(id) {
            let idx
            if ((idx = this.departments.findIndex(el => el.id == id)) == -1)
                return

            if (confirm(`Weet je zeker dat je '${this.departments[idx].name}' wilt verwijderen?`)) {
                axios.delete(`admin/department/${id}`).then(() => {
                    this.$notify({
                        text: 'Afdeling verwijderd',
                        type: 'success'
                    })

                    this.departments.splice(idx, 1)
                })
            }
        }
    }
}
</script>