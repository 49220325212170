<template>
    <Admin title="Formulieren">
        <template>
            <div class="mb-8 grid grid-cols-2 gap-8">
                <input type="text" v-model="filter.name"
                    class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray"
                    @keyup="getData()" placeholder="Zoeken op naam">
                <x-button-green @click="create()"
                    class="justify-self-end [@media(max-width:450px)]:min-w-max">Toevoegen</x-button-green>
            </div>

            <template v-if="companyForms.length > 0 && !$route.name.startsWith('toscani')">
                <h2 class="font-semibold text-lg" v-if="commonForms.length > 0">Jouw formulieren</h2>

                <div class="grid [@media(min-width:1300px)]:grid-cols-2 [@media(min-width:1600px)]:grid-cols-3 mb-5 [@media(max-width:1299px)]:min-h-[140px] overflow-y-scroll md:scrollbar-none">
                    <x-list-item v-for="form in companyForms" :key="form.id" id="id" name="name" :item="form"
                        :isRemoveAllowed="form.company_id == $store.user.mainCompany.id" @edit="edit" @remove="deleteForm">
                        <template #suffix>
                            <x-icon class="inline-block align-baseline" :icon="form.type"></x-icon>
                        </template>
                    </x-list-item>
                </div>
            </template>

            <template v-if="commonForms.length > 0">
                <h2 class="font-semibold text-lg" v-if="companyForms.length > 0">Algemene formulieren</h2>

                <div
                    class="grid [@media(min-width:1300px)]:grid-cols-2 [@media(min-width:1600px)]:grid-cols-3 [@media(max-width:1299px)]:min-h-[140px] overflow-y-scroll mb-2 md:scrollbar-none">
                    <x-list-item v-for="form in commonForms" :key="form.id" id="id" name="name" :item="form"
                        :isRemoveAllowed="$route.name.startsWith('toscani')" :noEdit="! $store.user.is_superadmin" @edit="edit" @remove="deleteForm">
                        <template #suffix>
                            <x-icon class="inline-block align-baseline" :icon="form.type"></x-icon>
                        </template>
                    </x-list-item>
                </div>
            </template>

            <Pagination :data="data" @pagination-change-page="getData"></Pagination>

            <!-- Confirmation message -->
            <div v-if="showDeleteModal"
                class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div class="bg-white rounded-lg p-6 w-11/12 md:w-4/12 shadow-xl">
                    <p class="text-xl mb-4 text-sentiment-green">Weet je zeker dat je {{ formNameToDelete }} wilt
                        verwijderen?</p>
                    <div class="flex justify-end space-x-4">
                        <button @click="cancelFormDelete"
                            class="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 ease-in-out duration-150">Nee</button>
                        <button @click="confirmFormDelete"
                            class="px-4 py-2 text-white bg-red-400 rounded-md hover:bg-red-500 ease-in-out duration-150">Ja</button>
                    </div>
                </div>
            </div>
        </template>
    </Admin>
</template>


<script>
import Axios from 'axios';

export default {
    name: 'FormIndex',
    data() {
        return {
            showDeleteModal: false,
            formIdToDelete: null,
            formNameToDelete: null,
            data: {},
            page: 1,
            filter: {
                name: ''
            }
        }
    },

    created() {
        this.getData();
    },

    computed: {
        commonForms() {
            if (typeof this.data.data !== 'undefined')
                return this.data.data.filter(form => null == form.company_id)

            return []
        },

        companyForms() {
            if (typeof this.data.data !== 'undefined')
                return this.data.data.filter(form => null !== form.company_id)

            return []
        }
    },

    methods: {
        getData(page = 1) {
            let params = new URLSearchParams([['page', page], ['per_page', 30]])
            this.page = page

            let url = '/admin/form'
            if (this.$route.name.startsWith('toscani')) {
                url = url.replace('admin', 'toscani')
            }

            if (this.filter.name !== '') {
                params.set('filter[name]', this.filter.name)
            }

            Axios.get(url, { params }).then((response) => {
                this.data = response.data
            })
        },

        deleteForm(id) {
            let index = this.data.data.findIndex(form => form.id == id);
            if (-1 == index) return;

            let name = this.data.data[index].name;
            this.formIdToDelete = id;
            this.formNameToDelete = name;
            this.showDeleteModal = true;
        },

        confirmFormDelete() {
            let index = this.data.data.findIndex(form => form.id == this.formIdToDelete);
            if (index !== -1) {
                let url = '/admin/form/'
                if (this.$route.name.startsWith('toscani')) {
                    url = url.replace('admin', 'toscani')
                }

                Axios.delete(url + this.formIdToDelete).then(() => {
                    this.data.data.splice(index, 1);
                    this.formIdToDelete = null;
                    this.formNameToDelete = null;
                    this.showDeleteModal = false;
                });
            }
        },

        cancelFormDelete() {
            this.formIdToDelete = null;
            this.formNameToDelete = null;
            this.showDeleteModal = false;
        },

        duplicate(id) {
            Axios.post(`/toscani/form/${id}`).then((response) => {
                // this.getData()
                this.$router.push({ name: 'toscani-form-edit', params: { id: response.data.id } })
            })
        },

        create() {
            let route = 'form-edit'
            if (this.$route.name.startsWith('toscani'))
                route = 'toscani-' + route

            this.$router.push({
                name: route,
                params: {
                    id: 'create'
                }
            })
        },

        edit(id) {
            console.log('do edit')
            let route = 'form-edit'
            if (this.$route.name.startsWith('toscani'))
                route = 'toscani-' + route

            this.$router.push({
                name: route,
                params: {
                    id: id
                }
            })
        }
    }
}
</script>