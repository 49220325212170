<template>
    <span class="d-inline-block">
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        <i :class="classes" v-if="! loading"></i>
    </span>
</template>

<script>
export default {
    props: {
        'alt': {
            type: String,
            default: () => 'fas fa-check'
        }
    },

    data: () => ({
            loading: false,
            danger: false,
	}),

    computed: {
        classes() {
            let c = {}
            c[this.alt] = true
            c['text-danger'] = this.danger
            return c
        }
    }
}
</script>