<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <div class="grid grid-flow-row grid-cols-5" :class="{
                'md:grid-cols-10': !isMobile && (field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10)
            }">
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '1', 'h-max': true }">
                    <img :src="getImage(1)" @click="change($event, 1)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '2', 'h-max': true }">
                    <img :src="getImage(2)" @click="change($event, 2)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '3', 'h-max': true }">
                    <img :src="getImage(3)" @click="change($event, 3)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '4', 'h-max': true }">
                    <img :src="getImage(4)" @click="change($event, 4)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '5', 'h-max': true }">
                    <img :src="getImage(5)" @click="change($event, 5)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '6', 'h-max': true }" v-if="field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10">
                    <img :src="getImage(6)" @click="change($event, 6)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '7', 'h-max': true }" v-if="field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10">
                    <img :src="getImage(7)" @click="change($event, 7)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '8', 'h-max': true }" v-if="field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10">
                    <img :src="getImage(8)" @click="change($event, 8)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '9', 'h-max': true }" v-if="field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10">
                    <img :src="getImage(9)" @click="change($event, 9)">
                </div>
                <div class="smiley" :class="{ selected: value_ == '' || value_ == '10', 'h-max': true }" v-if="field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10">
                    <img :src="getImage(10)" @click="change($event, 10)">
                </div>
            </div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'SmileyField',

    mixins: [ formMixin ],

    watch: {
        value(newVal) {
            this.value_ = newVal
        }
    },

    methods: {
        change: function ($event, value) {
            this.value_ = value

            this.$emit('value-changed', this.field, value)
        },

        getImage(num) {
            let field = this.field
            let basePath = window.location.protocol + '//' + window.location.host + '/assets/img/smiles/'
            if (field.values[1] !== undefined && field.values[1].value !== undefined ? field.values[1].value == 10 : field.values.max == 10)
                return basePath + '10/' + num  + '.png'

            return basePath + '5/' + num + '.png'
        }
    },

    computed: {
        isMobile() {
            return screen.width <= 768
        }
    }
}
</script>

<style scoped>
div.smiley {
    padding: 0px !important;
    margin: 0px !important;
    max-width: fit-content;
    /* min-height: 100px; */
    opacity: 0.6;
}

div.smiley img {
    max-height: 100px;
}

div.smiley.selected {
    opacity: 1;
}

</style>