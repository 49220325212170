<template>
    <PlainAdmin title="Aanmelden" :withMenu="false" x-class="relative">
        <template>
            <div>
            <div class="font-semibold text-[25px] max-w-full mx-auto md:mx-0 text-center md:text-left text-montserrat mt-10 mb-5">Bedrijf</div>
            <x-register-card :org="company" v-if="company !== null" />

            <div class="font-semibold text-[25px] max-w-full mx-auto md:mx-0 text-center md:text-left text-montserrat mt-20  md:mt-10">Beheerder</div>
            <x-register-admin-card :admin="company" v-if="company !== null" x-class="mt-5" />

            <div class="text-sentiment-blue mt-10 mb-40 md:mb-20 ">*Velden verplicht invullen</div>

            <div class="fixed right-6 bottom-6 w-max">
                <x-button-blue @click="$router.push({name: 'Login'})" x-class="md:inline-block mb-2 md:mb-0 md:mr-2">Annuleren</x-button-blue>
                <x-button-green @click="submit" x-class="md:inline-block mb-2 md:mb-0 md:mr-2">Aanmelden</x-button-green>
            </div>
            </div>
        </template>
    </PlainAdmin>
</template>

<script>
import Loader from "@/components/Loader"
import axios from 'axios';

export default {
  name: "toscani-company-edit",

  data() {
    return {
      isNew: false,
      errors: {
        logo: undefined,
        name: undefined,
        'address.street': undefined,
        'address.number': undefined,
        'address.postalcode': undefined,
        'address.city': undefined,
      },
      logo: null,
      company: {
        company_id: null,
        name: null,
        street: null,
        number: null,
        postalcode: null,
        city: null,
        iban: null,
        kvk: null,
        firstname: null,
        lastname: null,
        email: null,
      },

      loading: [
        false,
        false,
        false,
        false,
        false,
      ],
    };
  },

  components: {
    Loader
  },

   methods: {

    organizationCreated: function () {
      this.$notify({
        type: "success",
        title: "Organisatie aangemaakt",
      });

      this.$router.push({name: 'Registered'})
    },

    submit: function (toIndex = true) {
        let data = Object.assign({}, this.company)

            if (data.projects && typeof data.projects[0] != 'number')
                data.projects = data.projects.map(el => el.id)

            if (data.employees && typeof data.employees[0] != 'number')
                data.employees = data.employees.map(el => el.id)

            if (data.company_id === null) {
                delete data.company_id
            }   

                return axios.post("register", data).then((response) => {
                if (toIndex)
                    this.organizationCreated(response)
                return response
                }, this.error);
    },

    error: function (error) {
      if (413 == error.response.status) {
        this.errors.logo = 'Logo is te groot om te uploaden. Verklein het logo, of kies wat anders.'
        this.logo = null
      }
      else
        this.errors = Object.assign({logo: undefined}, error.response.data.errors);
    }
  }
};
</script>

<style scoped>
table tr:not(tr:first-child) {
  border-top: solid 1px #ccc;
}
</style>