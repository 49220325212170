<template>
    <AppLayout>
        <template>
            <div class="flex flex-col">

            <!-- When there're forms that are about to expire show warning message -->
                <transition name="notification-slide" mode="out-in">
                   <div v-if="showNotification && totalExpiring > 0" class="fixed top-0 left-0 right-0 p-4 bg-yellow-300 text-black font-bold text-center shadow-lg [@media(max-width:768px)]:text-[12px]">
                        <div class="w-full [@media(max-width:768px)]:text-[10px]">
                            <span class="text-red-500">Let op: </span>
                            <span v-if="totalExpiring === 1">{{ totalExpiring }} vragenlijst verloopt binnenkort!</span>
                            <span v-else>{{ totalExpiring }} vragenlijsten verlopen binnenkort!</span>
                        </div>
                        <button @click="dismissNotification" class="absolute top-4 right-6 text-red-500 hover:text-red-700 ease-in-out duration-200 font-semibold">
                            Sluiten
                        </button>
                    </div>
                </transition>

            <!-- Welcome message -->
                <div class="font-semibold text-[25px] [@media(min-width:768px)]:text-[30px] mx-auto md:mx-0 text-center text-montserrat pb-2 md:mt-2 md:mb-2">
                    {{ greeting }}<br class="md:hidden" /> {{ typeof $store.user != 'undefined' && null !== $store.user ? $store.user.firstname : '' }}
                    <svg class="inline-block" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.09839 18.4663C3.93489 18.4663 1.98967 17.4339 1.00979 15.7679C0.79635 15.4091 0.631419 15.0265 0.519848 14.6319C0.456786 14.4207 0.408277 14.2014 0.374321 13.9782C0.141477 12.4278 0.694481 10.8972 1.90236 9.7772C2.9453 8.80466 4.38602 8.24665 5.84614 8.24665C6.36034 8.24665 6.86969 8.31441 7.36933 8.44993C8.2716 8.69306 9.19812 8.81662 10.1198 8.81662H10.2993C11.1967 8.80068 12.0893 8.66915 12.9576 8.42601C13.4524 8.28651 13.9617 8.21875 14.4759 8.21875C15.839 8.21875 17.1585 8.70502 18.1917 9.58588C19.3996 10.6142 20.0496 12.069 19.9768 13.5717C19.9526 14.058 19.8604 14.5283 19.7052 14.9747C19.482 15.6084 19.1231 16.1943 18.638 16.7165C17.6047 17.8325 16.0282 18.4702 14.3158 18.4702H6.10324L6.09839 18.4663Z" fill="currentColor"/>
                        <path d="M10.2026 8.43382C12.9033 8.43382 15.0926 6.54584 15.0926 4.21691C15.0926 1.88797 12.9033 0 10.2026 0C7.50195 0 5.31262 1.88797 5.31262 4.21691C5.31262 6.54584 7.50195 8.43382 10.2026 8.43382Z" fill="currentColor"/>
                    </svg>
                </div>

            <!-- Message that tells user how many forms need to be filled in and how many are newly added-->
                <div class="font-semibold mx-auto md:mx-0 text-center text-sentiment-blue [@media(max-width:768px)]:mb-4 mb-8 [@media(max-width:768px)]:text-[10px]">
                    {{ getFormText }} <br class="[@media(min-width:403px)]:hidden">
                    <span v-if="totalNew() > 0"> ({{ totalNew() }} nieuw)</span>
                </div>


            <!-- Div that holds all the forms -->
                <div class="flex-grow flex-shrink h-[calc(100vh_-_21rem)] [@media(min-width:768px)]:h-[calc(100vh_-_18.25rem)] overflow-y-auto -mr-2 md:-mr-5 scrollbar-thin scrollbar-thumb-black/20 scrollbar-track:rounded scrollbar-thumb-rounded-full scrollbar-track-rounded-full">

                <!-- Grid for the forms -->
                    <div class="grid [@media(min-width:1000px)]:grid-cols-2 [@media(min-width:1550px)]:grid-cols-3 [@media(min-width:2100px)]:grid-cols-4 gap-x-16 gap-y-6 md:gap-y-12 my-10 md:mx-4 justify-items-center">

                        <div v-for="(period) in meetings" :key="period.id" class="relative w-[95%] rounded-2xl backdrop-blur mb-5 hover:backdrop-blur-lg hover:scale-[1.035] ease-in-out duration-200">

                        <!-- Div on top of the card -->
                            <div class="w-full font-semibold text-white flex flex-wrap py-2 rounded-t-2xl justify-center shadow-sentiment"
                                :class="period.form.type === 'employee' ? 'bg-purple-800' : 'bg-indigo-600'">
                            <!-- Conditional rendering based on form type -->
                                <div v-if="period.form.type === 'employee'">Medewerkersformulier</div>
                                <div v-else>Bedrijfsformulier</div>
                            </div>

                        <!-- Card that holds the information for every form -->
                            <div class="w-full font-semibold shadow-xl text-black gap-x-16 flex flex-wrap bg-black/10 px-5 py-7 rounded-b-2xl">

                            <!-- If form hasn't been finished yet, show grayed out checkmark -->
                                <div v-if="!showWarning(period)">
                                <!-- White circle with checkmark -->
                                    <span class="absolute flex h-7 w-7 right-3 top-[51px] opacity-80 items-center justify-center">
                                        <span class="inline-flex h-full w-full rounded-full bg-white/60"></span>
                                        <i class="fa-solid fa-circle-check fa-xl absolute text-gray-400/40" style="top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
                                    </span>
                                </div>

                            <!-- If the form is finished, show checkmark -->
                                <div v-if="period.user_finished && !showWarning(period)">
                                    <span class="absolute flex h-7 w-7 right-3 top-[51px] items-center justify-center">

                                    <!-- White circle with checkmark -->
                                        <span class="inline-flex h-full w-full rounded-full bg-white shadow-sentiment"></span>
                                        <i class="fa-solid fa-circle-check fa-xl absolute text-green-400/80" style="top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
                                    </span>
                                </div>

                            <!-- When the form has been newly added and the current date falls within a week of that date, show notification -->
                                <div v-if="showNewlyAdded(period) && !showWarning(period) && !period.user_finished && periodStatus[period.id] === 0" class="absolute flex right-3 top-[51px] [@media(min-width:400px)]:w-max ml-auto h-8 text-base block rounded-full bg-green-400 px-4 shadow-sentiment items-center">
                                    <p class="[@media(max-width:400px)]:text-[12px] text-white mr-[18px]">
                                        Nieuw
                                    </p>
                            <!-- White circle -->
                                    <span class="absolute flex h-5 w-5 right-2">
                                        <span class="inline-flex h-full w-full rounded-full bg-white shadow-sentiment"></span>
                                    </span>
                                </div>

                             <!-- When the form hasn't expired yet and it's 3 days before the expire date, show warning message -->
                                <div v-if="showWarning(period)" class="absolute flex right-3 top-[51px] [@media(min-width:400px)]:w-max ml-auto h-8 text-base block rounded-full bg-red-500 px-4 shadow-sentiment items-center">
                                    <p class="[@media(max-width:400px)]:text-[12px] text-white mr-[18px]">
                                        {{ getDaysUntilExpiration(period) === 0 ? 'Verloopt vandaag' : 'Verloopt ' + (getDaysUntilExpiration(period) === 1 ? 'morgen' : 'over ' + getDaysUntilExpiration(period) + ' dagen') +'!' }}
                                    </p>
                                <!-- White circle -->
                                    <span class="absolute flex h-5 w-5 right-2">
                                        <span class="inline-flex h-full w-full rounded-full bg-white shadow-sentiment"></span>
                                    </span>
                                </div>

                            <!-- Form name-->
                                <div class="leading-10 w-full text-[22px] mt-4">
                                    {{ period.name  }}
                                </div>

                            <!-- Due date -->
                                <div class="leading-10 mb-1 font-light">Inleverdatum: {{ period.tillFormatted }}</div>

                            <!-- When 0 progress has been made show:"Nog geen voortgang". If this is the case, show the percentage -->
                                <a v-if="period.form.type != 'employee'" :role="'employee' !== period.form.type && period.user_finished ? '' : 'button'"
                                   :class="'employee' !== period.form.type && periodStatus[period.id] === 100 ? 'cursor-default bg-green-500/80 col-span-2' : 'cursor-default bg-sentiment-blue'"
                                    class="grow basis-full w-full text-center h-10 text-[0.89rem] text-base text-white rounded-lg py-2 px-8 font-semibold"
                                >
                                    {{ periodStatus[period.id] === 0 ? 'Nog geen voortgang' : statusPeriod(period) + ' voltooid' }}
                                </a>

                                <a v-if="period.form.type === 'employee' && periodStatus[period.id] < 100" :role="'employee' !== period.form.type && period.user_finished ? '' : 'button'"
                                   :class="'employee' === period.form.type && period.user_finished ? 'cursor-default bg-green-500/80 col-span-2' : 'cursor-default bg-sentiment-blue'"
                                    class="grow basis-full w-full text-center h-10 text-[0.89rem] [@media(max-width:400px)]:text-[13px] text-base text-white rounded-lg py-2 px-8 font-semibold"
                                >
                                    {{ periodStatus[period.id] < 100 ? 'Medewerkers overgeslagen' : 'Nog geen voortgang' }}
                                </a>

                            <!-- Button for accessing form. Doesn's show when form is finished -->
                                <a v-if="! period.user_finished && periodStatus[period.id] < 100" :role="'employee' !== period.form.type && period.user_finished ? '' : 'button'"
                                   :class="'employee' !== period.form.type && period.user_finished ? 'hidden' : 'grow basis-full w-full text-center h-16 text-[0.89rem] text-base text-white rounded-lg py-5 px-8 font-semibold cursor-pointer bg-sentiment-green shadow-sentiment mt-5 hover:scale-[1.025] hover:shadow-sentimentDarker ease-in-out duration-200'
                                   " @click="goto(period)">
                                        {{ periodStatus[period.id] === 0 ? 'Start' : (periodStatus[period.id] > 0 && period.user_finished) ? 'Bewerk' : 'Ga verder' }}
                                </a>

                            <!-- Show when there's no form present -->
                                <p v-if="null === period.form_id">
                                    Geen formulier beschikbaar!
                                </p>

                            <!-- When form is finished, show this message instead of the button -->
                                <p class="block text-black text-md font-normal basis-full h-16 py-5 mt-5 col-span-2 text-center" v-if="(period.user_finished || 100 == periodStatus[period.id])">
                                    Bedankt voor het invullen!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            <!-- Bottom navigation buttons -->
                <div class="flex gap-4 fixed place-items-end md:right-16 bottom-6 md:h-10 mt-6 flex-col-reverse text-right md:flex-row-reverse mx-6 md:mx-0 left-0 right-0">
                    <x-button-green x-class="w-max px-4 md:ml-0" @click="$root.logoff()">Uitloggen</x-button-green>

                    <x-button-blue x-class="w-max px-4 md:ml-0" @click="$router.push({
                        name: 'user-profile'
                    })">Wachtwoord&nbsp;aanpassen</x-button-blue>

                </div>
            </div>
        </template>
    </AppLayout>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
// import Meeting from '@/components/Meeting'

export default {
    name: 'employee-dashboard',

    data: () => ({
        meetings: [],
        periodStatus: [],
        showNotification: true,
	}),

    components: {
        // meeting: Meeting
    },

    created: function () {
        // let _self = this
        this.getResults()
    },

    computed: {
        greeting: () => {
            switch(true) {
                case moment().isBefore(moment().hour(12).minute(0)):
                    return 'Goedemorgen'
                case moment().isAfter(moment().hour(18).minute(0)):
                    return 'Goedenavond'
                case moment().isAfter(moment().hour(12).minute(0)):
                    return 'Goedemiddag'
            }

            return 'Hoi'
        },

        pastMeetings: function () {
            return this.meetings.filter(function (meeting) {
                if (Date.parse(meeting.start) < Date.now())
                    return true
            })
        },

        futureMeetings: function () {
            return this.meetings.filter(function (meeting) {
                let meetingStart = new Date(Date.parse(meeting.start))
                let now = new Date

                if (meetingStart.getFullYear() > now.getFullYear() ||
                    (
                        meetingStart.getFullYear() == now.getFullYear &&
                        (
                            meetingStart.getMonth() > now.getMonth() ||
                            meetingStart.getMonth() == now.getMonth() &&
                            meetingStart.getDate() > now.getDate()
                        )
                    )
                )
                    return true
            })
        },

        currentMeeting: function () {
            let currentMeeting = this.meetings.filter(function (meeting) {
                let meetingStart = new Date(Date.parse(meeting.start))
                let now = new Date

                if (meetingStart.getFullYear() == now.getFullYear() &&
                    meetingStart.getMonth() == now.getMonth() &&
                    meetingStart.getDate() == now.getDate()
                )
                    return true
            })
            return currentMeeting.length > 0 ? currentMeeting[0] : false
        },

        totalForms() {
            // Filter out the finished forms (where user_finished is false)
            let unfinishedForms = this.meetings.filter(period => !period.user_finished)

            // Return the count of unfinished forms
            return unfinishedForms.length
        },

        getFormText() {
            if (this.totalForms === 0) {
                return `Op dit moment zijn er geen vragenlijsten beschikbaar`
            } else if (this.totalForms === 1) {
                return `Er staat ${this.totalForms} vragenlijst voor je klaar om ingevuld te worden`
            } else {
                return `Er staan ${this.totalForms} vragenlijsten voor je klaar om ingevuld te worden`
            }
        },


        totalExpiring() {
            // Filter the forms based on the showRedDot condition
            let formsWithWarning = this.meetings.filter(period => this.showWarning(period))

            // Return the count of forms that meet the condition
            return formsWithWarning.length
        },
    },

    methods: {
        statusPeriod(period) {
            if (period.user_finished && typeof this.periodStatus[period.id] !== 'undefined' && 100 == this.periodStatus[period.id])
                return '100%'

            let status = typeof this.periodStatus[period.id] == 'undefined' ? 'Laden...' :
                this.periodStatus[period.id] + '%'

            return status
        },

        getPeriodStatus(period) {
            return
        },

        showWarning(period) {
            if (!period.user_finished) {
                // Calculate date (3 days before period.tillFormatted)
                let expiringDate = moment(period.tillFormatted, 'DD-MM-YYYY').subtract(3, 'days')

                let currentDate = moment()

                // Check if the current date is equal to or more than the expiring date
                return currentDate.isSameOrAfter(expiringDate)
            }

            return false
        },

        showNewlyAdded(period) {
            if (!period.user_finished) {
                // Calculate date (3 days after period.fromFormatted)
                let newDate = moment(period.fromFormatted, 'DD-MM-YYYY').add(7, 'days')

                let currentDate = moment()

                // Check if the current date falls between the two other dates
                return currentDate.isBetween(moment(period.fromFormatted, 'DD-MM-YYYY'), newDate )
            }

            return false
        },

        totalNew() {
            // Filter the forms based on the showNewlyAdded condition, where the warning message isn't active, and periodStatus is 0
            let formsWithNewlyAdded = this.meetings.filter(period =>
                this.showNewlyAdded(period) &&
                !this.showWarning(period) &&
                this.periodStatus[period.id] === 0
            )

            // Return the count of forms that meet the condition

            return formsWithNewlyAdded.length
        },

        getDaysUntilExpiration(period) {
            if (!period.user_finished) {
                // Calculate date (3 days before period.tillFormatted)
                let expiringDate = moment(period.tillFormatted, 'DD-MM-YYYY').add(1, 'days')
                let currentDate = moment()

                // Calculate the number of days remaining until the expiration date
                let daysUntilExpiration = expiringDate.diff(currentDate, 'days')

                return daysUntilExpiration
            }
        },

        dismissNotification() {
            // Hide the notification when the close button is clicked
            this.showNotification = false;
        },

        getResults(page = 1) {
            let params = new URLSearchParams()
            if (page > 1)
                params.set('page', page)

            // let limit = 10

            // if (window.innerHeight < 915)
            //     limit = 8

            // if (window.innerWidth < 768)
            //     limit = 4

            // params.set('per_page', limit)

            axios.get('/sentiment', {params: params}).then(response => {
                this.meetings = response.data;

                if (typeof this.$route.query.go != 'undefined' && this.meetings.length == 1) {
                    this.$router.push({name: 'sentiment-form', params: {
                        period_id: this.meetings[0].id
                    }})
                }

                for(let period of this.meetings)
                    axios.get(`/sentiment/${period.id}/status`).then((response) => {
                        this.periodStatus[period.id] = Math.round(response.data.percentage * 100) / 100
                        this.$forceUpdate()
                    })
            }).catch(error => {})
        },

        longValid(meeting) {
            let till = moment(meeting.till, 'DD-MM-YYYY')

            return Math.floor(moment(till).diff(moment(), 'years')) > 1
        },

        isFuture(meeting) {
            let now = new Date
            let start = new Date(Date.parse(meeting.start))

            return start.getFullYear() >= now.getFullYear() && ((start.getMonth() == now.getMonth() && start.getDate() > now.getDate()) || start.getMonth() > now.getMonth())
        },

        isPast(meeting) {
            let now = new Date
            let start = new Date(Date.parse(meeting.start))

            return start.getFullYear() <= now.getFullYear() && ((start.getMonth() == now.getMonth() && start.getDate() < now.getDate()) || start.getMonth() <= now.getMonth())
        },

        to(period) {
            return {
                name: 'sentiment-form',
                params: {
                    period_id: period.id
                }
            }
        },

        goto(period) {
            if ('employee' !== period.form.type && period.user_finished)
                return

            this.$router.push(this.to(period))
        }
    }
}
</script>

<style scoped>
    .notification-slide-enter-active, .notification-slide-leave-active {
        transition: transform 0.5s;
    }

    .notification-slide-enter-from, .notification-slide-leave-to {
        transform: translateY(-100%);
    }
</style>