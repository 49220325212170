<template>
    <Admin :title="isNew ? 'Nieuwe periode toevoegen' : (period ? `Periode ${period.name} bewerken` : 'Periode Laden...') ">
        <template>
            <x-period-card :period="period" v-if="period" @save="submit" x-class="w-full xl:w-3/4" />

           <div class="fixed right-6 bottom-6 w-max">
            <x-button-blue @click="$router.go(-1)" x-class="[@media(min-width:885px)]:inline-block mb-2 [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-4">Annuleren</x-button-blue>
            <x-button-green @click="submit" x-class="[@media(min-width:885px)]:inline-block [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]=mr-2">Opslaan</x-button-green>
          </div>

        </template>
    </Admin>
</template>

<script>
import axios from 'axios';
import datePicker from 'vue2-datepicker'
import moment from 'moment'
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/nl";

export default {
    data: () => ({
            period: {},
            moment: moment,
            isNew: false
	}),

    components: {
        datePicker
    },

    created: function () {
        if (this.$route.params.id == 'create') {
            this.period = {
                from: null,
                till: null,
            form_id: null
            }
            this.isNew = true
        } else {
            axios.get(`admin/period/${this.$route.params.id}`).then(response => {
                this.period = response.data
            })
        }
    },

    methods: {
        validDate(date) {
            if (date < moment().startOf('day')) return true

            return false
        },

        submit() {
            if (typeof this.period.id === 'undefined') {
                axios.post('admin/period', this.period).then(() => {
                    this.$notify({
                        type: 'sucess',
                        text: 'Periode opgeslagen'
                    })
                    this.$router.push({name: 'period-index'})
                }).catch(error => {
                    console.log(error)
                })
            } else {
                axios.put(`admin/period/${this.period.id}`, this.period).then(() => {
                    this.$notify({
                        type: 'sucess',
                        text: 'Periode opgeslagen'
                    })
                    this.$router.push({name: 'period-index'})
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>