<template>
    <div>
        <p
            class="text-center text-sentiment-blue font-semibold py-3 px-6 relative">

            <a role="button" @click="prev()" class="text-sentiment-green inline-block mr-3">
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="translate-y-1">
                    <path d="M10 17L2 9.5L10 2" stroke="currentColor" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </a>

            <a @click="toggleUsers()" id="showUsersBtn" role="button" class="relative">
                {{ typeof user !== 'undefined' ? user.name : 'Loading...' }}

                <ul class="absolute z-50 top-10 right-1/2 translate-x-1/2 bg-black/40 p-4 rounded-2xl backdrop-blur-lg shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none"
                    v-if="showUsers" v-click-outside="hideUsers">
                    <li v-for="(user, key, idx) in users" class="font-light text-white leading-relaxed whitespace-nowrap" @click="$emit('selectUser', idx)">{{ user.name }}</li>
                </ul>
            </a>

            <a role="button" @click="next()" class="text-sentiment-green inline-block ml-3">
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="translate-y-1">
                    <path d="M2 2L10 9.5L2 17" stroke="currentColor" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </a>
<!--
            <template v-if="typeof users !== 'undefined'">
                <i class="fa-solid fa-person-circle-question fa-lg text-white absolute right-5 top-4 hover:cursor-pointer"
                    @click="toggleUsers()" id="showElementsBtn">
                </i>
                <i class="fa-solid fa-person-circle-xmark fa-lg text-red-500 bg-sentiment-blue absolute right-5 top-4 hover:cursor-pointer"
                    v-if="showUsers" v-click-outside="hideUsers" @click="toggleUsers()" id="hideElementsBtn">
                </i>

             </template> -->
        </p>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'UserSwitcher',

    props: {
        'users': {},
        'user': {},
        'onMobile': {},
        'isToggled': {},
        'noUsers': {},
        'isEmployee': {
            type: Boolean,
            default: () => false
        },
    },

    data: () => ({
        showUsers: false,
    }),

    methods: {
        prev() {
            this.$emit('prev')
        },

        next() {
            this.$emit('next')
        },

        prev2() {
            this.$emit('prev2')
        },

        next2() {
            this.$emit('next2')
        },

        toggleUsers() {
            this.showUsers = !this.showUsers
        },

        hideUsers(e) {
            if (e.target == document.querySelector('#showUsersBtn'))
                return false

            this.showUsers = false
        },
    }
}
</script>