<template>
    <nav class="text-white text-base justify-center items-center font-base">
        <x-nav-item v-if="$store.isLoggedIn" url="/" @click="closeMenu" icon="fa-solid fa-chalkboard text-[25.77px] h-[38.65px] py-1.5" :active="$route.path == '/'">
            Dashboard
        </x-nav-item>

        <x-nav-item :url="{ name: 'user-list' }" icon="fa-solid fa-user-group text-[23.2px] h-[38.65px] py-1.5" :active="$route.name != 'user-import' && $route.name.startsWith('user')" v-if="$store.is_admin">
            Gebruikers
        </x-nav-item>

        <x-nav-item :url="{ name: 'user-import' }" icon="fa-solid fa-upload text-[29px] h-[38.65px] py-1" :active="$route.name == 'user-import'" v-if="$store.is_admin">
            Gebruikers importeren
        </x-nav-item>

        <x-nav-item :url="{ name: 'company-index'}" icon="fa-solid fa-building text-[38.65px]" :active="$route.name.startsWith('company')" v-if="$store.is_admin">
            Vestigingen
        </x-nav-item>

        <x-nav-item :url="{ name: 'department-index'}" icon="fa-solid fa-building text-[38.65px]" :active="$route.name.startsWith('department')" v-if="$store.is_admin">
            Afdelingen
        </x-nav-item>

        <x-nav-item :url="{
            name: 'form-index'
        }" icon="fa-solid fa-clipboard text-[38.65px]" :active="$route.name.startsWith('form')">
            Formulieren
        </x-nav-item>

        <x-nav-item :url="{ name: 'period-index'}" icon="fa-solid fa-calendar text-[33.14px] h-[38.65px] py-0.5" :active="$route.name.startsWith('period')" v-if="$store.is_admin">
            Periodes
        </x-nav-item>

        <x-nav-item :url="{ name: 'compensation-index'}" icon="compensation" :active="$route.name.startsWith('compensation')" v-if="$store.is_admin">
            Vergoedingen
        </x-nav-item>

        <x-nav-item :url="{ name: 'result-index'}" icon="chart" :active="$route.name.startsWith('result')" v-if="$store.is_admin">
            Resultaten
        </x-nav-item>

        <!-- <x-nav-item :url="{ name: 'compensation-index'}" icon="compensation" :active="$route.name.startsWith('compensation')" v-if="$store.is_admin">
            Vergoedingen
        </x-nav-item> -->
    </nav>
</template>

<script>
export default {
    props: ['closeMenu'],
}
</script>