<template>
    <div>
        <FormComponent :form="form" :formValues="values" @send-form="sendForm" style="padding-bottom: 100px;"></FormComponent>
    </div>
</template>

<script>
import Form from '@/components/Form'

export default {
    name: 'form-modal',

    props: {
        'form': {
            required: true
        },
        'values': {
            type: Array,
            default: function () {
                return []
            }
        },
        'bus': {
            type: Object,
            default: function () {
                return {}
            }
        }
    },

    components: {
        'FormComponent' : Form
    },

    methods: {
        sendForm(values) {
            if (null === this.bus) {
                this.$emit('send-form', values)
                this.$emit('close')
            } else
                this.bus.sendForm(values)

        }
    }
}
</script>