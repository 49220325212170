<template>
    <Admin :title="isNew ? 'Nieuw formulier' : 'Formulier bewerken'" :hasTooltips="true" xClass="relative">
        <div class="hidden md:block h-0" ref="onMobile"></div>
        <template>
            <div id="forms"
                class="md:grid grid-flow-row gap-y-2 box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl px-5 py-3 xl:w-3/4 bg-white text-black w-full mb-2">
                <div class="xl:grid grid-cols-12 items-center">
                    <label class="font-bold text-base text-monsterrat">Naam</label>
                    <div class="col-span-11 mt-2">
                        <input type="text" v-model="form.name" :class="{ error: errors.name }"
                            class="bg-gray-100 rounded-full w-input h-input py-2 px-4" name="name" />
                        <span class="error" v-if="errors.name">{{ errors.name[0] }}</span>
                    </div>
                </div>

                <div class="xl:flex flex-row items-center mb-4">
                    <label class="font-bold text-base text-monsterrat xl:basis-1/12">Voor</label>
                    <div class="xl:basis-11/12 mt-2">
                        <v-select type="text" v-model="form.type" :class="{ error: errors.for }"
                            class="bg-gray-100 rounded-full w-input h-input pl-4 pr-2" name="name" :reduce="val => val.id"
                            :options="[
                                {
                                    id: 'company',
                                    label: 'Bedrijf'
                                },
                                {
                                    id: 'employee',
                                    label: 'Medewerker'
                                }
                            ]">
                            <option value="company">Bedrijf</option>
                            <option value="employee">Medewerker</option>
                        </v-select>
                        <span class="error" v-if="errors.for">{{ errors.for[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-row gap-10 mt-4 text-black">
                <div name="elements" class="hidden xl:block basis-44 mb-20">
                    <ul class="button-type" :class="elementsClass">
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('additional')">Aanvullende tekst</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('group')">Groep</li>
                        <li v-if="form.type == 'employee'"
                            class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('employees')"
                            title="Groep velden voor medewerker. Voor formulier voor medewerkers.">Medewerkers</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('text')">Tekstregel</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('textarea')">Tekstvak</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('select')">Selectbox</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('radio')">Radiobutton</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('checkbox')">Checkbox</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('number')">Cijfer</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addFieldMobile('scale')">Schaal</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('smiley')">Smiley</li>
                        <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                            @click="addField('upload')">Bestand</li>
                    </ul>
                </div>

                <div class="w-full xl:w-3/4 mb-44 md:mb-20" :class="fieldsClass">
                    <form-field :field="{ fields: fields, type: 'group' }" :errors="errors" @duplicate="duplicate"
                        :delay="onMobile() ? 100 : 0" />

                    <div
                        class="border-slate-100 border-2 rounded-xl w-full xl:hidden mt-4 mb-4 bg-slate-100 shadow-sentiment hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-300">
                        <div class=" flex xl:hidden w-full justify-evenly justify-items-center content-evenly items-center p-4 hover:cursor-pointer"
                            @click="toggleElements()" id="showElementsBtn">
                            <div class="w-max"><i class="fas fa-plus text-black"></i></div>
                        </div>

                        <ul class="fixed z-50 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-black bg-opacity-30 border-solid border border-gray-400 p-4 rounded-xl"
                            :class="elementsClass" v-if="showElements" v-click-outside="hideElements">
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('additional')">Aanvullende tekst</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('group')">Groep</li>
                            <li v-if="form.type == 'employee'"
                                class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('employees')"
                                title="Groep velden voor medewerker. Voor formulier voor medewerkers.">Medewerkers</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('text')">Tekstregel</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('textarea')">Tekstvak</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('select')">Selectbox</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('radio')">Radiobutton</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('checkbox')">Checkbox</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('number')">Cijfer</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('scale')">Schaal</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('smiley')">Smiley</li>
                            <li class="p-2 bg-slate-100 mb-2 border-2 rounded-xl border-slate-100 cursor-pointer w-full text-center shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-200 hover:border-slate-200 md:ease-in-out md:duration-200"
                                @click="addFieldMobile('upload')">Bestand</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="fixed bottom-6 right-6 w-max">
                <x-button-blue @click="$router.go(-1)"
                    x-class="[@media(min-width:1050px)]:mr-2 [@media(min-width:1050px)]:inline-block [@media(max-width:1049px)]:mb-2">
                    Annuleren
                </x-button-blue>
                <x-button-green @click="preview = true"
                    x-class="[@media(min-width:865px)]:mr-2 [@media(min-width:865px)]:inline-block [@media(max-width:864px)]:mb-2">
                    Voorbeeld
                </x-button-green>
                <x-button-green @click="saveForm" x-class="md:inline-block">
                    <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
                    Opslaan
                </x-button-green>
            </div>

            <!-- Confirmation message -->
            <div v-if="showConfirmModal"
                class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div class="bg-white rounded-lg p-6 w-11/12 md:w-4/12 shadow-xl">
                    <p class="text-xl mb-4 text-sentiment-green">Weet je zeker dat je dit veld wilt verwijderen?</p>
                    <div class="flex justify-end space-x-4">
                        <button @click="cancelDelete"
                            class="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 ease-in-out duration-150">Nee</button>
                        <button @click="confirmDelete"
                            class="px-4 py-2 text-white bg-red-400 rounded-md hover:bg-red-500 ease-in-out duration-150">Ja</button>
                    </div>
                </div>
            </div>

        </template>
    </Admin>
</template>

<script>
import axios from 'axios'
import FormModal from '@/components/modals/formModal'
import FormField from '@/components/Admin/FormField'

export default {
    name: 'ToscaniFormEdit',
    components: {
        FormField
    },
    data: function () {
        return {
            showConfirmModal: false,
            indexToDelete: null,
            isNew: false,
            loading: false,
            errors: {},
            deletedFields: [],
            form: {
                name: '',
                type: 'company',
            },
            fields: [],
            preview: false,
            elementsClass: null,
            fieldsClass: null,
            showElements: false,
        }
    },

    created() {
        document.addEventListener('scroll', () => {
            this.elementsClass = {}
            this.fieldsClass = {}
            if (document.documentElement.scrollTop > 170) {
                this.elementsClass = {
                    'md:fixed': true,
                    'md:top-10': true,
                    'md:w-44': true,
                }
                this.fieldsClass = {
                    // 'xl:ml-[calc(176px_+_2rem)]': true
                }
            }
        })

        let url = '/admin/form/'

        if (this.$route.name.startsWith('toscani')) {
            url = url.replace('admin', 'toscani')
        }
        if ('create' == this.$route.params.id) {
            this.isNew = true
        } else {
            axios.get(url + this.$route.params.id).then(response => {
                this.form.name = response.data.name
                this.form.type = response.data.type
                this.fields = response.data.own_fields

                this.fields.sort((a, b) => a.order > b.order ? 1 : (a.order == b.order ? 0 : -1))
            }, () => {
                console.log('Something went wrong!', 'Toscani/Form/Edit')
            })
        }
    },

    watch: {
        preview(newVal) {
            let form = Object.assign({}, this.form)
            form.own_fields = JSON.parse(JSON.stringify(this.fields))

            if (true === newVal)
                this.$modal.show(FormModal, { form: form, bus: this }, { height: 'auto', scrollable: true, minHeight: 0 }, { 'send-form': () => { this.preview = false }, closed: () => { this.preview = false } })
        }
    },

    methods: {
        onMobile() {
            return typeof this.$refs.onMobile !== 'undefined' && !this.$refs.onMobile.checkVisibility()
        },

        addFieldMobile(field) {
            this.addField(field)
            this.showElements = false
        },

        hideElements(e) {
            console.log('hideElements', e)
            if (e.target == document.querySelector('#showElementsBtn'))
                return false

            this.showElements = false
        },

        toggleElements() {
            this.showElements = !this.showElements
        },

        fieldError(index, val) {
            let key = 'fields.' + index + '.' + val
            return typeof this.errors[key] !== 'undefined' ? this.errors[key][0] : false
        },
        fieldErrors(index) {
            let key = `fields.${index}`
            let errors = []
            for (let k in this.errors) {
                if (k.startsWith(key)) {
                    errors.push(this.errors[k])
                }
            }
            return errors
        },
        saveForm() {
            let form = {
                name: this.form.name,
                type: this.form.type,
                fields: [],
            }

            for (let index in this.fields) {
                let field = {
                    id: this.fields[index].id ?? 0,
                    order: index,
                    name: this.fields[index].name,
                    label: this.fields[index].name,
                    type: this.fields[index].type,
                    required: this.fields[index].required,
                    values: this.fields[index].values,
                    deleted: false
                }

                if (this.isNew && 'number' == field.type) {
                    let values = {};
                    values.min = field.values[0].value
                    values.max = field.values[1].value
                    field.values = values
                }

                if ('group' == field.type || 'employees' == field.type) {
                    field.fields = this.fields[index].fields
                }

                form.fields.push(field)
            }

            for (let index in this.deletedFields) {
                if (typeof this.deletedFields[index] !== 'undefined' &&
                    typeof this.deletedFields[index].id !== 'undefined' &&
                    null !== this.deletedFields[index].id
                ) {
                    let field = {
                        id: this.deletedFields[index].id,
                        deleted: true
                    }

                    form.fields.push(field)
                }
            }

            this.loading = true

            let url = '/admin/form'
            let route = 'form-index'
            if (this.$route.name.startsWith('toscani')) {
                url = url.replace('admin', 'toscani')
                route = 'toscani-' + route
            }

            if (this.isNew) {
                axios.post(url, form).then(response => {
                    if (response.status == 201) {
                        this.deletedFields = []
                        this.errors = {}
                        this.$router.push({ name: route })
                        this.$notify({
                            text: 'Formulier aangemaakt',
                            type: 'success',
                        })
                    }
                }).catch(error => {
                    if (error.response && error.response.status == 422) {
                        this.errors = error.response.data.errors
                    } else {
                        console.log(error)
                    }
                }).finally(() => {
                    this.loading = false
                })
            } else {
                axios.put(`${url}/${this.$route.params.id}`, form).then((response) => {
                    if (response.status == 200) {
                        this.deletedFields = []
                        this.errors = {}
                        this.$router.push({ name: route })
                        this.$notify({
                            text: 'Formulier geüpdatet',
                            type: 'success',
                        })
                    }
                }).catch((error) => {
                    if (error.response && error.response.status == 422) {
                        this.errors = error.response.data.errors
                    } else {
                        console.log(error)
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        isDeleted(field) {

            for (let key in this.deletedFields) {
                if (this.deletedFields[key].id == field.id) {
                    return true
                }
            }

            return false

        },

        remove(index) {
            this.showConfirmModal = true;
            this.indexToDelete = index;
        },

        confirmDelete() {
            if (this.indexToDelete !== null) {
                this.deletedFields.push(this.fields[this.indexToDelete]);
                this.fields.splice(this.indexToDelete, 1);
                this.indexToDelete = null;
            }
            this.showConfirmModal = false;
        },

        cancelDelete() {
            this.indexToDelete = null;
            this.showConfirmModal = false;
        },

        removeFieldFromGroup(group, field) {
            let confirmDeleteMethodTmp = this.confirmDeleteMethod
            let gidx = this.fields.findIndex((fElement) => {
                console.log(fElement, group)
                return fElement.id == group.id
            })
            console.log(group, gidx)
            this.confirmDeleteMethod = () => {
                let idx = this.fields[gidx].fields.findIndex((gField) => {
                    return gField.id = field.id
                })
                if (idx !== -1)
                    delete this.fields[gidx].fields.splice(idx, 1)

                this.deletedFields.push(field);
                this.showConfirmModal = false

                this.confirmDeleteMethod = confirmDeleteMethodTmp
                return true
            }
            this.showConfirmModal = true
        },

        removeValue(value) {
            value.deleted = true
        },

        fieldTemplate(type) {
            let tpl = {
                id: null,
                order: 0,
                name: null,
                label: null, // ? meerwaarde
                required: 0,
                values: {},
                type: type,
                deleted: false
            }

            if (type == 'group' || type == 'employees') {
                tpl.fields = [];
            }

            if (type == 'number' || type == 'scale') {
                tpl.values = [
                    {
                        value: null,
                    },
                    {
                        value: null,
                    }
                ]
            }

            if (type == 'smiley') {
                tpl.values = {
                    min: 1,
                    max: 5,
                }
            }

            if (type == 'additional') {
                tpl.name = tpl.label = 'Aanvullende tekst'
                tpl.values = [{
                    value: ''
                }]
            }

            return tpl
        },
        getFieldTypeName(type) {

            let types = {
                text: 'Tekstregel',
                textarea: 'Tekstvak',
                select: 'Selectbox',
                radio: 'Radiobutton',
                checkbox: 'Checkbox',
                number: 'Cijfer',
                smiley: 'Smileys',
            }

            return types[type]
        },
        addField(type) {
            this.fields.push(this.fieldTemplate(type))
            window.scrollTo(0, document.body.scrollHeight)
        },

        duplicate(field) {
            this.fields.push(JSON.parse(JSON.stringify(field)))
            this.$nextTick(function () {
                document.querySelector('.field-wrap:last-child').scrollIntoView()
            })
        },

        sendForm() {
            this.preview = false
        }
    },
}
</script>