<template>
    <router-link :to="url" class="flex items-center  text-white py-2 pl-12 nav-item " :class="{ 'uppercase': active }">
        <i class="w-4 text-sentiment-green mr-3" :class="icon"></i>

       <slot></slot>

    </router-link>
</template>

<script>
export default {
    name: 'nav-item',

    props: ['active', 'url', 'icon']
}
</script>

