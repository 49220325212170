<template>
    <div class="table-scroll-outer">
        <h3>{{ field.name }}</h3>
        <div class="table-scroll">
            <table>
                <tr class="head-row">
                    <th></th>
                    <th v-for="period in periods" :key="period.id">{{ period.name }}</th>
                </tr>
                <tr v-for="(answer, aidx) in results" :key="answer.id">
                    <td class="pl-3 head-col">{{ answer.value }}</td>
                    <td v-for="(result, period) in results[aidx]['periods']" :key="'qr-' + period + '-' + aidx">
                        <progress max="100" :value="getCount(aidx, period)" />
                    </td>
                </tr>
                <tr>
                    <td :colspan="Object.keys(results).length + 1">&nbsp;</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'result-modal',

    data() {
        return {
            results: {},
            periods: []
        }
    },

    props: [
        'period',
        'field',
    ],

    created: async function () {
        let results = await axios.get(`sentiment/${this.period}/form/${this.field.id}/result`)
        this.results = results.data

        for(let answer in this.results) {
            if (this.results[answer]['periods'] !== undefined) {
                for(let period in this.results[answer]['periods']) {
                    this.periods.push({
                        id: this.results[answer]['periods'][period].period_id,
                        name: this.results[answer]['periods'][period].period
                    })
                }
                break
            }
        }
    },

    methods: {
        getCount(adx, period) {
            if (this.results[adx] !== undefined &&
                    this.results[adx]['periods'] !== undefined &&
                    this.results[adx]['periods'][period] !== undefined &&
                    this.results[adx]['periods'][period]['count'] !== undefined
                    )
                return this.results[adx]['periods'][period]['count'] / Math.max(this.results[adx]['periods'][period]['answers'], 1) * 100

            return 0
        }
    }
}
</script>

<style scoped>
h3 {
    margin-bottom: 0;
}
div.table-scroll-outer {
    position:relative;
}
div.table-scroll {
    overflow-x: auto;
    overflow-y: visible;
    margin-left: 200px;
}
.head-col {
    position:absolute;
    width: 200px;
    margin-left: -200px;
}

.head-row {
    height: 3em;
    vertical-align: bottom;
}

.table > tbody > tr {
    position: initial;
}
</style>