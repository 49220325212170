<template>
    <div class="border-sentiment-green font-poppins pr-2 py-2 md:pr-4 md:mr-23 md:ml-1 flex font-normal gap-2" :class="xClass">
        <p class="w-emails">
            <a @click="edit(item[id])" role="button" class="text-black hover:text-sentiment-green ease-in-out duration-150">
                <slot>
                    {{ (prefix ? prefix + ' > ' : '') + item[name] }}
                </slot>

                <slot name="suffix"/>
            </a>
        </p>
        <a @click="edit(item[id])" v-if="! noEdit" class="cursor-pointer ml-3 hover:scale-[1.15] ease-in-out duration-150">
          <i class="fa-regular fa-pen-to-square text-[26px]"></i>
        </a>

        <a role="button" v-if="isRemoveAllowed" :title="item[name] + ' verwijderen'" @click="remove(item[id])" class="cursor-pointer hover:scale-[1.15] hover:text-red-400 ease-in-out duration-150">
           <i class="fa-solid fa-trash text-[26px]"></i>
        </a>

        <p v-else class="w-[26px]"></p>
    </div>
</template>

<script>
export default {
    props: ['item', 'name', 'id', 'isRemoveAllowed', 'prefix', 'x-class', 'noEdit'],

    methods: {
        remove(idx) {
            if (this.isRemoveAllowed)
                this.$emit('remove', idx)
        },

        edit(id) {
            if (this.noEdit)
                return

            this.$emit('edit', id)
        }
    },

}
</script>