export default {
    methods: {
        translate: function  (key) {
            let trans

            if (! (trans = this.$translations[key]))
                return key

            return trans
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$root.msg = null

        if (this.$root.showTooltipLayer) {
          this.$root.showTooltipLayer = false
        }

        next()
      },
}