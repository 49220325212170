<template>
    <Admin
        :title="isNew ? 'Nieuwe vestiging toevoegen' : (settling != null ? `Vestiging bewerken` : 'Vestiging laden...')">
        <template>
            <div class="md:flex items-center gap-16 mt-10 w-full">
                <div class="font-montserrat text-black font-semibold md:inline">Naam&nbsp;*</div>
                <input type="text" class="text-black py-2 px-6 rounded-full text-sm md:text-lg w-full shadow-lg" placeholder="naam"
                    v-model="settling.name">
            </div>

            <div>
                <x-departments-card x-class="mt-10" :departments="settling.departments" @editDepartment="editDepartment"
                    @removeDepartment="removeDepartment" />
            </div>

            <!--Buttons for editing comnpany-->
            <div class="[@media(min-width:450px)]:flex [@media(min-width:450px)]:justify-end -mt-[20px]">
                <div class="w-full [@media(min-width:450px)]:w-max mt-10 [@media(min-width:450px)]:flex [@media(min-width:450px)]:justify-end">
                    <x-button-blue @click="$router.go(-1)" x-class="[@media(min-width:450px)]:inline-block mb-2 [@media(min-width:450px)]:mb-0 [@media(min-width:450px)]:mr-4">Annuleren</x-button-blue>
                    <x-button-green @click="submit" x-class="[@media(min-width:450px)]:inline-block mb-5">Opslaan</x-button-green>
                </div>
            </div>

            <div>
                <x-settings-card  v-if="null == settling.company_id && typeof settling.settings != 'undefined'" :settings="settling.settings" />
            </div>
        </template>
    </Admin>
</template>

<script>
import axios from "axios"
import Loader from '@/components/Loader'
import AppSettings from '@/AppSettings'

export default {
    name: "SettlingEdit",

    data() {
        return {
            isNew: false,
            errors: {
                logo: undefined,
                name: undefined,
                'address.street': undefined,
                'address.number': undefined,
                'address.postcode': undefined,
                'address.city': undefined,
            },
            logo: null,
            settling: {
                name: null,
                address: {
                    street: null,
                    number: null,
                    postcode: null,
                    city: null,
                },
                email: null,
                phone: null,
                pillars: [],
                projects: [],
                employees: [],
                departments: [],
                participants: [],
                newEmployees: [],
            },
            pillars: [],
            projects: [],
            users: [{
                id: -1,
                name: 'Nieuwe gebruiker aanmaken' +
                    '<br><small>Organisatie word eerst opgelagen</small>'
            }],
        };
    },

    components: {
        Loader
    },

    created: function () {
        let _this = this;
        this.reader = new FileReader();

        this.reader.onloadend = function () {
            _this.logo = _this.reader.result;
        };

        //    axios.get('admin/pillar').then(response => _this.pillars = response.data)

        if (this.$route.params.id != "create") {
            axios.get(`admin/company/${this.$route.params.id}`).then(
                (response) => {
                    this.settling = response.data;
                    this.logo = this.settling.logo;

                    if ('undefined' == typeof response.data.address || null === response.data.address) {
                        this.settling.address = {
                            street: null,
                            number: null,
                            postcode: null,
                            city: null,
                        }
                    }

                    this.settling.newEmployees = []
                }
            );
        } else {
            this.isNew = true;
        }
    },

    computed: {
        filteredsettlings: function () {
            let settlings = [];
            for (let idx in this.settlings) {
                settlings.push(this.settlings[idx]);
            }
            return settlings;
        },

        avatar: function () {
            let style = {
                backgroundImage: 'url("/assets/img/avatar-np.jpg")'
            }

            if (this.logo) {
                style.backgroundImage = `url(${this.logo}`
            }

            return style
        },

        departments: function () {
            let departments = [...this.settling.departments]

            for (let settling in this.settling.companies) {
                if (this.settling.companies[settling].departments != undefined) {
                    departments.push(...this.settling.companies[settling].departments)
                }
            }

            return departments
        },
    },

    methods: {
        editDepartment(id) {
            this.$router.push({
                name: 'department-edit',
                params: {
                    id: id
                }
            })
        },

        removeDepartment(id) {

        },

        buttonClass(idx) {
            if (this.$refs[`loader-${idx}`] !== undefined && this.$refs[`loader-${idx}`][0]) {
                return this.$refs[`loader-${idx}`][0].danger ? 'btn-outline-danger' : 'btn-outline-primary'
            }

            return 'btn-outline-primary'
        },

        previewImg: function () {
            let file = this.$refs.file.files[0];
            if (file) {
                this.reader.readAsDataURL(this.$refs.file.files[0]);
            }
        },

        settlingCreated: function () {
            this.$notify({
                type: "success",
                title: "Organisatie aangemaakt",
            });

            this.$router.push({ name: 'settling-list' })
        },

        settlingUpdated: function () {
            this.$notify({
                type: "success",
                title: "Organisatie geüpdatet",
            });

            this.$router.push({ name: 'settling-list' })
        },

        buildFormData: function (formData, data, parent = "") {
            for (let key in data) {
                if (typeof data[key] == "object") {
                    this.buildFormData(formData, data[key], key);
                } else {
                    formData.append(parent != "" ? `${parent}[${key}]` : key, data[key]);
                }

            }
        },

        submit: function (toIndex = true) {
            let data = Object.assign({}, this.settling)
            if (data.pillars && typeof data.pillars[0] != 'number')
                data.pillars = data.pillars.map(el => el.id)

            if (data.projects && typeof data.projects[0] != 'number')
                data.projects = data.projects.map(el => el.id)

            data.employees.push(...data.newEmployees)
            if (data.employees && typeof data.employees[0] != 'number')
                data.employees = data.employees.map(el => el.id)

            if (this.isNew) {

                // if (this.$refs.file.files.length > 0) {
                //     let formData = new FormData();
                //     this.buildFormData(formData, data);

                //     formData.append("logo", this.$refs.file.files[0]);

                //     return axios.post("company", formData, {
                //         headers: { "content-type": "multipart/form-data" },
                //     }).then((response) => {
                //         if (toIndex)
                //             this.settlingCreated(response)
                //         return response
                //     }, this.error);
                // }

                return axios.post("company", data).then((response) => {
                    if (toIndex)
                        this.settlingCreated(response)
                    return response
                }, this.error);
            } else {
                // if (this.$refs.file.files.length > 0) {
                //     let formData = new FormData();
                //     this.buildFormData(formData, data);

                //     formData.append("logo", this.$refs.file.files[0]);
                //     formData.append("_method", "PUT");

                //     return axios.post(`company/${this.settling.id}`, formData, {
                //         headers: { "content-type": "multipart/form-data" },
                //     }).then((response) => {
                //         if (toIndex)
                //             this.settlingUpdated(response)
                //         return response
                //     }, this.error);
                // }

                return axios.put(`admin/company/${this.settling.id}`, data).then((response) => {
                    if (toIndex)
                        this.settlingUpdated(response)
                    return response
                }, this.error);
            }
        },

        error: function (error) {
            if (413 == error.response.status) {
                this.errors.logo = 'Logo is te groot om te uploaden. Verklein het logo, of kies wat anders.'
                this.logo = null
            }
            else
                this.errors = Object.assign({ logo: undefined }, error.response.data.errors);
        },

        findProject(term, loading) {
            let params = new URLSearchParams()
            params.append('forOrg', true)
            params.append('filter[name]', term)
            for (let idx in this.settling.pillars) {
                params.append('filter[pillar][]', this.settling.pillars[idx].id ?? this.settling.pillars[idx])
            }
            loading(true)
            axios.get(`admin//admin/project`, { params: params }).then(response => {
                this.projects = response.data
                loading(false)
            })
        },

        addProject(project) {
            this.settling.projects.push(project)
        },

        removeProject(project) {
            let idx = this.settling.projects.findIndex(el => el.id == project.id)
            if (confirm(`Weet je zeker dat deze organisatie geen toegang meer mag hebben tot ${project.name}?`))
                this.settling.projects.splice(idx, 1)
        },

        findUser(search, loading) {
            loading(true)

            if ('' == search) {
                this.users = [{
                    id: -1,
                    name: 'Nieuwe gebruiker aanmaken' +
                        '<br><small>Organisatie word eerst opgelagen</small>'
                }]

                loading(false)
                return
            }

            let params = new URLSearchParams()
            params.append('filter[name]', search)
            params.append('filter[userGroup]', 'instantiemedewerker')

            axios.get('admin//admin/user', { params: params }).then(response => {
                this.users = response.data.data
                loading(false)
            })
        },

        addUser(user) {
            if (-1 == user.id) {
                this.submit(false).then(response => {
                    let settling_id = response.data.id
                    this.$router.push({
                        name: 'user-edit', params: {
                            id: 'create'
                        }, query: {
                            settling: settling_id
                        }
                    })
                })
            }
            this.settling.newEmployees.push(user)
        },

        removeUser(user) {
            let idx = this.settling.employees.findIndex(el => { return el.id == user.id })

            if (-1 == idx) {
                idx = this.settling.newEmployees.findIndex(el => { return el.id == user.id })

                this.settling.newEmployees.splice(idx, 1)
            }

            if (confirm(`Weet je zeker dat ${user.name} niet meer bij deze organisatie hoord?`)) {
                axios.delete(`admin/settling/${this.settling.id}/employee/${user.id}`).then(response => {
                    if (204 == response.status)
                        this.settling.employees.splice(idx, 1)
                })
            }
        },

        updateSetting: function (event, idx) {
            this.setLoading(idx)
            let setting = this.settling.settings[idx],
                url = `admin/setting/${setting.key}`,
                val = setting.value

            let property
            switch (setting.key) {
                case 'background_color':
                    property = 'background-color'
                    break
                case 'background_image':
                    property = 'background-image'
                    break
                case 'logo':
                    break
                default:
                    property = setting.key
            }
            if (setting.type == 'file') {
                if (this.$refs[`file-${setting.id}`] && this.$refs[`file-${setting.id}`][0].files.length) {
                    let data = new FormData
                    data.append('upload', this.$refs[`file-${setting.id}`][0].files[0])
                    data.append('_method', 'PUT')

                    axios.post(url, data).then((response) => {
                        this.setLoading(idx, false)
                        setting.value = response.data.path

                        this.$root.settings[setting.key] = AppSettings.API_URL.slice(0, -4) + 'storage/' + response.data.path

                        if (property)
                            document.documentElement.style.setProperty('--' + property, this.$root.settings[setting.key])
                    })
                    return
                }

                this.setLoading(idx, false, true)
                return
            }

            axios.put(url, { value: val }).then(() => {
                this.setLoading(idx, false)

                this.$root.settings[setting.key] = setting.value

                if (property)
                    document.documentElement.style.setProperty('--' + property, setting.value)
            })
        },

        removeFile: function (event, idx) {
            let setting = this.settling.settings[idx]
            if (confirm(`Weet je zeker dat je het bestand voor ${setting.key} wilt verwijderen?`)) {
                this.setLoading(idx, true, false, 'remove');
                axios.post(`admin/setting/${setting.key}`, { '_method': 'PUT' }).then(() => {
                    this.setLoading(idx, false, false, 'remove')
                    setting.value = null
                    this.$root.settings[setting.key] = '/assets/img/sentiment-logo.png'
                })
            }
        },

        setLoading: function (idx, state = true, danger = false, ref = 'loader') {
            this.$refs[`${ref}-${idx}`][0].loading = state
            this.$refs[`${ref}-${idx}`][0].danger = danger
        }
    },
};
</script>

<style scoped>
table tr:not(tr:first-child) {
    border-top: solid 1px #ccc;
}

.input-group .btn:not(:last-child) {
    margin-right: 0 !important;
}</style>