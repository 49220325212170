<template>
    <Admin title="Gebruikers importeren" :loading="loading" :hasTooltips="true">
        <template>
            <div class="text-black">
                <div class="is-empty is-fileinput">
                    <input type="file" ref="fileInput" class="absolute -left-full " :class="{ 'is-invalid': errors }" accept="text/csv,.csv" @change="(ev) => filename = ev.target.files[0].name">

                    <input type="text" readonly class="px-3 py-1 w-full md:w-[350px] rounded-full hover:cursor-pointer box-shadow shadow-sentiment hover:shadow-sentimentDarker hover:scale-[1.01] ease-in-out duration-200" placeholder="Selecteer csv-bestand om te importeren" v-model="filename" @click="openFileDialog">
                    <br>
                    <p class="text-slate-500 mt-4">
                        Let op: de csv moet een komma als scheidingsteken gebruiken, &quot; als teken om tekst en datums in het formaat <em>dd-mm-jjjj</em>.<br>
                        De velden active (1) en is_admin (0) zijn optioneel, standaard waarde staat tussen haakjes.
                    </p>

                    <span v-if="errors.csv" class="invalid-feedback">
                        {{ errors.csv }}
                    </span>
                </div>

                <div class="fixed bottom-6 right-6 w-1/2 md:w-max">
                    <x-button-green @click="send" x-class="md:inline-block md:mb-0 md:mr-2 whitespace-nowrap">Importeer bestand</x-button-green>
                </div>

                <h2 class="text-sentiment-green text-2xl font-bold mt-8 mb-2">Voorbeeld</h2>

                <ul class="list-unstyled">
                    <li><a class="hover:text-sentiment-green sease-in-out duration-150" target="_blank" href="/import.csv">Invuldocument <i class="fas fa-download"></i></a></li>
                </ul>

                <h2 class="text-sentiment-green text-2xl font-bold mt-8">Uitleg</h2>

                <div class="mt-2 mb-20">
                    Je kan hier CSV-bestand uploaden met nieuwe gebruikers. <br><br>
                    <strong>Let op:</strong> Gebruikers met hetzelfde emailadres worden overschreven!<br>
                    <br>
                    <div class="text-left">
                        Velden: <br>
                        <ul class="list-disc list-inside">
                            <li><em>firstname</em> (Voornaam)</li>
                            <li><em>lastname</em> (Achternaam)</li>
                            <li><em>email</em> (E-mailadres)</li>
                            <li><em>function</em> (Functie)</li>
                            <li><em>description</em> (Omschrijving, optioneel)</li>
                            <li><em>start_date</em> (Datum begonnen, formaat: dd-mm-jjjj)</li>
                            <li><em>date_of_birth</em> (Geboortedatum, formaat: dd-mm-jjjj)</li>
                            <li><em>settling</em> (Vestiging, wordt aangemaakt indien deze niet bestaat)</li>
                            <li><em>department</em> (afdeling, wordt aangemaakt binnen de vestiging indien deze niet bestaat)</li>
                            <li><em>active</em> (Actief, 0 of 1, optioneel, standaard 1)</li>
                            <li><em>is_admin</em> (Is de gebruiker admin? 0 of 1, optioneel, standaard 0)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </Admin>
</template>

<script>
import Axios from 'axios'
export default {
    name: 'ImportFeature',

    data: () => ({
            filename: null,

            errors: {},
            loading: false,

            roles: [],
            projects: [],
	}),

    created() {

    },

    methods: {
        openFileDialog() {
            this.$refs.fileInput.click()
        },

        send() {
            this.loading = true

            let data = new FormData

            data.append('upload', this.$refs.fileInput.files[0])

            Axios.post('admin/user/import', data)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.messages.length == 0) {
                        this.$notify('Gebruikers succesvol geïmporteerd')
                    } else {
                        this.errors = response.data.messages
                    }
                }
            })
            .finally(() => {
                this.loading = false
            }).catch((error) => {
                console.log(error)
                if (422 == error.response.status && error.response.data.messages !== undefined) {
                    this.errors = error.response.data.messages
                }
            })
        },
    }
}
</script>

<style scoped>
sup {
    color:red;
}

.tippy-tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: black; */
    z-index: 999;
    width: 40%;
    /* color:  white; */
    /* padding: 5px 10px 0px; */
    /* border-radius: 10px; */
}
</style>
