<template>
    <div action="" class="px-4 py-4 lg:grid grid-cols-3 gap-4 bg-white rounded-xl box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200" :class="xClass">
        <div class="mb-4 m-4">
            <label class=" font-bold text-base block text-montserrat mb-2 ">Naam *</label>
            <input type="text" id="voornaam" name="voornaam" placeholder="Naam"
                v-model="period.name"  :disabled="period.compensation_period == 1"
                class="text-black bg-gray-100 rounded-full w-full h-input py-2 px-4 max-w-full">
        </div>
        <div class="mb-4 m-4">
            <label class=" font-bold text-base block text-montserrat mb-2">Vanaf *</label>
            <input type="date" id="vanaf" name="vanaf" placeholder="vanaf" v-model="period.from"
                :min="minDate" :disabled="period.compensation_period == 1"
                class="text-black bg-gray-100 rounded-full w-full h-input py-2 px-4 max-w-full">
        </div>
        <div class="mb-4 m-4">
            <label class=" font-bold text-base block text-montserrat mb-2">Tot *</label>
            <input type="date" id=" tot" name="tot" placeholder="tot" v-model="period.till"
                :min="minTill" :disabled="period.compensation_period == 1"
                class="text-black bg-gray-100 rounded-full w-full h-input py-2 px-4 max-w-full">
        </div>
        <div class="mb-4 col-span-2 mx-4">
            <label class=" font-bold text-base block text-montserrat mb-2">Onderzoeksvraag</label>
            <v-select class="bg-gray-100 rounded h-[36px] pl-4 pr-3 md:h-input text-black w-full"
                :options="forms" label="name" v-model="period.form_id"
                :disabled="period.compensation_period == 1"
                :reduce="option => option.id" />
        </div>
        <div class="mb-4 mx-4">
            <label class="font-bold text-base block text-montserrat mb-2">Anonimiseren</label>
            <label class="text-black text-base">
                <input type="checkbox" v-model="period.anonimize" :disabled="periodStarted" class="mr-4">
                Ja
            </label>
            <div class="text-gray-500 italic" v-if="periodStarted">
                De periode is al begonnen, dus je kan het anonimiseren niet meer wijzigen.
            </div>
        </div>
        <div class="basis-full h-0"></div>
        <!-- <div class="mb-4">
            <label class="font-bold text-base block text-monsterrat">
                Periode om vergoedingen te bepalen
            </label>
            <label class="text-black text-base">
                <input type="checkbox" v-model="period.compensation_period" class="mr-4">
                Ja
            </label>
        </div> -->
        <div class="mb-4 mx-4" v-if="period.compensation_period">
            <label class="font-bold text-base block text-monsterrat mb-2">
                Inhoud vergoedingen pot
            </label>
            <input type="number" step="0.10" v-model="period.compensation_amount"
                class="text-black bg-gray-100 rounded-full w-input h-input py-2 px-4 max-w-full">
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    props: ['period', 'x-class'],

    data: () => ({
        forms: [],
    }),

    created() {
        axios.get('admin/form?noPaginate').then(response => {
            this.forms = response.data
        })
    },

    computed: {
        minDate() {
            return moment().format('Y-MM-DD')
        },

        minTill() {
            if (this.period.from)
                return this.period.from

            return moment().format('Y-MM-DD')
        },

        periodStarted() {
            return moment(this.period.from).isBefore(moment())
        }
    }
}
</script>