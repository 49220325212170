<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <textarea rows="4" @keyup="change($event)" class="form-control" :class="iClass" v-model="value_"></textarea>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'TextareaField',

    created() {
        this.$nextTick(function () {
            this.iClass = this.iClass.replace('h-input', '')

        })
    },

    mixins: [ formMixin ],
}
</script>