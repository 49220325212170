<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus" :xClass="xClass">
        <template #input>
            <div class="radio pb-0" v-for="(value, index) in field.values" :key="index">
                <label class="form-check-label" :for="'field_' + idx + '_value-' + index">
                    <input type="radio" :name="name" @change="change($event)" :checked="value_ !== null && (value_ == value.id || (typeof value_ == 'object' && value_.id == value.id))" @input="typeof value_ == 'object' && null !== value_ ? value_.id = value.id : value_ = value.id" :value="value.id ? value.id : value.value" :class="{ 'is-invalid': errors }" :id="'field_' + idx + '_value-' + index" class="mr-3">
                    &nbsp;{{ value.value }}
                    <template v-if="value.with_input == 1 && null !== value_ && (value_ == value.id || (typeof value_ == 'object' && value_.id == value.id))">
                        <input type="text" v-model="with_input_value" :class="iClass" @change="change($event)">
                    </template>
                </label>
            </div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'RadioField',

    data: () => ({
        name: '',
        with_input_value: '',
	}),

    created() {
        this.$nextTick(function () {
            this.name = 'radio-' + Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 6)
            if (typeof this.value == 'string') {
                let idx = this.field.values.findIndex(option => option.value == this.value)

                if (idx !== -1)
                    this.value_ = this.field.values[idx].id
            } else if (typeof this.value == 'object' && null !== this.value &&
                typeof this.value.id !== 'undefined'
            ) {
                this.value_ = this.value.id
                if (typeof this.value.subvalue !== 'undefined')
                    this.with_input_value = this.value.subvalue
                else
                    this.with_input_value = ''
            }
        })
    },

    methods: {
        change($event) {
            let value = this.field.values.findIndex(el => el.id == this.value_)

            if (-1 !== value && this.field.values[value].with_input &&
                $event.target.tagName.toLowerCase() == 'input' &&
                $event.target.type == 'text'
            ) {
                // concat value id with extra input
                this.$emit('value-changed', this.field, {id: this.value_, extra: $event.target.value})
                return
            }

            this.$emit('value-changed', this.field, $event.target.value)

        }
    },

    mixins: [ formMixin ],
}
</script>