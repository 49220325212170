<template>
    <div v-if="null !== currentEmployee">
        <p class="text-2xl mt-10 -mb-12 p-8 bg-white rounded-xl">{{ null !== currentEmployee ? currentEmployee.name : '' }}</p>
        <!-- <FormField v-if="null != currentEmployee" :name="''" :idx="idx" :errors="errors" :bus="bus" :xClass="xClass" :field="field">
            <template #input> -->
        <span v-for="(currentField, index) in field.fields" :key="(null !== currentEmployee ? currentEmployee.id : '') + '.' + currentField.id">
            <component v-if="currentField" :is="currentField.type + 'Field'" :field="currentField" :idx="currentField.id" :value="getValue(currentField)" :errors="getError(currentField)" @value-changed="valueChanged" :coworkers="coworkers" :xClass="index > 0 ? '' : 'mt-0'"></component>
        </span>

        <!-- Buttons for desktop -->
        <div v-if="showButtons()" class="hidden md:flex md:flex-row justify-between gap-6 [@media(max-width:1225px)]:mb-[90px]">
            <a role="button" class="bg-sentiment-green border-sentiment-green border-solid font-semibold text-white text-center w-full h-12 py-2 px-4 block box-border rounded-lg border-3 mt-10 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" @click="prev">Vorige</a>
            <a role="button" class="bg-sentiment-blue border-sentiment-blue border-solid font-semibold text-white text-center w-full h-12 py-2 px-4 block box-border rounded-lg border-3 mt-10 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" @click="next(true)">Overslaan</a>
            <a role="button" class="bg-sentiment-green border-sentiment-green border-solid font-semibold text-white text-center w-full h-12 py-2 px-4 block box-border rounded-lg border-3 mt-10 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" @click="next(false)">Volgende</a>
        </div>

        <!-- Buttons for mobile -->
         <div v-if="showButtons()" class="md:hidden">
            <div class="flex flex-row justify-between gap-6">
                <a role="button" class="bg-sentiment-green border-sentiment-green border-solid font-semibold text-white text-center w-full h-12 py-2 px-4 block box-border rounded-lg border-3 mt-10 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" @click="prev">Vorige</a>
                <a role="button" class="bg-sentiment-green border-sentiment-green border-solid font-semibold text-white text-center w-full h-12 py-2 px-4 block box-border rounded-lg border-3 mt-10 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" @click="next(false)">Volgende</a>
            </div>
            <a role="button" class="bg-sentiment-blue border-sentiment-blue border-solid font-semibold text-white text-center w-full h-12 py-2 px-4 block box-border rounded-lg border-3 mt-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" @click="next(true)">Overslaan</a>
        </div>

            <!-- </template>
        </FormField> -->
    </div>
</template>

<script>
import formMixin from '../../mixins/formMixin'

export default {
    name: 'EmployeesField',

    data: () => ({
        'currentIdx': 0,
        'done': 0,
    }),

    computed: {
        currentEmployee() {
            return typeof this.coworkers !== 'undefined' &&
                typeof this.coworkers[this.currentIdx] !== 'undefined' ? this.coworkers[this.currentIdx] : null
        },

        last() {
            return this.coworkers.findIndex((el) => this.currentEmployee == null || el.id == this.currentEmployee.id) == (this.coworkers.length - 1)
        }
    },

    methods: {
        showButtons() {
            if (this.$parent.$parent.$options.name == 'form-modal')
                return false

            return true
        },

        next(skip = false) {
            if (! skip) {
                this.$emit('send-form', this.value_[this.currentEmployee.id], this.currentEmployee, Math.round(this.currentIdx + 1 / this.coworkers.length * 10000) / 100, () => {
                    this.done++
                    if (this.last) {
                        this.$router.push({name: 'sentiment-sent'})
                    }

                    if (this.currentIdx++ == this.coworkers.length)
                        this.currentIdx = 0

                    // scroll to top
                    document.querySelector('#main-element').scrollTo(0, this.$el.offsetTop - 100)
                }, () => {
                    this.$forceUpdate()
                })
                return
            }

            if (this.currentIdx++ == this.coworkers.length - 1) {
                this.$router.push({name: 'sentiment-sent'})
                this.currentIdx = 0
            }

            this.$emit('update-process', Math.round(this.currentIdx / this.coworkers.length * 10000) / 100)

            // scroll to top
            document.querySelector('#main-element').scrollTo(0, this.$el.offsetTop - 100)
        },

        prev() {
            if (this.currentIdx-- <= 0) {
                this.currentIdx = this.coworkers.length - 1
            }

            this.$emit('update-process', Math.round(this.currentIdx / this.coworkers.length * 10000) / 100)

            window.scrollTo(0, this.$el.offsetTop - 100)
        },

        getValue(field) {
            if (typeof this.value_ !== 'undefined' &&
                typeof this.currentEmployee !== 'undefined' &&
                this.currentEmployee !== null &&
                typeof this.value_[this.currentEmployee.id] !== 'undefined' &&
                typeof this.value_[this.currentEmployee.id][field.id] !== 'undefined'
            ) {
                return this.value_[this.currentEmployee.id][field.id]
            }

            return null
        },

        getError(field) {
            if (typeof this.errors !== 'undefined' &&
                typeof this.errors[field.id] !== 'undefined'
            )
                return this.errors[field.id]

            return
        },

        valueChanged(field, value) {
            this.value_[this.currentEmployee.id][field.id] = value
            this.$emit('value-changed', field, value, this.currentEmployee)
        }
    },

    mixins: [ formMixin ],
}
</script>