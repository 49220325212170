export const ROLE_ADMIN = 'beheerder'
export const ROLE_TRAINER = 'medewerker'
export const ROLE_PARENT = 'ouder'
export const ROLE_INSTANCE = 'instantiemedewerker'
export const ROLE_PARTICIPANT = 'deelnemer'
export const ROLE_COORDINATOR = 'projectcoordinator'

export default {
  get API_URL() {
    return process.env.VUE_APP_API_URL
  },

  get APP_NAME () {
    return process.env.VUE_APP_APP_NAME
  },

  get PUSHER_KEY () {
    return process.env.VUE_APP_PUSHER_KEY
  },

  get PUSHER_AUTH_URL () {
    return process.env.VUE_APP_BROADCASTING_AUTH_URL
  },

  get PUSHER_CLUSTER () {
    return process.env.VUE_APP_PUSHER_CLUSTER
  },

  get BEAMS_AUTH_URL () {
    return process.env.VUE_APP_BEAMS_AUTH_URL
  },

  get BEAMS_INSTANCE_ID () {
    return process.env.VUE_APP_BEAMS_INSTANCE_ID
  },
}