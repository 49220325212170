<template>
    <div class="text-black w-full mb-[90px] [@media(max-width:885px)]:mb-[140px]" :class="xClass">
        <h2 class="text-[25px] text-sentiment-green font-bold mb-2">Instellingen</h2>
        <div class="grid [@media(min-width:1310px)]:grid-cols-3 gap-6 mt-4 text-black box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl p-8 overscroll-none w-full bg-white mb-8 [@media(min-width:1350px)]:mb-4">
            <div v-for="setting in filteredSettings" :key="setting.id" class="w-full mb-5">
                <label class="font-bold">{{ setting.key | translateSetting | capitalize }}</label>
                <div class="flex w-full text-sm md:text-base mt-2">
                    <template v-if="setting.type == 'file'">
                        <input type="file" class="absolute -left-[9999px]" :ref="'file-' + setting.id" accept="image/*" @change="$forceUpdate()"/>
                        <div v-if="hasNoFile(setting)" class="text-right grid grid-cols-1">
                            <button @click="$refs['file-' + setting.id][0].click()" class="bg-gray-100 rounded-l-full w-[9999px] max-w-full h-input py-2 px-4 hover:bg-gray-200 ease-in-out duration-200">Bestand kiezen</button>
                        </div>
                        <div v-else class="grid grid-cols-2">
                            <button @click="$refs['file-' + setting.id][0].click()" class="bg-gray-100 rounded-l-full border-r-slate-200 border-r w-[9999px] max-w-full h-input py-2 px-4 hover:bg-gray-200 ease-in-out duration-200">Vervangen</button>
                            <button @click="removeFile(setting);setting.value = '';  $forceUpdate()" class="bg-gray-100 w-[9999px] max-w-full h-input py-2 px-4 hover:bg-gray-200 ease-in-out duration-200">Verwijderen</button>
                        </div>
                    </template>
                    <input v-else-if="setting.type == 'date'" type="date" class="bg-gray-100 rounded-l-full w-full max-w-full h-input py-2 px-4"  v-model="setting.value" />
                    <input v-else type="text" class="bg-gray-100 rounded-l-full w-full max-w-full h-input py-2 px-4"  v-model="setting.value" />

                    <div class="text-right">
                        <button class="rounded-r-full -ml-px hover:bg-gray-200 ease-in-out duration-200 px-4 h-input border-l-slate-200 border-l [@media(min-width:1310px)]:mr-4"
                            :class="buttonClicked[setting.id] ? 'bg-green-400 hover:bg-green-400' : 'bg-gray-100'"
                            @click="update(setting)">
                            <Loader :ref="`loader-${setting.id}`" alt="fas fa-check" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {

    props: ['settings', 'x-class'],

    data: () => ({
        forms: [],
        timeout: null,
        buttonClicked: {},

        filteredSettingsCache: [],
    }),

    mounted() {
        this.$nextTick(() => {
            axios.get('admin/form?noPagination').then(response => {
                this.forms = response.data
            })

            this.filteredSettingsCache = this.settings.filter(setting => {
                return setting.key.indexOf('feature_compensation') !== 0
            })
        })
    },

    computed: {
        filteredSettings() {
            if (this.filteredSettingsCache.length == 0 && typeof this.settings !== 'undefined') {
                this.filteredSettingsCache = this.settings.filter(setting => {
                    return setting.key.indexOf('feature_compensation') !== 0
                })
            }

            return this.filteredSettingsCache
        }
    },

    methods: {
        update(setting) {
            this.$set(this.buttonClicked, setting.id, true);

            setTimeout(() => {
            this.$set(this.buttonClicked, setting.id, false);
            }, 1000);

            let data = setting
            let url = `admin/setting/${setting.key}`
            if (setting.type == 'file' && typeof this.$refs['file-' + setting.id] !== 'undefined' &&
                this.$refs['file-' + setting.id][0].files.length != 0
            ) {
                data = this.buildFormData(setting)
                axios.post(url, data).then(response => {
                    if (response.status >= 200 && response.status < 300) // success
                        this.$root.getSettings()
                })
                return
            }

            axios.put(url, data).then(response => {
                if (response.status >= 200 && response.status < 300) // success
                    this.$root.getSettings()
            })
        },

        buildFormData(setting) {
            let data = new FormData

            data.append('upload', this.$refs['file-' + setting.id][0].files[0])
            data.append('_method', 'PUT')

            return data
        },

        hasNoFile(setting) {
            return (setting.value == null || setting.value == '') &&
                ! (typeof this.$refs['file-' + setting.id] !== 'undefined' &&
                this.$refs['file-' + setting.id][0].files.length != 0)
        },

        setLoading: function (idx, state = true, danger = false, ref = 'loader') {
            this.$refs[`${ref}-${idx}`][0].loading = state
            this.$refs[`${ref}-${idx}`][0].danger = danger
        },

        removeFile(setting) {
            let f = this.$refs['file-' + setting.id][0]
            if(f.value){
                try{
                    f.value = ''; //for IE11, latest Chrome/Firefox/Opera...
                }catch(err){ }
                if(f.value){ //for IE5 ~ IE10
                    var form = document.createElement('form'),
                        parentNode = f.parentNode, ref = f.nextSibling;
                    form.appendChild(f);
                    form.reset();
                    parentNode.insertBefore(f,ref);
                }
            }
        },

        searchForms(term, loading) {
            loading(true)
            if (null !== this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(this.doSearch, 250, term, loading)
        },

        doSearch(term, loading) {
            axios.get('admin/form', {params: {'filter[name]': term}}).then(response => {
                this.forms = response.data.data
                loading(false)
            });
        },

        getFormLabel(option) {
            if (typeof option == 'object')
                return option.name

            let idx = this.forms.findIndex(el => {
                return el.id == option
            })

            if (-1 == idx) {
                return null
            }

            return this.forms[idx].name
        },

        changeCheckbox(setting, event) {
            setting.value = event.target.checked ? 1 : 0
        }
    }
}
</script>

<style scoped>
.v-select {
    width: 100% !important;
}

</style>