<template>
    <LineChartGenerator
      :chart-options="cOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
      ref="chart"
    />
  </template>

  <script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )

  export default {
    name: 'LineChart',
    components: {
      LineChartGenerator
    },
    props: {
      chartId: {
        type: String,
        default: 'line-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      },
      chartData: {
        type: Object,
        default: () => {
            return {
                labels: [],
                datasets: []
            }
        }
      },
      chartOptions: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        // chartData: {
        //   labels: [],
        //   datasets: []
        // },
        cOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                callback: (value, index, ticks) => {
                  if ([0, 5].indexOf((value - Math.floor(value)) * 10) != -1)
                    return value
                }
              }
            }
          }
        }
      }
    },

    created: function () {
      this.$nextTick(() => {
        this.cOptions = Object.assign(this.cOptions, this.chartOptions)
      })

    }
  }
  </script>
