<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :noLabel="!0" :bus="bus">
        <template #input>
            <div class="form-group">
                <p v-html="valueParsed"></p>
            </div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'AdditionalField',

    mixins: [ formMixin ],

    computed: {
        valueParsed() {
            return typeof this.field.values[0] == 'object' ?
                this.field.values[0].value.replace(/\*([^*]*)\*/mg, '<strong>$1</strong>').replace(/_([^_]*)_/mg, '<i>$1</i>').replace(/# (.*)/mg, '<label class="bold">$1</label>').replace(/\n/mg, '<br>') :
                this.field.values[0].replace(/\*([^*]*)\*/mg, '<strong>$1</strong>').replace(/_([^_]*)_/mg, '<i>$1</i>').replace(/# (.*)/mg, '<label class="bold">$1</label>').replace(/\n/mg, '<br>')
        }
    }
}
</script>

<style>
label.bold {
    font-weight: bold;
    font-size: larger;
}
</style>