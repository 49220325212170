<template>
    <Admin title="Vestigingen">
        <template>
            <div class="mb-8 grid grid-cols-2 gap-8">
                <input type="text" v-model="filter.name"
                    class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray"
                    @keyup="filterCompanies" placeholder="Zoeken op naam">

                <x-button-green @click="create()" class="justify-self-end [@media(max-width:450px)]:min-w-max">Toevoegen</x-button-green>
            </div>

            <div class="md:grid [@media(max-width:1400px)]:grid-cols-2  grid-cols-4" v-if="companies && companies.length > 0">
                <template v-for="(company, index) in companies">
                    <x-list-item :key="index" :item="company" id="id" name="name" @edit="edit" @remove="remove"
                        :isRemoveAllowed="($store.user.is_admin) && typeof company.companies !== 'undefined' && company.companies.length == 0 && company.departments.length == 0" />

                    <x-list-item v-for="child in company.companies" :key="(index * 1000) + child.id + 1" :item="child"
                        id="id" name="name" @edit="edit" @remove="remove" :prefix="company.name"
                        :isRemoveAllowed="($store.user.is_admin) && child.companies.length == 0 && child.departments.length == 0" />
                </template>

                <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination>
            </div>
            <!-- <div class="container-md px-0">
        <! -- <div class="row"> -->
            <!-- <div class="col-sm">
            <button class="btn btn-primary mt-sm-n1" @click="getResults(1)">Filter</button>
          </div> - ->
          <div class="text-sm-right">
            <router-link class="btn btn-primary mt-0 mt-sm-n1" :to="{ name: 'company-edit', params: { id: 'create' }}">Organisatie toevoegen</router-link>
          </div>
        <! -- </div> -- >

        <div class="row">
          <div class="col-sm-12">
            <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 100%;">Naam</th>
                <th>Acties</th>
              </tr>
            </thead>

            <tbody v-if="companies && companies.length > 0">
              <template v-for="(company, index) in companies">
                <tr :key="index">
                  <td>
                    <a role="button" @click="edit(company.id)">
                      <i class="far fa-edit"></i>
                      &nbsp;
                      {{ company.name }}
                    </a>
                  </td>
                  <td></td>
                </tr>
                <template v-for="(child, idx) in company.companies">
                  <tr :key="(index * 1000) + child.id + 1">
                  <td>
                    <a role="button" @click="edit(child.id)">
                      <i class="far fa-edit"></i>
                      &nbsp;
                      <i class="fas fa-arrow-right"></i>
                      &nbsp;
                        {{ child.name }}
                    </a>
                  </td>
                  <td class="text-right">
                    <a role="button" class="btn btn-link" @click="remove(index, idx)" title="Verwijder deze vestiging" v-if="child.departments && child.departments.length == 0">
                      <i class="text-danger fas fa-trash"></i>
                    </a>
                  </td>
                </tr>
                </template>
              </template>
            </tbody>
            </table>
          </div>
        </div>

        <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination>
      </div> -->

        </template>
    </Admin>
</template>

<script>
import Axios from "axios";

const abortController = new AbortController()

export default {
    name: "CompanyIndex",

    data() {
        return {
            laravelData: {},
            filter: {
                name: null
            }
        };
    },

    created: function () {
        this.getResults()
    },

    computed: {
        companies: function () {
            if (this.filter.name) {
                let data = []
                for (let cidx in this.laravelData.data) {
                    let company = this.laravelData.data[cidx]

                    if (company.name.match(new RegExp('.*' + this.filter.name + '.*', 'i'))) {
                        let main = {}
                        Object.keys(company).filter((key) => ['name', 'id'].indexOf(key) >= 0).forEach(key => {
                            main[key] = company[key]
                        })
                        data.push(main)
                    }

                    for (let idx in company.companies) {
                        let settling = Object.assign({}, this.laravelData.data[cidx].companies[idx])
                        if (settling.name.match(new RegExp('.*' + this.filter.name + '.*', 'i'))) {
                            let origName = settling.name
                            settling.name = company.name + ' > ' + origName
                            data.push(settling)
                        }
                    }
                }

                return data
            }

            return this.laravelData.data;
        },
    },

    methods: {
        create() {
            this.$router.push({
                name: 'company-edit',
                params: {
                    id: 'create'
                }
            })
        },

        filterCompanies() {
            if (this.filter.name.length > 3 || this.filter.name.length == 0) {
                abortController.abort()

                this.getResults()
            }
        },

        getResults(page = 1) {
            let _this = this
            let params = new URLSearchParams([['page', page]])
            params.set('onlyMain', undefined)
            let filter = Object.keys(this.filter).map(function (key) {
                if (_this.filter[key] == '' || _this.filter[key] == null)
                    return

                if ('inactive' == key) {
                    return `inactive=1`
                }

                return params.set(`filter[${key}]`, _this.filter[key])
            })

            Axios.get(`admin/company`, {params: params}).then(response => {
                this.laravelData = response.data
                window.scrollTo(0, 0)
            })
        },

        edit: function (id) {
            this.$router.push({
                name: 'company-edit',
                params: {
                    id: id
                },
            })
        },

        remove: function (id) {
            let _self = this
            let child
            let idx = this.companies.findIndex((el) => {
                child = el.companies.findIndex((child) => child.id == id)
                return child !== -1
            })

            if (idx != -1 && confirm(`Weet je zeker dat je ${this.companies[idx].companies[child].name} wilt verwijderen?`)) {
                Axios.delete(`admin/company/${this.companies[idx].companies[child].id}`).then(() => {
                    _self.companies[idx].companies.splice(child, 1);
                })
            }
        }
    },
};
</script>