<template>
    <div class="box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl p-8 overscroll-none lg::w-3/4 bg-white text-black">
        <div class="grid [@media(min-width:1310px)]:grid-cols-3 gap-6">
          <div class="mb-4 lg:mr-4">
            <label for="name" class="font-bold text-base text-monsterrat mb-2 block">Naam *</label>
            <input type="text" id="organisation_name" class="bg-gray-100 rounded-full w-full max-w-full h-input py-2 px-4" v-model="org.name">
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="street" class="font-bold text-base text-monsterrat mb-2 block">Straat + nummer *</label>
            <div class="grid grid-cols-3 gap-4">
                <input type="text" id="street" class="bg-gray-100 rounded-full w-full h-input py-2 px-4 col-span-2" v-model="org.street">
                <input type="text" id="number" class="bg-gray-100 rounded-full w-full h-input py-2 px-4 text-center" v-model="org.number">
            </div>
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="postalcode" class="font-bold text-base text-monsterrat mb-2 block">Postcode *</label>
            <input type="text" id="postalcode" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="org.postalcode">
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="city" class="font-bold text-base text-monsterrat mb-2 block">Plaats *</label>
            <input type="text" id="city" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="org.city">
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="accountnumber" class="font-bold text-base text-monsterrat mb-2 block">IBAN</label>
            <input type="text" id="accountnumber" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="org.accountnumber">
          </div>
          <div class="mb-4 lg:mr-4">
            <label for="kvk" class="font-bold text-base text-monsterrat mb-2 block">KvK *</label>
            <input type="text" id="kvk" class="bg-gray-100 rounded-full w-full h-input max-w-full py-2 px-4" v-model="org.kvk">
          </div>
        </div>
      </div>
</template>

<script>
export default {
    props: ['org']
}
</script>