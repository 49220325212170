<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <input type="text" @keyup="change($event)" v-model="value_" class="form-control" :class="iClass">
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'TextField',

    mixins: [formMixin],
    // props: [ 'field', 'idx', 'errors', 'value' ],

    // methods: {
    //     change($event) {
    //         this.$emit('value-changed', this.field, $event.target.value)
    //     }
    // },
}
</script>