<template>
    <div class="relative mx-auto max-w-full md:max-w-4xl">
        <template>
            <!-- <h2 style="margin-bottom: 0;">{{ form.name }}</h2> -->

            <div v-if="showButtons()" class="fixed top-0 left-0 right-0 p-6">
                <div class="text-right">
                    <p class="text-black">{{ progress }}%</p>
                </div>
                <div class="bg-white rounded-full h-2.5 border border-gray-200">
                    <div class="bg-sentiment-green h-2.5 rounded-full" :style="{width: progress + '%'}"></div>
                </div>
            </div>

            <div class="mb-6 mt-8">
                <span v-for="(currentField, index) in fields" :key="currentField.id">
                    <component v-if="currentField" :is="currentField.type + 'Field'"
                        :field="currentField" :idx="currentField.id" :coworkers="coworkers"
                        :value="getValue(currentField)" :errors="getError(currentField)"
                        :xClass="'p-0'"
                        @value-changed="valueChanged"
                        @send-form="send"
                        @update-process="updateProcess"
                        @clear-errors="clearErrors"></component>
                </span>

                <!-- Buttons for desktop -->
                <div v-if="showButtons()" class="hidden md:flex justify-between font-semibold text-base mt-10 gap-8 [@media(max-width:1225px)]:mb-[90px]">
                    <x-button-blue class="w-full" @click="$router.push({name: 'sentiment-dashboard'})">Later afronden</x-button-blue>

                    <x-button-green class="w-full" @click="send">Insturen</x-button-green>
                </div>

                <!-- Buttons for mobile -->
                <div v-if="showButtons()" class="md:hidden grid grid-rows-2 justify-center font-semibold text-base gap-4 mt-10 mb-10">
                    <x-button-green @click="send">Insturen</x-button-green>

                    <x-button-blue @click="$router.push({name: 'sentiment-dashboard'})">Later afronden</x-button-blue>
                </div>


            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'form-component',

    props: {
        'form': Object,
        'formValues': [Object, Array],
        'failures': Object,
        'bus-component': Object,
        'coworkers': {
            type: Array,
            default: function () {
                if (this.$parent.$options.name == 'form-modal') {
                    return [{
                        'name': 'Piet'
                    }]
                }

                return []
            }
        },
    },

    created() {
        if (this.$parent.$options.name == 'form-modal') {
            this.coworkers
        }
    },

    mounted() {
        this.$nextTick(function () {
            if (this.formValues && 'employee' !== this.form.type)
                this.values = Object.assign({}, this.formValues)

            if ('employee' == this.form.type && typeof this.coworkers !== 'undefined') {
                for (let employee of this.coworkers) {
                    if (typeof this.formValues[employee.id] !== 'undefined') {
                        this.values[employee.id] = this.formValues[employee.id]
                        continue
                    }

                    this.values[employee.id] = {}
                }
            }

            if (this.failures)
                this.errors = this.failures
        })
    },

    data: () => ({
            errors: {},

            currentFieldIdx: 0,
            values: {},
            showAll: false,
            changed: {},
            uncomputedProcess: 0,
            submittedFormId: null,
	}),

    watch: {
        // watch formValues
        formValues: function (val) {
            // loop through set values
            for(let id in val) {
                if (null !== val[id]) {
                    let idx
                    if ((idx = this.fields.findIndex(el => el.id == id)) !== -1) {
                        if (this.fields[idx].type == 'checkbox' && Array.isArray(val[id])) {
                            val[id] = val[id].map(el => el.id)
                        }
                    }

                    this.values[id] = val[id]

                    if ((Array.isArray(val[id]) && val[id].length > 0) || null !== val[id])
                        // update currentFieldIdx
                        this.currentFieldIdx++
                }
            }
        }
    },

    computed: {
        field () {
            return this.fields.length > this.currentFieldIdx ? this.fields[this.currentFieldIdx] : false
        },

        fields () {
            if (this.form == undefined || this.form.own_fields == undefined || this.form.own_fields.length == 0)
                return []

            let fields = []

            for(let i in this.form.own_fields) {
                if ('group' == this.form.own_fields[i].type) {
                    fields.push(...this.form.own_fields[i].fields)
                    continue
                }

                fields.push(this.form.own_fields[i]);
            }

            return fields
        },

        bus() {
            return this
        },

        progress() {
            if (this.form.type == 'employee')
                return this.uncomputedProcess;

            return Math.round(( (this.currentFieldIdx) / this.fields.filter(el => ['group', 'additional'].indexOf(el.type) == -1).length ) * 10000) / 100
        },
    },

    methods: {
        updateProcess(progress) {
            this.uncomputedProcess = progress
            this.busComponent.errors = {}
        },

        showButtons() {
            if (this.$parent.$options.name == 'form-modal')
                return false

            return this.form && 'employee' != this.form.type
        },

        showResults(field) {
            console.log(field)
        },

        groupTitle (field) {
            if (this.form == undefined || this.form.ownfields == undefined || this.form.ownfields.length == 0)
                return ''

            let idx = this.form.ownfields.findIndex((el) => el.id == field.form_field_group_id)

            return this.form.ownfields[idx].name
        },

        getValue(field) {
            if ('employees' == field.type) {
                let values = {}
                for(let employee of this.coworkers) {
                    values[employee.id] = typeof this.formValues[employee.id] !== 'undefined' ? this.formValues[employee.id] : {}
                }

                return values
            }

            if (typeof this.values !== 'undefined' && field.form_field_group_id !== null && typeof this.values[field.form_field_group_id] != 'undefined') {
                return this.values[field.form_field_group_id][field.id] ?? null
            }

            if (typeof this.values !== 'undefined' && typeof this.values[field.id] != 'undefined') {
                return this.values[field.id] ?? null
            }

            if (typeof this.formValues != 'undefined' &&
                typeof this.formValues[field.id] != 'undefined'
            )
                return this.formValues[field.id]

            if ('form-modal' == this.$parent.$options.name && this.formValues) {
                let idx = this.formValues.findIndex(el => el.field_id == field.id)

                if (-1 == idx)
                    return null

                return this.formValues[idx].value
            }

            if (this.$parent !== undefined && this.$parent.$parent !== undefined && this.$parent.$parent.values !== undefined)
                return this.$parent.$parent.values[field.id] ?? null

            return null
        },

        styleButton() {
            if (this.$parent.$parent.$options.name == 'participant-evaluation')
                return 'margin-right: -2rem !important;'
        },

        toggle() {
            this.showAll = !this.showAll
        },

        valueChanged(field, value, employee = null) {
            if ('form-modal' == this.$parent.$options.name && this.formValues) {
                this.changed[field.id] = value
                return
            }

            let id = field.id ?? this.fields.findIndex(el => el === field)

            if (null !== employee) {
                if (-1 == this.coworkers.findIndex(coworker => coworker.id == employee.id))
                    return

                if (typeof this.values[employee.id] == 'undefined')
                    this.values[employee.id] = {}

                if (typeof value == 'object') {
                    if (typeof value.id !== 'undefined') {
                        this.values[employee.id][field.id] = value
                    }
                }

                this.values[employee.id][field.id] = value
                this.$emit('value-changed', field, value, employee)

                return
            }

            this.$emit('value-changed', field, value)

            if (field.form_field_group_id !== null) {
                if (typeof this.values[field.form_field_group_id] == 'undefined')
                    this.values[field.form_field_group_id] = {}

                this.values[field.form_field_group_id][field.id] = value
                return
            }

            this.values[id] = value

            this.currentFieldIdx = Object.values(this.values).filter(value => value).length
        },

        async send(values = {}, employee = null, process, callback = null, catcher = null) {
            if (null !== employee) {
                await this.$emit('send-form', values, employee, () => {
                    this.uncomputedProcess = process
                    callback()
                }, catcher)
                return
            }

            this.currentFieldIdx = 0
            if ('form-modal' == this.$parent.$options.name && this.formValues) {
                this.$emit('send-form', this.changed)
                return
            }

            this.$emit('send-form', Object.assign(this.formValues, this.values), null, null, () => {
                this.$forceUpdate()
            })
        },

        getError(field) {
            if (typeof this.failures !== 'undefined' &&
                typeof this.failures[field.id] !== 'undefined'
            )
                return this.failures[field.id]

            if ('employees' == field.type) {
                return this.failures
            }

            return
        },

        clearErrors() {
            this.errors = {}
        }
    }
}
</script>

<style scoped>
.progress-bar {
  background-color: var(--secondary);
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;

  width: 100%;

  position: relative;
  display: block;

  padding: 0 !important;
}

.progress-bar > span {
  background-color: var(--primary);
  border-radius: 2px;

  display: block;
  text-indent: -9999px;
  z-index: 1;
  position: absolute;
  top: 0;

  height: 100%;
}

.progress-bar > p {
    position: relative;
    z-index: 2;
}
</style>