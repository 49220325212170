<template>
    <Admin :title="isNew ? 'Nieuwe afdeling toevoegen' : (department != null ? `Afdeling bewerken` : 'Afdeling laden...')">
      <template>
        <div>
          <div class="items-center [@media(min-width:1450px)]:grid grid-cols-12 gap-x-16 [@media(min-width:1450px)]:gap-y-4 mt-5 md:-mt-3 [@media(min-width:885px)]:mt-10  [@media(min-width:1450px)]:mt-16 w-full">
            <div class="font-montserrat text-black font-semibold [@media(min-width:1450px)]:inline mb-2">Naam&nbsp;*</div>
            <input type="text" class="text-black [@media(min-width:1450px)]:col-span-11 bg-gray-100 py-2 px-6 shadow-sentiment rounded-full text-sm [@media(min-width:1450px)]:text-lg w-input" placeholder="naam"
              v-model="department.name">

            <div class="font-montserrat text-black font-semibold [@media(min-width:1450px)]:inline mt-6 [@media(min-width:1450px)]:mt-0 mb-2">Vestiging&nbsp;*</div>
            <v-select
              class="bg-gray-100 rounded-full shadow-sentiment [@media(min-width:1450px)]:col-span-11 w-input [@media(min-width:1450px)]:text-lg h-[36px] pl-4 pr-3 [@media(min-width:1450px)]:h-input"
              v-model="department.company_id"
              :options="filteredCompanies"
              :reduce="company => company.id"
              :getOptionLabel="getCompanyLabel"
            >
              <template #option="company">
                <span v-if="company.company_id">
                  {{ company.company.name}} >
                </span>
                {{ company.name }}
                <span v-if="! company.company_id">
                  (hoofdvestiging)
                </span>
              </template>
            </v-select>
          </div>

          <x-employees-card :employees="department.employees" />

          <div class="fixed right-6 bottom-6 w-max">
            <x-button-blue @click="$router.go(-1)" x-class="[@media(min-width:885px)]:inline-block mb-2 [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-4">Annuleren</x-button-blue>
            <x-button-green @click="submit" x-class="[@media(min-width:885px)]:inline-block [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]=mr-2">Opslaan</x-button-green>
          </div>
          
        </div>
      </template>

    </Admin>
  </template>

  <script>
  import axios from "axios"

  export default {
    name: "DepartmentEdit",

    data() {
      return {
        isNew: false,
        errors: {
          logo: undefined,
          name: undefined,
          'address.street': undefined,
          'address.number': undefined,
          'address.postcode': undefined,
          'address.city': undefined,
        },
        logo: null,
        department: {
          name: null,
          address: {
            street: null,
            number: null,
            postcode: null,
            city: null,
          },
          email: null,
          phone: null,
          pillars: [],
          projects: [],
          employees: [],
          departments: [],
          participants: [],
          newEmployees: [],
        },
        companies: [],
        projects: [],
        users: [{
            id: -1,
            name: 'Nieuwe gebruiker aanmaken' +
              '<br><small>Organisatie word eerst opgelagen</small>'
          }],
      };
    },

    created: function () {
      let _this = this;
      this.reader = new FileReader();

      this.reader.onloadend = function () {
        _this.logo = _this.reader.result;
      };

      axios.get('admin/company?noPaginate').then(response => {
        this.companies = response.data
      })

  //    axios.get('admin/pillar').then(response => _this.pillars = response.data)

      if (this.$route.params.id != "create") {
        axios.get(`admin/department/${this.$route.params.id}`).then(
          (response) => {
            this.department = response.data;
            this.logo = this.department.logo;

            if ('undefined' == typeof response.data.address || null === response.data.address) {
              this.department.address = {
                street: null,
                number: null,
                postcode: null,
                city: null,
              }
            }

            this.department.newEmployees = []
          }
        );
      } else {
        this.isNew = true;
      }
    },

    computed: {
      filteredCompanies: function () {
        let companies = [];
        for (let idx in this.companies) {
          companies.push(this.companies[idx]);

          if (this.companies[idx].companies.length) {
            for (let cidx in this.companies[idx].companies) {
              companies.push(this.companies[idx].companies[cidx]);
            }
          }
        }
        return companies;
      },
    },

    methods: {
      getCompanyLabel(company) {
        let label = '';
        if (company.company !== undefined) {
          label += company.company.name + ' > '
        }
        label += company.name
        return label
      },

      previewImg: function () {
        let file = this.$refs.file.files[0];
        if (file) {
          this.reader.readAsDataURL(this.$refs.file.files[0]);
        }
      },

      departmentCreated: function () {
        this.$notify({
          type: "success",
          title: "Afdeling aangemaakt",
        });

        this.$router.push({name: 'department-index'})
      },

      departmentUpdated: function () {
        this.$notify({
          type: "success",
          title: "Afdeling geüpdatet",
        });

        this.$router.push({name: 'department-index'})
      },

      submit: function (toIndex = true) {
        let data = Object.assign({}, this.department)

        if (data.projects && typeof data.projects[0] != 'number')
          data.projects = data.projects.map(el => el.id)

        data.employees.push(...data.newEmployees)
        if (data.employees && typeof data.employees[0] != 'number')
          data.employees = data.employees.map(el => el.id)

        if (this.isNew) {
          return axios.post("admin/department", data).then((response) => {
            if (toIndex)
              this.departmentCreated(response)
            return response
          }, this.error);
        } else {
          return axios.put(`admin/department/${this.department.id}`, data).then((response) => {
            if (toIndex)
              this.departmentUpdated(response)
            return response
          }, this.error);
        }
      },

      error: function (error) {
        if (413 == error.response.status) {
          this.errors.logo = 'Logo is te groot om te uploaden. Verklein het logo, of kies wat anders.'
          this.logo = null
        }
        else
          this.errors = Object.assign({logo: undefined}, error.response.data.errors);
      },

      findUser(search, loading) {
        loading(true)

        if ('' == search) {
          this.users = [{
            id: -1,
            name: 'Nieuwe gebruiker aanmaken' +
              '<br><small>Organisatie word eerst opgelagen</small>'
          }]

          loading(false)
          return
        }

        let params = new URLSearchParams()
        params.append('filter[name]', search)
        params.append('filter[userGroup]', 'instantiemedewerker')

        axios.get('admin//admin/user', {params: params}).then(response => {
          this.users = response.data.data
          loading(false)
        })
      },

      addUser(user) {
        if (-1 == user.id) {
          this.submit(false).then(response => {
            let department_id = response.data.id
            this.$router.push({
              name:'user-edit', params: {
                id: 'create'
              }, query: {
                department: department_id
              }
            })
          })
        }
        this.department.newEmployees.push(user)
      },

      removeUser(user) {
        let idx = this.department.employees.findIndex(el => {return el.id == user.id})

        if (-1 == idx) {
          idx = this.department.newEmployees.findIndex(el => {return el.id == user.id})

          this.department.newEmployees.splice(idx, 1)
        }

        if (confirm(`Weet je zeker dat ${user.name} niet meer bij deze organisatie hoord?`)) {
          axios.delete(`admin/department/${this.department.id}/employee/${user.id}`).then(response => {
            if (204 == response.status)
              this.department.employees.splice(idx, 1)
          })
        }
      },
    },
  };
  </script>

  <style scoped>
  table tr:not(tr:first-child) {
    border-top: solid 1px #ccc;
  }
  </style>