<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <div class="">
                <span v-for="(currentField, index) in field.fields" :key="currentField.id">
                    <component v-if="currentField" :is="currentField.type + 'Field'" :field="currentField" :idx="currentField.id" :value="getValue(currentField)" :errors="getError(currentField)" @value-changed="valueChanged" :xClass="index > 0 ? '' : 'mt-0'"></component>
                </span>
            </div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'GroupField',

    mixins: [ formMixin ],

    props: ['value', 'errors'],

    methods: {
        getValue(field) {
            let employee
            if (typeof this.$parent !== 'undefined' &&
                typeof this.$parent.$options !== 'undefined' &&
                typeof this.$parent.$options.name !== 'undefined' &&
                'EmployeeField' == this.$parent.$options.name &&
                typeof (employee = this.$parent.employee) == 'undefined' &&
                typeof this.$root.bus !== 'undefined' &&
                typeof this.$root.bus.values !== 'undefined' &&
                typeof this.$root.bus.values[employee.id][field.form_id] !== 'undefined' &&
                typeof this.$root.bus.values[employee.id][field.form_id] !== 'undefined' &&
                typeof this.$root.bus.values[employee.id][field.form_id][field.id] !== undefined
            ) {
                return this.$root.bus.values[employee.id][field.form_id][field.id]
            }

            if (typeof this.$root.bus !== 'undefined' &&
                typeof this.$root.bus.values !== 'undefined' &&
                typeof this.$root.bus.values[field.form_id] !== 'undefined' &&
                typeof this.$root.bus.values[field.form_id][field.id] !== undefined
            ) {
                return this.$root.bus.values[field.form_id][field.id]
            }
        },

        getError(field) {
            if (typeof this.errors !== 'undefined' && typeof this.errors[field.id] !== undefined) {
                return this.values[field.id]
            }
        },

        valueChanged(field, value) {
            this.$emit('value-changed', field, value)
        }
    }
}
</script>