<template>
    <Admin :title="company ? `Gebruikers van ${company.name}` : 'Gebruikers'">
        <template #content>
      <div class="container-md px-0 position-relative">
       <div class="row">
          <div class="col-sm-12 overflow-auto">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="min-width: 2.5vw; max-width: 2.5vw;">#</th>
                  <th style="min-width: 20vw; max-width: 20vw;" role="button">Naam</th>
                  <th style="min-width: 2vw; max-width: 2vw;">Acties</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in employees" :key="index">
                  <td class="id">{{ user.id }}</td>
                  <td>
                    <routerlink :role="user.mainCompany.id == $store.user.mainCompany.id ? 'button' : ''" @click="edit(user.id)" :title="user.name + ' bewerken'" :custom="user.mainCompany.id != $store.user.mainCompany.id">
                      <i class="far fa-edit" v-if="user.mainCompany.id == $store.user.mainCompany.id"></i>
                      {{ user.firstname }} {{ user.lastname }} <small v-if="user.company && company.id != user.company.id">({{ user.company.name }})</small>
                    </routerlink>
                  </td>
                  <td>
                    <!-- <a role="button" v-if="($store.user.role == settings.ROLE_ADMIN || $store.user.role == settings.ROLE_COORDINATOR) && user.id != $store.user.id &&! ($store.user.role == settings.ROLE_COORDINATOR && user.role == settings.ROLE_ADMIN)" @click="remove(index)" :title="user.name + ' verwijderen'"><i class="fas fa-trash text-danger"></i></a> -->
                  </td>
                </tr>
                <tr v-if="employees.length == 0">
                    <td colspan="3" v-if="company.company_id == null" class="alert alert-warning">Geen mederwerkers voor dit bedrijf</td>
                    <td colspan="3" v-else class="alert alert-warning">Geen mederwerkers voor deze vestiging</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination> -->
      </div>
    </template>
    </Admin>
</template>

<script>
import axios from 'axios'
export default {
    name: 'toscani-company-employees',
    data: () => ({
            company: null,
            employees: [],
	}),

    created: function () {
        let id = this.$route.params.company

        axios.get(`toscani/company/${id}/user`).then(response => response.data)
            .then((res) => {
                this.company = res.company
                this.employees = res.employees
            })
    },

    methods: {
    }
}
</script>