<template>
    <Admin title="E-mails">
        <template>
            <div class="">
              <x-list-item v-for="mail in mails" :key="mail.id" :item="mail"
                name="subject" id="id" @edit="edit"/>
            </div>
            <div class="container-md px-0">
                <div class="row">
                    <div class="col-sm-3">

                    </div>
                </div>
                
                <Pagination :data="laravelData" @pagination-change-page="getResults"></Pagination>
            </div>
        </template>
    </Admin>
</template>

<script>
import axios from "axios";

export default {
  name: "MailIndex",

  data() {
    return {
      laravelData: {
        data: []
      }
    };
  },

  created: function () {
    // let _self = this;
    this.getResults()
  },

  computed: {
    mails() {
      return this.laravelData.data
    }
  },

  methods: {
    getResults(page = 1) {
      let _this = this

      axios.get(`/toscani/mails?page=${page}`).then((response) => {
        _this.laravelData = response.data
      });
    },

    edit: function (id) {
        this.$router.push({
            name: 'toscani-mail-edit',
            params: {
                id: id
            },
        })
    },
},
};
</script>