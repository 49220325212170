<template>
    <BaseLayout :noLogo="noLogo" :xClass="mainClass">
        <slot />
    </BaseLayout>
</template>

<style>
html,body, #app {
    height:100vh;
}
body {
    background-color:black;
    background-image:none;
}
</style>

<script>
export default {
    name: 'AppLayout',

    props:{
        'noLogo': {},
        'x-class': {
            type: [String, Object],
            default: 'pb-20'
        }
    },

    methods: {
        logoff: function () {
            if (confirm('Weet je het zeker?'))
                this.$store.logoff()
        },

        back() {
            history.go(-1)
        },
    },

    computed: {
        canGoBack() {
            return history.length > 2 && history.state !== null
        },

        mainClass() {
            let mainClass = {
            },
                given = this.xClass

            if (typeof given == 'object') {
                for (let key in given) {
                    mainClass[key] = given[key]
                }
            } else {
                for (let curClass of given.split(' ')) {
                    mainClass[curClass] = true
                }
            }

            return mainClass
        }
    }
}
</script>
