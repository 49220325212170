<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <div class="checkbox" v-for="(value, index) in field.values" :key="index">
                <input type="checkbox" :name="name" @change="($event) => {value_[value.id] = $event.target.checked; change($event)}" :checked="value_[value.id].value" :value="value.id ? value.id : value.value" :class="{ 'is-invalid': errors }" :id="'value-' + index" class="mr-3">
                <label class="form-check-label" :for="'value-' + index">&nbsp;{{ value.value }}</label>
                <template v-if="value.with_input == 1 && null !== value_ && (typeof value_ == 'object' && typeof value_[value.id] == 'object' && value_[value.id].value)">
                    <input type="text" v-model="value_[value.id].extra" :class="iClass" @change="change($event)">
                </template>
            </div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'CheckboxField',

    data: () => ({
        name: '',
        value_: {},
	}),

    created() {
        this.name = 'checkbox-' + Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 6) + '[]'
        this.value_ = {}
        for(let idx in this.field.values) {
            this.value_[this.field.values[idx].id] = {
                value: false,
                extra: null,
            }
        }
    },

    mounted: function () {
        this.$nextTick(function () {
            this.value_ = {}
            for(let idx in this.field.values) {
                this.value_[this.field.values[idx].id] = {
                    value: false,
                    extra: null,
                }
            }

            if (typeof this.value == 'object' && null !== this.value) {
                Object.keys(this.value).forEach(el => {
                    let val = this.value[el]
                    this.value_[el] = val
                })
            }
        })
    },

    methods: {
        change($event) {
            let value = this.field.values.findIndex(el => el.id == this.value_)

            if (-1 !== value && this.field.values[value].with_input &&
                $event.target.tagName.toLowerCase() == 'input' &&
                $event.target.type == 'text'
            ) {
                // concat value id with extra input
                this.$emit('value-changed', this.field, {id: this.value_, extra: $event.target.value})
                return
            }

            this.$emit('value-changed', this.field, this.value_)
        },
    },

    mixins: [ formMixin ],
}

</script>