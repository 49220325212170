<template>
    <nav class="font-base text-white">
        <x-nav-item class="flex items-center py-2 pl-4 nav-item uppercase font-bold hover:cursor-default"
            icon="superadmin" v-if="$store.is_superadmin"
        >
            Superadmin
        </x-nav-item>

        <x-nav-item :url="{
            name: 'toscani-company-index'
        }" icon="fa-solid fa-building text-[38.65px]" :active="$route.name.startsWith('toscani-company')" v-if="$store.is_superadmin">
            Bedrijven
        </x-nav-item>

        <x-nav-item :url="{
            name: 'toscani-form-index'
        }" icon="fa-solid fa-clipboard text-[38.65px]" :active="$route.name.startsWith('toscani-form')" v-if="$store.is_superadmin">
            Formulieren
        </x-nav-item>

        <x-nav-item :url="{
            name: 'toscani-mail-index'
        }" icon="fa-solid fa-envelope text-[29px] h-[38.65px] py-1" :active="$route.name.startsWith('toscani-mail')" v-if="$store.is_superadmin">
            E-mail
        </x-nav-item>

        <x-nav-item v-if="$store.isLoggedIn" icon="fa-solid fa-right fa-right-from-bracket fa-rotate-180 text-red-500 text-[29px] mr-[8px] h-[38.65px] py-1" @click="$root.logoff()">Uitloggen</x-nav-item>
    </nav>
</template>

<script>
export default {

}
</script>