<template>
    <Admin :title="question ? title(question) : 'Resultaten'">
        <div class="hidden md:block" ref="checkMobile"></div>

        <template v-if="loading">
            <div class="absolute top-0 left-0 md:left-[335px] bottom-0 right-0 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512" class="animate-spin fill-sentiment-blue">
                    <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/>
                </svg>
            </div>
        </template>

        <template v-if="! isEmployee &&! loading">
            <div v-if="'object' == typeof filterable && null !== filterable && Object.keys(filterable).length > 0" class="mb-2">
                <div v-for="key of Object.keys(filterable)" :key="key">
                    Filter op {{ key }}:
                    <v-select :options="filterable[key]" class="bg-white rounded-full px-2 w-input"
                        v-model="filter[key]" :clearable="true" @input="getResults" />
                </div>
            </div>
            <!--<div class="hidden md:block text-black">
                <div class="font-semibold text-lg [@media(min-width:1251px)]:grid [@media(min-width:1251px)]:grid-cols-2 gap-10 mx-5">
                    <div class="grid grid-cols-2 mx-5">
                        <div>
                            <p>Vraag</p>
                        </div>
                        <div class="text-right">
                            <p>Aantal antwoorden</p>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 mx-5 [@media(max-width:1250px)]:hidden">
                        <div>
                            <p>Vraag</p>
                        </div>
                        <div class="text-right">
                            <p>Aantal antwoorden</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <template v-for="(question, qidx, questionIdx) in questions">
                    <div v-if="question" class="text-black font-poppins last:mb-20 bg-black/10 p-5 rounded-2xl backdrop-blur-lg shadow-sentiment first:mt-5" :class="{'mt-0': questionIdx == 0, 'mt-20': questionIdx !== 0, }" :key="'a' + qidx">
                        <div class="hidden md:block">
                            <div class="font-semibold">
                                <p :data-qid="question.id" :data-qtype="question.type">{{ question.question }}</p>
                            </div>
                        </div>

                        <div class="text-base items-center ml-auto" :key="'b' + qidx">

                            <template v-if="question.type !== 'smiley'">
                                <div class="absolute">
                                    <div v-for="(answer, aidx) in getAnswersGiven(question.id)" :key="'answer-' + aidx"
                                        class="p-2 first:mt-[2rem] mb-2 w-[250px] left-0 whitespace-nowrap text-right rounded-lg overflow-y-auto scrollbar-none"
                                       :class="aidx % 2 === 0 ? 'bg-black/10' : 'bg-black/20'"
                                       :data-qid="question.id" :data-qtype="question.type">
                                        {{ answer }}
                                    </div>
                                </div>
                            </template>


                            <template v-if="question.type == 'smiley'">
                                <div class="absolute">
                                    <div v-for="(val, index) in getAnswers(question)" :key="'smiley-' + index"
                                        class="p-2 first:mt-[2rem] mb-2 w-[250px] left-0 whitespace-nowrap text-right rounded-lg h-[2.5rem]"
                                       :class="index % 2 === 0 ? 'bg-black/10' : 'bg-black/20'"
                                       :data-qid="question.id" :data-qtype="question.type">
                                        <img :src="getSmiley(question.max, val)" class="float-right max-h-6"/>
                                    </div>
                                </div>
                            </template>


                            <div :style="answersStyle(question.id)" class="grid grid-flow-col items-center overflow-auto gap-2 scrollbar-none block ml-[258px]">

                                <template v-for="(result, period) in periods" v-if="'results' !== period && 'filters' !== period && 'periods' !== period">
                                    <p class="mx-3 whitespace-nowrap text-center" :key="'period-' + period">{{ result.period }}</p>

                                    <template v-if="'smiley' !== question.type">
                                        <template v-for="(answer, aidx) in getAnswersGiven(question.id)">
                                            <p :class="[
                                                getCount(period, qidx, answer) > 0 ? (aidx % 2 === 0 ? 'bg-black/40 text-white' : 'bg-black/50 text-white') : (aidx % 2 === 0 ? 'bg-black/10' : 'bg-black/20'),
                                                'p-2 text-center rounded-lg'
                                                ]"
                                                :key="(period * 1000) + aidx">
                                                    {{ getCount(period, qidx, answer) }}
                                            </p>
                                        </template>
                                    </template>

                                    <template v-if="'smiley' === question.type">
                                        <template v-for="(val, aidx) in getAnswers(question)">
                                            <p :class="[
                                                getCount(period, qidx, val) > 0 ? (aidx % 2 === 0 ? 'bg-black/40 text-white' : 'bg-black/50 text-white') : (aidx % 2 === 0 ? 'bg-black/10' : 'bg-black/20'),
                                                'p-2 text-center rounded-lg'
                                                ]"
                                                :key="(period * 1000) + val">
                                                    {{ getCount(period, qidx, val) }}
                                            </p>
                                            <p v-if="! period.anonimize">
                                                {{  submittedBy(period, qidx, val) }}
                                            </p>
                                        </template>
                                    </template>

                                </template>

                            </div>
                        </div>
                    </div>
                </template>
            </div> -->

            <div v-if="! onMobile() && Object.keys(periods).length > 1" class="relative -mt-4 mb-10">
                <p class="absolute right-20 mt-1">Vergelijk periodes:</p>
                <div class="absolute right-5 top-0">
                    <div :class="{'bg-green-400': isToggled, 'bg-black/20 backdrop-blur-xl': !isToggled}"
                        class="w-14 h-8 flex items-center rounded-full p-1 cursor-pointer shadow-sentiment duration-[0.4s]"
                        @click="toggleSwitch"
                    >
                        <div :class="{'translate-x-5': isToggled, 'translate-x-0': !isToggled}"
                            class="bg-white w-6 h-6 rounded-full shadow-sentiment transform transition duration-[0.4s]">
                        </div>
                    </div>
                </div>
            </div>

            <period-switcher :period1="periods[Object.keys(periods)[currentPeriodIdx1]]"
                :period2="periods[Object.keys(periods)[currentPeriodIdx2]]"
                :questions="questions" :onMobile="onMobile()" :isToggled="isToggled"
                :noPeriods="Object.keys(periods).length"
                :periods="periods"
                @next1="next1" @prev1="prev1" @next2="next2" @prev2="prev2" />

            <div class="grid gap-10 overflow-y-auto scrollbar-none p-5"
                :class="isToggled == true && Object.keys(periods).length > 1 ? 'grid grid-cols-2' : ''">
                <period-results :period="periods[Object.keys(periods)[currentPeriodIdx1]]"
                    :questions="questions"/>

                <period-results v-if="! onMobile() && isToggled == true && Object.keys(periods).length > 1" :period="periods[Object.keys(periods)[currentPeriodIdx2]]" :questions="questions" />
            </div>
        </template>

        <template v-else-if="! loading">
            <user-switcher v-if="typeof employees !== 'undefined'" :users="employees" :user="employees[currentEmployee]"
                @next="nextEmployee" @prev="prevEmployee" @selectUser="selectEmployee"/>

            <period-switcher :period1="periods[Object.keys(periods)[currentPeriodIdx1]]"
                    :questions="questions" :onMobile="onMobile()" :isToggled="isToggled"
                    :noPeriods="Object.keys(periods).length"
                    :isEmployee="true"
                    :employees="employees"
                    :employee="currentEmployee"
                    :periods="periods"
                    @selectPeriod="(idx) => currentPeriodIdx1 = idx"
                    @next1="next1" @prev1="prev1" />

            <period-results :period="periods[Object.keys(periods)[currentPeriodIdx1]]"
                    :questions="getQuestionsForEmployee(Object.keys(periods)[currentPeriodIdx1], employees[currentEmployee].id)"
                    :isEmployee="true" />
        </template>

        <div class="fixed bottom-6 right-6 w-max">
            <x-button-green @click="$router.push({ name: 'result-index'})">Terug</x-button-green>
        </div>
    </Admin>
</template>

<script>
import axios from 'axios';
import Deselect from 'vue-select/src/components/Deselect'
import LineChart from '@/components/Admin/Chart/Line'
import BarChart from '@/components/Admin/Chart/Bar'
import column from '@/functions/arrayColumn'
import unique from '@/functions/arrayUnique'
import PeriodSwitcher from '@/components/Admin/period/Switcher'
import PeriodResults from '@/components/Admin/period/Results'
import UserSwitcher from '@/components/Admin/user/Switcher'

const array_column = column.array_column
const array_unique = unique.array_unique

const  color = [10, 49, 18, 170, 125]
let originalBgImage = ''

export default {
    name: 'form-result',

    data: () => ({
        loading: false,
        isEmployee: false,
        // currentEmployee: null,
        currentEmployeeIdx: 0,
        employees: [],
        question: null,
        questions: [],
        periods: [],
        submitted_by: [],
        chartOptions: {},
        currentPeriodIdx1: 0,
        currentPeriodIdx2: 0,
        showElements1: false,
        showElements2: false,
        isToggled: false,

        filterable: {},
        filter: {},

        periodColor: {},

        cacheAnswers: {},

        components: {
            Deselect
        }
    }),

    mounted() {
        if (this.onMobile()) {
            if (this.isEmployee)
                this.chartOptions.indexAxis = 'y'

            this.chartOptions.plugins = {
                legend: {
                    position: 'top',
                    align: 'start'
                }
            }
        }
    },

    beforeDestroy: function (to, from, next) {
        //document.documentElement.style.setProperty('--background-image', originalBgImage)
    },

    created: function () {
        //originalBgImage = document.documentElement.style.getPropertyValue('--background-image')
        //document.documentElement.style.removeProperty('--background-image')

        this.getResults()
        this.currentPeriodIdx2 = this.currentPeriodIdx1 + 1
    },

    updated: function () {
        if (window.location.hash != '' && document.querySelector(window.location.hash)) {
            document.querySelector(window.location.hash).closest('.table-scroll').scrollLeft = document.querySelector(window.location.hash).offsetLeft
            window.scrollTo(0,0)
        }
    },

    components: {
        LineChart,
        BarChart,
        PeriodSwitcher,
        PeriodResults,
        UserSwitcher,
    },

    computed: {
        currentEmployee: function () {
            return Object.keys(this.employees)[this.currentEmployeeIdx]
        },

        width: function () {
            let width = window.innerWidth

            if (width > 768)
                width = width - 399

            return Math.max(width, 1200)
        },

        height() {
            return window.screen.height * 0.333333 - 40
        },

        chartData: function () {
            let labels = []
            let values = []

            if (null == this.question || this.isEmployee) return

            for (let period in this.periods) {
                if ('results' == period || 'filters' == period || 'periods' == period) continue;

                let score = this.periods[period].score
                let label = this.periods[period].period ?
                        this.periods[period].period :
                    'Periode ' + Math.floor(Math.random() * 100)

                values.push(score)
                labels.push(label)
            }

            return {
                labels: labels,
                datasets: [{
                    label: this.question,
                    data: values
                }]
            }
        },

        formattedSubmittedBy() {
            return this.submitted_by.join('<br>')
        },

        formattedSubmittedBy1() {
            let periodId = Object.keys(this.periods)[this.currentPeriodIdx1]

            return Object.values(this.periods[periodId].submitted_by).join('<br>')
        },

        formattedSubmittedBy2() {
            let periodId = Object.keys(this.periods)[this.currentPeriodIdx2]

            return Object.values(this.periods[periodId].submitted_by).join('<br>')
        }
    },

    methods: {
        selectEmployee(idx) {
            this.currentEmployeeIdx = idx
        },

        nextEmployee() {
            if (++this.currentEmployeeIdx >= Object.keys(this.employees).length)
                this.currentEmployeeIdx = 0
        },

        prevEmployee() {
            if (--this.currentEmployeeIdx < 0) {
                this.currentEmployeeIdx = Object.keys(this.employees).length - 1
            }
        },

        getScore(question, periodId) {
            // periodId should be integer
            periodId = parseInt(periodId)

            // Check if question exists, has results for period and periodResults has
            // score and count
            if (typeof this.questions[question.id] == 'undefined' ||
                typeof this.questions[question.id].results == 'undefined' ||
                typeof this.questions[question.id].results[periodId] == 'undefined' ||
                typeof this.questions[question.id].results[periodId][0] == 'undefined' ||
                typeof this.questions[question.id].results[periodId][0].score == 'undefined' ||
                typeof this.questions[question.id].results[periodId][0].count == 'undefined'
            )
                return 0

            return this.questions[question.id].results[periodId][0].score /
                this.questions[question.id].results[periodId][0].count
        },

        getMax(question) {
            if (['smiley'].indexOf(question.type) != -1) {
                return question.max
            }

            if(typeof array_column(question.values, 'score') == 'undefined')
                return null

            return Math.max(...array_column(question.values, 'score'))
        },

        toggleSwitch() {
            this.isToggled = !this.isToggled;
        },

        getAnswersGiven(questionId, employeeId = null) {
            if (null == employeeId) {
                if (typeof this.cacheAnswers[questionId] !== 'undefined')
                    return this.cacheAnswers[questionId]

                let answers = (() => {
                    let answers = []
                    if (['radio', 'select', 'checkbox'].indexOf(this.questions[questionId].type) !== -1) {
                        return this.questions[questionId].values
                    }

                    for (let period of Object.keys(this.periods)) {
                        if (typeof this.questions[questionId] == 'undefined' ||
                            typeof this.questions[questionId].results == 'undefined' ||
                            typeof this.questions[questionId].results[period] == 'undefined') continue

                        if (typeof this.questions[questionId].results[period].length == 'undefined' ||
                            this.questions[questionId].results[period].length == 0)
                            continue

                        answers.push(...array_column(this.questions[questionId].results[period], 'answer'))
                    }

                    if ('number' == this.questions[questionId].type || 'smiley' == this.questions[questionId].type) {
                        // Convert answers to numbers (if they're not already) and sort them
                        return answers.map(a => Number(a)).sort((a, b) => a - b);
                    }

                    return answers
                })()


                return this.cacheAnswers[questionId] = array_unique(answers);
            }

            if (typeof this.cacheAnswers[employeeId] !== 'undefined' &&
                typeof this.cacheAnswers[employeeId][questionId] !== 'undefined'
            ) {
                return this.cacheAnswers[employeeId][questionId]
            }

            if (typeof this.results == 'undefined')
                return []

            let answers = (() => {
                let givenAnswers = {}
                for (let period in this.periods) {
                    if (typeof this.employees[employeeId].results[period] == 'undefined') {
                        continue
                    }
                    let resultsEmployee = this.employees[employeeId].results[period]
                    if (typeof resultsEmployee[questionId] == 'undefined')
                        continue

                    for (let idx in resultsEmployee[questionId]) {
                        let answer = resultsEmployee[questionId][idx]
                        if (null == answer || typeof answer.answer == 'undefined')
                            continue;

                        givenAnswers[answer.answer] = {
                            count: answer.count,
                            score: answer.score
                        }
                    }
                }

                return givenAnswers
            })()

            if (typeof this.cacheAnswers[employeeId] == 'undefined') {
                this.cacheAnswers[employeeId] = {}
            }

            return this.cacheAnswers[employeeId][questionId] = answers
        },

        onMobile() {
            return typeof this.$refs.checkMobile !== 'undefined' && ! this.$refs.checkMobile.checkVisibility()
        },

        getAnswers(question) {
            if ('smiley' == question.type) {
                return [...Array(question.max).keys()].map(el => el + 1)
            }
        },

        getSmiley(max, num) {
            let basePath = window.location.protocol + '//' + window.location.host + '/assets/img/smiles/'
            if (max == 10)
                return basePath + '10/' + num  + '.png'

            return basePath + '5/' + num + '.png'
        },

        prev1() {
            if (--this.currentPeriodIdx1 < 0)
                this.currentPeriodIdx1 = Object.keys(this.periods).length - 1;
        },

        next1() {
            if (++this.currentPeriodIdx1 > (Object.keys(this.periods).length - 1))
                this.currentPeriodIdx1 = 0;

            if (this.currentPeriodIdx1 === this.currentPeriodIdx2) {
                this.currentPeriodIdx2 = (this.currentPeriodIdx2 + 1) % Object.keys(this.periods).length;
            }
        },

        prev2() {
            if (--this.currentPeriodIdx2 < 0)
                this.currentPeriodIdx2 = Object.keys(this.periods).length - 1;

            if (this.currentPeriodIdx2 === this.currentPeriodIdx1) {
                this.currentPeriodIdx1 = this.currentPeriodIdx1 - 1;
                if (this.currentPeriodIdx1 < 0) {
                    this.currentPeriodIdx1 = Object.keys(this.periods).length - 1;
                }
            }
        },

        next2() {
            if (++this.currentPeriodIdx2 > (Object.keys(this.periods).length - 1))
                this.currentPeriodIdx2 = 0;
        },

        hideElements1(e) {
            if (e.target == document.querySelector('#showElementsBtn') ||
                e.target == document.querySelector('#showElementsBtn2')
            )
                return false

            this.showElements1 = false
        },

        toggleElements1() {
            this.showElements1 = !this.showElements1
        },

        hideElements2(e) {
            if (e.target == document.querySelector('#showElementsBtn') ||
                e.target == document.querySelector('#showElementsBtn2')
            )
                return false

            this.showElements2 = false
        },

        toggleElements2() {
            this.showElements2 = !this.showElements2
        },


        async getResults() {
            this.loading = true;
            let id = this.$route.params.id

            let config = {}

            if (Object.keys(this.filter).length > 0) {
                let filters = {}
                Object.keys(this.filter).forEach(key => {
                    filters['filter[' + key + ']'] = this.filter[key]
                })

                config.params = filters
            }

            let question = await axios.get(`admin/form/${id}/result`, config).then(response => response.data)

            /* eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }] */
            let {form: _rq, questions: _q, submitted_by: _sub, isEmployee: _iE, employees: _em, filters: _fs, periods: _p, ...rest} = question

            this.question = _rq
            this.questions = _q
            this.submitted_by = _sub
            this.isEmployee = _iE
            this.employees = _em
            this.filterable = _fs
            this.periods = _p
            this.results = rest

            this.loading = false
        },

        answersStyle(questionId, employeeId) {
            if (typeof this.results == 'undefined' || typeof questionId == 'undefined') {
                return {}
            }

            let answers = this.getAnswersGiven(questionId, employeeId)

            if (typeof answers == 'undefined' || null === answers)
                return {}

            let rows = Object.keys(answers).length +1
            let maxWidth = '300px'

            if (this.onMobile())
                maxWidth = '50vw'

            if ('smiley' == this.questions[questionId].type) {
                rows = [...Array(this.questions[questionId].max).keys()].length + 1
            }

            let periods = Object.keys(this.periods).length

            return {
                'grid-template-rows': `repeat(${rows}, auto)`,
                'grid-template-columns': `repeat(${periods}, 1fr)`
            }
        },

        submittedBy(period, idx, answer) {
            if (typeof this.periods[period] == 'undefined' &&
                this.periods[period].anonimize ||
                typeof this.questions[idx] == 'undefined'
            ) {
                return ''
            }

            if (typeof answer == 'object' && typeof answer.answer !== 'undefined') {
                answer = answer.answer
            }

            let adx = -1

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period].findIndex !== 'undefined'
            ) {
                adx = this.questions[idx].results[period].findIndex(el => {
                    if (typeof el.answer != 'undefined')
                        return el.answer == answer

                    return el.answer == answer
                })

                if (adx !== -1 && typeof this.questions[idx].results[period][adx] !== 'undefined' &&
                        typeof this.questions[idx].results[period][adx]['submitted_by'] !== 'undefined') {
                    return this.questions[idx].results[period][adx]['submitted_by'].join('<br>')
                }
            }

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer]['submitted_by'] !== 'undefined'
            )
                return this.questions[idx].results[period][answer]['submitted_by'].join('<br>')

            return ''
        },

        showUsers(event) {
            let users = event.target.nextSibling
            users.style.top = (event.target.offsetTop + 20) + 'px'
            users.classList.remove('hidden')

        },

        hideUsers(event) {
            event.target.nextSibling.classList.add('hidden')
        },

        getCount(period, idx, answer, withSmileyCheck = true) {
            if (typeof this.questions[idx] == 'undefined') return 0;

            if (typeof answer == 'object' && typeof answer.answer !== 'undefined') {
                answer = answer.answer
            }

            if (withSmileyCheck && this.questions[idx].type == 'smiley') {
                let aidx

                // adx is value
                if (typeof this.questions[idx].results[period] !== 'undefined' &&
                    (aidx = this.questions[idx].results[period].findIndex(el => el.answer == answer)) != -1
                ) {
                    return this.questions[idx].results[period][aidx].count
                }

                return 0
            }

            let adx = -1

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period].findIndex !== 'undefined'
            ) {
                adx = this.questions[idx].results[period].findIndex(el => {
                    if (typeof el.answer != 'undefined')
                        return el.answer == answer

                    return el.answer == answer
                })

                if (adx !== -1 && typeof this.questions[idx].results[period][adx] !== 'undefined' &&
                        typeof this.questions[idx].results[period][adx]['count'] !== 'undefined') {
                    return this.questions[idx].results[period][adx]['count']
                }
            }

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer]['count'] !== 'undefined'
            )
                return this.questions[idx].results[period][answer]['count']

            return 0
        },

        getCountForEmployee(period, eidx, idx, adx, withSmileyCheck = true) {
            if (typeof this.employees[eidx] == 'undefined' ||
                typeof this.employees[eidx].results[period] == 'undefined'
            ) {
                return 0
            }

            if (withSmileyCheck && this.questions[idx].type == 'smiley') {

                let aidx

                // adx is value
                if (typeof this.employees[eidx].results[period][idx] !== 'undefined' &&
                    (aidx = this.employees[eidx].results[period][idx].findIndex(el => el.answer == (adx + 1))) != -1
                ) {
                    return this.employees[eidx].results[period][idx][aidx].count
                }

                return 0
            }


            if (typeof this.employees[eidx].results[period][idx] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx][adx] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx][adx]['count'] !== 'undefined'
            ) {
                return this.employees[eidx].results[period][idx][adx]['count']
            }

            if (typeof this.employees[eidx].results[period] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx] !== 'undefined'
            ) {
                let answer = this.questions[idx].values[adx]
                let answerIdx = -1
                if (typeof answer !== 'undefined') {
                    for(let aidx in this.employees[eidx].results[period][idx]) {
                        if (this.employees[eidx].results[period][idx][aidx].answer == answer) {
                            answerIdx = aidx
                            break
                        }
                    }
                }

                if (answerIdx == -1) {
                    for(let aidx in this.employees[eidx].results[period][idx]) {
                        if (null !== this.employees[eidx].results[period][idx][aidx] && this.employees[eidx].results[period][idx][aidx].answer == adx) {
                            answerIdx = aidx
                            break
                        }
                    }
                }

                if (answerIdx !== -1 && typeof this.employees[eidx].results[period][idx][answerIdx] !== 'undefined' &&
                        typeof this.employees[eidx].results[period][idx][answerIdx]['count'] !== 'undefined') {
                    return this.employees[eidx].results[period][idx][answerIdx]['count']
                }
            }

            return 0
        },

        title(question) {
            return  `Resultaten<span class="hidden md:inline"> onderzoeksvraag</span>: <br class="md:hidden">${question}`
        },

        getChartDataQuestion(question, employeeId = null) {
            if (['checkbox', 'select', 'radio'].indexOf(question.type) === -1) {
                console.error(question, 'is not a checkbox-, select- or radiofield!')
                return {}
            }

            let answerLabels = (() => {
                let answers = []

                for(let answer of this.questions[question.id].values) {
                    if (answer.with_input) continue // will get answer trough answersGiven

                    answers.push(answer.value)
                }

                answers.push(...Object.keys(this.getAnswersGiven(question.id, employeeId)))
                return answers.filter((value, index, array) => {
                    return array.indexOf(value) === index
                })
            })()

            let data = {
                labels: answerLabels,
                datasets: [],
                options: {},
            }

            if (typeof this.employees[employeeId] !== 'undefined') {
                for (let periodId in this.employees[employeeId].results) {
                    if (
                        null === employeeId ||
                        typeof this.employees[employeeId].results == 'undefined' ||
                        typeof this.employees[employeeId].results[periodId] == 'undefined'
                    ) {
                        continue;
                    }

                    let bgColor
                    if (typeof this.periodColor[periodId] == 'undefined') {
                        this.periodColor[periodId] = 'rgba(' +
                        color[Math.floor(Math.random() * color.length)] + ', ' +
                        color[Math.floor(Math.random() * color.length)] + ', ' +
                        color[Math.floor(Math.random() * color.length)] + ', 0.6)'
                    }

                    bgColor = this.periodColor[periodId]

                    let datasetData = Array(answerLabels.length).fill(0)

                    if (typeof this.employees[employeeId].results[periodId] !== 'undefined') {
                        for (let i in this.employees[employeeId].results[periodId][question.id]) {
                            let answer = this.employees[employeeId].results[periodId][question.id][i]
                            let idx = answerLabels.findIndex(el => el == answer.answer)
                            if (idx !== -1)
                            datasetData[idx] += answer.count
                    }
                }

                data['datasets'].push({
                    label: this.periods[periodId].period,
                    data: datasetData,
                    backgroundColor: bgColor
                })
            }
        }

            if (this.onMobile()) {
                data.options.indexAxis = 'y'
            }

            return data
        },

        getQuestionsForEmployee(periodId, employeeId) {
            let questions = {}

            for (let qid in this.employees[employeeId].results[periodId]) {
                questions[qid] = Object.assign({
                    results: {}
                }, this.questions[qid])
                questions[qid].question = questions[qid].name
                questions[qid].results[periodId] = this.employees[employeeId].results[periodId][qid]
            }

            if (Object.keys(questions).length == 0) {
                questions = Object.assign({}, this.questions)

                for (let question in questions) {
                    questions[question].question = questions[question].name
                    questions[question].results = {}
                    questions[question].results[periodId] = {}
                }

                return questions
            }

            return questions
        }
    }
}
</script>

<style scoped>
div.table-scroll-outer {
    position:relative;
}
div.table-scroll {
    overflow-x: scroll;
    overflow-y: visible;
    /* width: 200px; */
    margin-left: 200px;
}
.headcol {
    position:absolute;

    width: 200px;
    margin-left: -200px;
}

.table > tbody > tr {
    position: initial;
}
</style>