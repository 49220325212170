<template>
    <form action="" class="px-8 py-8 mx-auto md:w-login" :class="xClass">
        <div class="mb-4 text-xl text-poppins">Wachtwoord vergeten</div>
        <div class="mb-4">
            <label class="font-bold text-base flex text-montserrat text-sentiment-blue"> E-mailadres</label>
            <input type="text" id=" E-mailadres" name="E-mailadres" placeholder="E-mailadres"
                v-model="email"
                class="bg-gray-100 rounded-full w-full h-input py-2 px-4 mt-2">
        </div>

        <x-button-green title="Wachtwoord opvragen" class="mx-auto mt-8" @click="request">Opvragen</x-button-green>
        <x-button-blue @click="$router.push({name: 'Login'})" x-class="mx-auto mt-4">Annuleren</x-button-blue>

        <slot />
    </form>
</template>

<script>
export default {
    name: 'RequestPassword',

    props: ['x-class'],

    data: () => ({
        'email': null,
	}),

    methods: {
        request: function () {
            this.$emit('request', Object.assign({}, this.$data))
        }
    }
}
</script>