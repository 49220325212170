<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <input type="range"
                class="block w-full"
                @change="localChange($event)"
                :min="min"
                :max="max"
                ref="input"
                list="minmax"
                v-model="value_" />

            <datalist id="minmax">
                <option v-for="value in field.values" :key="value.id" :value="value.id"></option>
            </datalist>

            <div class="absolute" :style="getStyle()">
                {{  getValue() }}
            </div>

            <div class="hidden md:block" ref="checkMobile"></div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin';

export default {
    mixins: [
        formMixin
    ],

    mounted: function () {
        console.log('mounted')
        this.$nextTick(() => {
            console.log('nextTick mounted')
            console.log(! isNaN(parseInt(this.value)), parseInt(this.value))
            if (isNaN(parseInt(this.value))) {
                let valueIdx = this.field.values.findIndex(el => isNaN(parseInt(this.value)) ? el.value == this.value : el.id == this.value)

                console.log(valueIdx, this.field.values[valueIdx].id)
                this.value_ = valueIdx !== -1 ? this.field.values[valueIdx].id : null

                this.$refs.input.value = this.value_
                this.$refs.input.dispatchEvent(new InputEvent('input', {data: null}))

                console.log(this.value_)
            }
        })

        this.localChange()
    },

    computed: {
        min () {
            if (typeof this.field.values !== 'undefined' && typeof this.field.values[0] !== 'undefined')
                return parseInt(this.field.values[0].id)

            if (typeof this.field.min !== 'undefined')
                return this.field.min

            return 0
        },

        max () {
            if (typeof this.field.values !== 'undefined' && typeof this.field.values[1] !== 'undefined')
                return parseInt(this.field.values[this.field.values.length - 1].id)

            if (typeof this.field.max !== 'undefined')
                return this.field.max

            return 10
        }
    },

    watch: {
        value_ (newVal, oldVal) {
            // console.log(newVal, new Error)
        }
    },

    methods: {
        localChange(event = null) {
            const rangeInput = this.$refs.input;
            function updateTrackColor() {
                const percentage = (rangeInput.value - rangeInput.min) / (rangeInput.max - rangeInput.min) * 100;
                const colorBeforeThumb = 'var(--primary)';
                const colorAfterThumb = '#ccc';
                const newBackgroundStyle = `linear-gradient(90deg, ${colorBeforeThumb} ${percentage}%, ${colorAfterThumb} ${percentage}%)`;
                rangeInput.style.background = newBackgroundStyle;
            }
            // Initial call to set the track color based on the default value.
            updateTrackColor();
            // Event listener to update the track color whenever the range value changes.
            rangeInput.addEventListener('input', updateTrackColor);

            if (null !== event)
                this.change(event)
        },

        getValue() {
            let valueIdx = this.field.values.findIndex(el => isNaN(this.value_) ? el.value == this.value_ : el.id == this.value_)

            return valueIdx != -1 ? this.field.values[valueIdx].value : 'N.A.'
        },

        getStyle() {
            let valueIdx = this.field.values.findIndex(el => isNaN(parseInt(this.value_)) ? el.value == this.value_ : el.id == this.value_)

            var left = (valueIdx / this.field.values.length) * 100

            var style = 'left: ' + Math.max(9, Math.min (left, this.onMobile() ? 65 : 70)) + '%;',
            //     'text-align': left >= 80 ? 'right': false
            // }
            style = style + (left >= 80 ? ' text-align: right;' : '')



            return style // 'left: ' + (valueIdx / this.field.values.length) * 100 + '%;'
        },

        onMobile() {
            return typeof this.$refs.checkMobile !== 'undefined' && ! this.$refs.checkMobile.checkVisibility()
        },
    }
}
</script>

<style scoped>
datalist {
    display: none;
    /* flex-direction: row;
    justify-content: space-between; */
}
input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    margin: 18px 0;
    width: 100%;
    background: transparent;
    border-radius: 9999px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    cursor: pointer;
    border-radius: 9999px;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 25px;
    cursor: pointer;
    background: var(--primary);
    border-radius: 9999px;
}
input[type=range]::-moz-range-progress {
    background: white;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 25px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=“range”]::-ms-fill-lower {
    background-color: white;
  }
input[type=range]::-webkit-slider-thumb {
    border: 3px solid white;
    height: 37px;
    width: 37px;
    border-radius: 25px;
    background: var(--secondary);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
}
input[type=range]::-moz-range-thumb {
    border: 3px solid var(--secondary);
    height: 37px;
    width: 37px;
    border-radius: 25px;
    background: var(--secondary);
    cursor: pointer;
}
input[type=range]::-ms-thumb {
    border: 3px solid var(--secondary);
    height: 37px;
    width: 37px;
    border-radius: 25px;
    background: var(--secondary);
    cursor: pointer;
}
</style>