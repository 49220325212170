<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <div class="form-group is-empty is-fileinput">
                <input type="file" @change="change($event)" ref="fileInput" class="absolute left-[-999px]" :class="{ 'is-invalid': errors }">

                <template v-if="value_.length > 0">
                    <span class="italic">Je hebt al een bestand geupload. Je kan het hier nog vervangen.</span>
                </template>
                <div class="input-group">
                    <input type="text" readonly="" class="w-full" placeholder="Selecteer een bestand" v-model="filename" @click="$refs.fileInput.click()">
                </div>
            </div>
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Axios from 'axios'

export default {
    name: 'FileField',

    mixins: [ formMixin ],

    data: () => ({
            file: null,
            filename: null,
	}),

    methods: {
        change() {
            let fileData = new FormData()
            fileData.set('file', this.$refs.fileInput.files[0])
            if (typeof this.$refs.fileInput.files[0] == 'undefined') {
                this.filename = ''
                Axios.delete('/upload', {
                    params: {
                        file: this.value
                    }
                })
                return
            }

            this.filename = this.$refs.fileInput.files[0].name

            Axios.post('/upload', fileData).then(response => {
                this.file = response.data
                this.$emit('value-changed', this.field, this.file)
            })
        }
    },
}
</script>