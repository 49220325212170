<template>
  <PlainAdmin :withMenu="false">
    <x-request-password ref="form" x-class="bg-white rounded-xl m-auto md:mt-64" @request="request" />
  </PlainAdmin>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'PasswordReset',
  data: () => ({
      email: null,
      appName: null,
      error: false,
      message: null,
	}),

  watch: {
    email: function () {
      if (this.error) {
        this.error = false
      }
    },
  },
  methods: {
    request: function (data) {
      Axios.post('/auth/password/reset', { email: data.email })
        .then((response) => {
          if (typeof response.data.message == 'string') {
            let msg = response.data.message
            msg = msg.replace(/\(([^)]*)\)\[([^\]]*)\]/, '<a href="mailto:$2" class="font-weight-bold text-white">$1</a>')
            this.$notify(msg)
          }

          this.$router.push({name: 'Login'})
        })
        .catch(() => {
          this.error = true
        })
    }
  }
};
</script>

<style scoped>
.login-page {
  padding-top: 20vh
}
.full-page > .content {
    min-height: calc(80vh - 70px);
}
.full-page > .content, .full-page > .footer {
    position: relative;
    z-index: 4;
}
</style>