<template>
  <Admin>
    <div class="hidden md:block" ref="checkMobile"></div>

    <template>
      <div class="font-semibold text-[42px] max-w-full mx-auto md:mx-0 text-center md:text-left text-montserrat mt-20  md:mt-4">
          {{ greeting }}<br class="md:hidden" /> {{ typeof $store.user != 'undefined' ? $store.user.firstname : 'N.A.' }}
      </div>

      <div class="grid grid-cols-1 [@media(min-width:1501px)]:grid-cols-2 gap-6 mt-2">

          <div class="relative bg-black/10 rounded-2xl backdrop-blur-lg shadow-sentiment [@media(max-width:1500px)]:h-[350px] h-[35vh] [@media(max-width:1500px)]:mb-5">
            <div class="absolute bg-sentiment-blue p-1 rounded-t-2xl w-full text-center font-semibold text-white shadow-sentiment">Tips</div>
            <i class="absolute fa-solid fa-angle-left text-black/10 [@media(max-width:450px)]:text-[70px] text-[100px] cursor-pointer hover:text-black/20 [@media(max-width:450px)]:hover:text-[80px] hover:text-[110px] ease-in-out duration-150" style="top: 55%; left: 8%; transform: translate(-50%, -50%);"
               @click="prevContent">
            </i>
            <div class="absolute bg-black/10 rounded-2xl shadow-sentiment h-[75%] w-[70%] text-left font-semibold text-black p-3" style="top: 55%; left: 50%; transform: translate(-50%, -50%);">
              {{ currentTitle }}
              <br><br>
              <span class="font-normal">
                {{ currentContent }}
              </span>
            </div>
            <i class="absolute fa-solid fa-angle-right text-black/10 [@media(max-width:450px)]:text-[70px] text-[100px] cursor-pointer hover:text-black/20 [@media(max-width:450px)]:hover:text-[80px] hover:text-[110px] ease-in-out duration-150" style="top: 55%; left: 92%; transform: translate(-50%, -50%);"
               @click="nextContent">
            </i>
          </div>

          <div class="relative bg-black/10 rounded-2xl backdrop-blur-lg shadow-sentiment [@media(max-width:1500px)]:h-[665px] [@media(min-width:951px)]:h-[35vh] [@media(max-width:1500px)]:mb-5">
            <div class="absolute bg-sentiment-blue p-1 rounded-t-2xl w-full text-center font-semibold text-white shadow-sentiment">Snelle acties</div>
              <div class="grid grid-cols-1">

                <div class="absolute grid [@media(max-width:1500px)]:w-[90%] w-[95%] [@media(max-width:950px)]:top-[26%] top-[37%]" style="left: 50%; transform: translate(-50%, -50%);">
                  <div class="relative bg-black/10 rounded-b-2xl backdrop-blur-ms shadow-sentiment [@media(max-width:950px)]:h-[150px] h-[10vh] text-center font-semibold text-white [@media(max-width:950px)]:mb-8">
                    <div class="bg-sentiment-blue p-[5px] w-full rounded-t-2xl text-sm shadow-sentiment -mt-7">
                      Gebruikers
                    </div>
                    <div class="grid grid-cols-2">
                      <button @click="createUser()" class="absolute min-w-0 w-[45%] h-3/5 text-[30px] ml-auto block box-border rounded-lg bg-sentiment-green px-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" style="top: 50%; left: 25%; transform: translate(-50%, -50%);">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                      <button @click="importUser()" class="absolute min-w-0 w-[45%] h-3/5 text-[30px] ml-auto block box-border rounded-lg bg-sentiment-green px-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" style="top: 50%; left: 75%; transform: translate(-50%, -50%);">
                        <i class="fa-solid fa-arrow-up-from-bracket"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="absolute grid grid-cols-2 [@media(min-width:951px)]:grid-cols-4 gap-4 [@media(max-width:1500px)]:w-[90%] w-[95%] [@media(max-width:950px)]:top-[70%] top-[80%]" style="left: 50%; transform: translate(-50%, -50%);">
                  <div class="relative bg-black/10 rounded-b-2xl backdrop-blur-ms shadow-sentiment [@media(max-width:950px)]:h-[150px] h-[10vh] text-center font-semibold text-white [@media(max-width:950px)]:mb-8">
                    <div class="bg-sentiment-blue p-[5px] w-full rounded-t-2xl text-sm shadow-sentiment -mt-7">
                      Vestigingen
                    </div>
                    <button @click="createCompany()" class="absolute min-w-0 w-3/4 h-3/5 text-[30px] ml-auto block box-border rounded-lg bg-sentiment-green px-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>

                  <div class="relative bg-black/10 rounded-b-2xl backdrop-blur-ms shadow-sentiment [@media(max-width:950px)]:h-[150px] h-[10vh] text-center font-semibold text-white">
                    <div class="bg-sentiment-blue p-[5px] w-full rounded-t-2xl text-sm shadow-sentiment -mt-7">
                      Afdelingen
                    </div>
                    <button @click="createDepartment()" class="absolute min-w-0 w-3/4 h-3/5 text-[30px] ml-auto block box-border rounded-lg bg-sentiment-green px-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>

                  <div class="relative bg-black/10 rounded-b-2xl backdrop-blur-ms shadow-sentiment [@media(max-width:950px)]:h-[150px] h-[10vh] text-center font-semibold text-white">
                    <div class="bg-sentiment-blue p-[5px] w-full rounded-t-2xl text-sm shadow-sentiment -mt-7">
                      Formulieren
                    </div>
                    <button @click="createForm()" class="absolute min-w-0 w-3/4 h-3/5 text-[30px] ml-auto block box-border rounded-lg bg-sentiment-green px-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>

                  <div class="relative bg-black/10 rounded-b-2xl backdrop-blur-ms shadow-sentiment [@media(max-width:950px)]:h-[150px] h-[10vh] text-center font-semibold text-white">
                    <div class="bg-sentiment-blue p-[5px] w-full rounded-t-2xl text-sm shadow-sentiment -mt-7">
                      Periodes
                    </div>
                    <button @click="createPeriod()" class="absolute min-w-0 w-3/4 h-3/5 text-[30px] ml-auto block box-border rounded-lg bg-sentiment-green px-4 hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
          </div>

          <div class="relative [@media(min-width:1501px)]:col-span-2 bg-black/10 rounded-2xl backdrop-blur-lg shadow-sentiment h-[85vh] md:h-[35vh] [@media(max-width:1500px)]:mb-5">
            <div class="absolute bg-sentiment-blue p-1 rounded-t-2xl w-full text-center font-semibold text-white shadow-sentiment">Resultaten</div>
            <template v-if="periods.length > 0">
              <div class="flex justify-center align-center">
                <a role="button" @click="prevPeriod" class="mt-10 text-sentiment-green inline-block mr-2">
                  <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="translate-y-1">
                      <path d="M10 17L2 9.5L10 2" stroke="currentColor" stroke-width="4" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </svg>
                </a>

                <p class="mt-10 text-center font-semibold inline-block">{{ periods[currentPeriodIdx].name }}</p>

                <a role="button" @click="nextPeriod" class="mt-10 text-sentiment-green inline-block ml-2">
                  <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="translate-y-1">
                      <path d="M2 2L10 9.5L2 17" stroke="currentColor" stroke-width="4" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </svg>
                </a>
              </div>

            </template>

            <div class="md:grid grid-cols-5 h-3/5 md:h-[85%] p-5">
              <div class="relative col-span-2 h-3/5 md:h-[85%]">
                <div class="absolute -mt-3 md:-mt-5 z-10 top-[42%] md:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Doughnut :chartData="periodData" :width="onMobile() ? 155 : 235" :height="onMobile() ? 155 : 235" :chart-options="chartOptions"/>
                </div>
                <div class="absolute top-[42%] md:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Doughnut :chartData="periodData2" :width="onMobile() ? 210 : 305" :height="onMobile() ? 210 : 305" :chart-options="chartOptions"/>
                </div>
                <p class="absolute text-[12px] md:text-[24px] text-black text-center top-[42%] md:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  {{ percentageFinished.toFixed(2) }}%<br>
                  Voltooid
                </p>
              </div>

              <div class="md:relative col-span-3">
                <div class="md:absolute w-full h-full [@media(max-width:450px)]:max-h-[330px] max-h-[43vh]  overflow-y-auto">
                  <div v-if="(typeof periods[currentPeriodIdx] !== 'undefined')" class="grid md:grid-cols-3 gap-4">
                    <div v-for="employee in periods[currentPeriodIdx].active_employees" :key="employee.id"
                      class="flex w-full"
                    >
                      <span class="inline-block" :title="progressEmployeeTitle(employee)"><i :class="progressEmployee(employee)"></i></span>
                      <span class="inline-block ml-2">{{ employee.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div class="flex gap-4 fixed place-items-end bottom-6 md:h-10 mt-6 flex-col-reverse text-right md:flex-row-reverse mx-6 right-0">
          <x-button-green x-class="w-max mb-0 px-4 md:ml-0" @click="$router.push({
              name: 'user-profile'
          })">Wachtwoord&nbsp;aanpassen</x-button-green>
      </div>
    </template>
  </Admin>
</template>

<script>
import moment from 'moment'
import Doughnut from '@/components/Admin/Chart/Doughnut'
import PeriodSwitcher from '@/components/Admin/period/Switcher'
import axios from 'axios'

export default {
  name: 'Home',

  data: function () {
    const contentsLength = 3

    return {
      titles: [
        "Gebruikers",
        "Vestigingen",
        "Afdelingen",
        "Formulieren",
        "Periodes",
        "Resultaten"
      ],
      contents: [
        "Er zijn twee manieren beschikbaar voor het toevoegen van gebruikers: handmatige invoer of het uploaden van een CSV-bestand.",
        "In dit overzicht worden de afdelingen weergegeven die deel uitmaken van de organisatie. Tevens biedt dit scherm de optie om de vormgeving van de applicatie naar wens aan te passen.",
        "Hier vind je een compleet overzicht van de medewerkers die tot de geselecteerde afdeling behoren. Je hebt de mogelijkheid om via dit scherm direct gebruikers te bewerken.",
        "Dit overzicht presenteert alle beschikbare formulieren, waarbij uw persoonlijke formulieren onderscheiden worden van de standaardformulieren.",
        "Je treft hier een compleet overzicht van alle periodes. Binnen dit scherm heb je de mogelijkheid om nieuwe periodes toe te voegen en bestaande periodes te wijzigen.",
        "In dit gedeelte vind je de resultaten van de vragenlijsten met aangemaakte periodes. Tijdens de evaluatie van deze resultaten kun je vergelijkingen maken met andere periodes, wat inzicht biedt in de ontwikkelingen binnen de organisatie of onder collega's.",
      ],
      currentIndex: Math.floor(Math.random() * contentsLength),
      periods: [],
      currentPeriodIdx: 0,
    }
  },

  created() {
    axios.get('/admin/dashboard').then(response => {
      this.periods = response.data
    })
  },

  mounted() {
    this.$forceUpdate()
  },

  components: {
    Doughnut,
    PeriodSwitcher
  },

  methods: {
    onMobile() {
        return typeof this.$refs.checkMobile !== 'undefined' && ! this.$refs.checkMobile.checkVisibility()
    },

    progressEmployee(employee) {
      if (null === employee || typeof employee.finished == 'undefined')
        return ''

      switch (employee.finished) {
        case 0:
          return 'far fa-circle text-red'

        case 1:
          return 'fas fa-circle-half-stroke fa-rotate-270'

        case 2:
          return 'fas fa-circle'
      }
    },

    progressEmployeeTitle(employee) {
      if (null === employee || typeof employee.finished == 'undefined')
        return ''

      switch (employee.finished) {
        case 0:
          return 'Nog niet begonnen'

        case 1:
          return 'Begonnen'

        case 2:
          return 'Klaar!'
      }
    },

    nextPeriod() {
      if (++this.currentPeriodIdx >= this.periods.length)
        this.currentPeriodIdx = 0
    },

    prevPeriod() {
      if (--this.currentPeriodIdx <= 0)
        this.currentPeriodIdx = this.periods.length - 1
    },

   prevContent() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.titles.length - 1;
      }
    },

    nextContent() {
      if (this.currentIndex < this.titles.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },

    createUser() {
      this.$router.push({
          name: 'user-edit',
          params: {
              id: 'create'
          },
      })
    },

    importUser() {
      this.$router.push({
          name: 'user-import',
          params: {
              id: 'import'
          },
      })
    },

    createCompany() {
      this.$router.push({
        name: 'company-edit',
        params: {
          id: 'create'
        },
      })
    },

    createDepartment() {
      this.$router.push({
        name: 'department-edit',
        params: {
          id: 'create'
        },
      })
    },

    createForm() {
      let route = 'form-edit'
        if (this.$route.name.startsWith('toscani'))
            route = 'toscani-' + route

        this.$router.push({
            name: route,
            params: {
                id: 'create'
            }
        })
    },

    createPeriod() {
      this.$router.push({
          name: 'period-edit',
          params: {
              id: 'create'
          },
      })
    },
  },

  computed: {
        greeting: () => {
            switch(true) {
                case moment().isBefore(moment().hour(12).minute(0)):
                    return 'Goedemorgen'
                case moment().isAfter(moment().hour(18).minute(0)):
                    return 'Goedenavond'
                case moment().isAfter(moment().hour(12).minute(0)):
                    return 'Goedemiddag'
            }

            return 'Hoi'
        },
        currentContent() {
          return this.contents[this.currentIndex]
        },
        currentTitle() {
          return this.titles[this.currentIndex];
        },

        percentageFinished() {
          if (!this.periods || this.periods.length <= 0 ||
            typeof this.periods[this.currentPeriodIdx] == 'undefined'
          ) {
            return 0;
          }
          return this.periods[this.currentPeriodIdx].percentage_finished;
        },

        periodData() {
          let period = {
            labels: [],
            datasets: []
          }
          if (null === this.periods || this.periods.length <= 0 ||
            typeof this.periods[this.currentPeriodIdx] == 'undefined'
          )
            return period

          let percentage = this.periods[this.currentPeriodIdx].percentage_finished
          period.datasets.push({
            label: this.periods[this.currentPeriodIdx].name,
            data: [percentage, 100 - percentage],
            backgroundColor: [
              getComputedStyle(document.body).getPropertyValue('--primary'),
              'rgb(0, 0, 0, 0.075)',
            ],
            borderWidth: 0,
            borderRadius: [100,100],
            cutout: '70%',
            circumference: 270,
            rotation: 225,
          })

          return period
        },

        periodData2() {
          let period = {
            labels: [],
            datasets: []
          }
          if (null === this.periods || this.periods.length <= 0 ||
            typeof this.periods[this.currentPeriodIdx] == 'undefined'
          )
            return period

          period.datasets.push({
            label: this.periods[this.currentPeriodIdx].name,
            data: [50, 20, 20, 10, 45],
            backgroundColor: ['red', 'orange', 'yellow', 'green', 'rgb(0, 0, 0, 0)'],
            borderWidth: 0,
            rotation: 235,
            cutout: '92.5%',
            tooltipHidden: true,
          })

          return period
        },

        chartOptions() {
          return {
              plugins: {
                tooltip: {
                  external: function(tooltipModel) {
                    // EXTENSION: filter is not enough! Hide tooltip frame
                    if (!tooltipModel.body || tooltipModel.body.length < 1) {
                      tooltipModel.caretSize = 0;
                      tooltipModel.xPadding = 0;
                      tooltipModel.yPadding = 0;
                      tooltipModel.cornerRadius = 0;
                      tooltipModel.width = 0;
                      tooltipModel.height = 0;
                    }
                  },
                  // Hide tooltips of blank gabs
                  filter: function(tooltipItem, data) {

                    return !tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].tooltipHidden;
                  },
                }
              }
          }
        }
  },
}
</script>
