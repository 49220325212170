var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"font-base text-white"},[(_vm.$store.is_superadmin)?_c('x-nav-item',{staticClass:"flex items-center py-2 pl-4 nav-item uppercase font-bold hover:cursor-default",attrs:{"icon":"superadmin"}},[_vm._v(" Superadmin ")]):_vm._e(),(_vm.$store.is_superadmin)?_c('x-nav-item',{attrs:{"url":{
        name: 'toscani-company-index'
    },"icon":"fa-solid fa-building text-[38.65px]","active":_vm.$route.name.startsWith('toscani-company')}},[_vm._v(" Bedrijven ")]):_vm._e(),(_vm.$store.is_superadmin)?_c('x-nav-item',{attrs:{"url":{
        name: 'toscani-form-index'
    },"icon":"fa-solid fa-clipboard text-[38.65px]","active":_vm.$route.name.startsWith('toscani-form')}},[_vm._v(" Formulieren ")]):_vm._e(),(_vm.$store.is_superadmin)?_c('x-nav-item',{attrs:{"url":{
        name: 'toscani-mail-index'
    },"icon":"fa-solid fa-envelope text-[29px] h-[38.65px] py-1","active":_vm.$route.name.startsWith('toscani-mail')}},[_vm._v(" E-mail ")]):_vm._e(),(_vm.$store.isLoggedIn)?_c('x-nav-item',{attrs:{"icon":"fa-solid fa-right fa-right-from-bracket fa-rotate-180 text-red-500 text-[29px] mr-[8px] h-[38.65px] py-1"},on:{"click":function($event){return _vm.$root.logoff()}}},[_vm._v("Uitloggen")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }