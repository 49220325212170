<template>
  <PlainAdmin :withMenu="false">
    <x-reset-password  ref="form" :errors="errors" x-class="bg-white rounded-xl m-auto md:mt-64" @reset="change" :isNew="true" />
  </PlainAdmin>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'SetPassword',

  data: () => ({
    errors: {}
  }),

  methods: {
    change: function (data) {
      let token = encodeURIComponent(this.$route.params.token)
      let email = encodeURIComponent(this.$route.query.email)

      Axios.post(`/auth/password/set/${token}?email=${email}`, data).then((response) => {
        if (response.status == 200) {
          this.$notify('Je kan nu inloggen met je emailadres en je gekozen wachtwoord.')
          this.$router.push({name: 'Login'})
        }
      }).catch(error => {
        if (error.response.status == 406) {
          this.$notify({text: error.response.data.message, type: 'error'})
        }
      })
    }
  }
}
</script>