import Axios from 'axios'
// import * as PusherPushNotifications from '@pusher/push-notifications-web'
// import * as roles from '@/AppSettings'
// import AppSettings from '@/AppSettings'

export default function (Vue) {
  let store = {
    _state: {
      isLoggedIn: false,
      user: null,
      at: window.localStorage.getItem('at'),
      atex: window.localStorage.getItem('atex')
    },

    setUser (user) {
      this._state.user = user
      window.localStorage.setItem('user', JSON.stringify(this._state.user))
    },

    setIsLoggedIn (isLoggedIn) {
      this._state.isLoggedIn = isLoggedIn
    },

    reset(callback){
      this.setUser(null);
      this.setIsLoggedIn(false);

      window.localStorage.removeItem('user');
      window.localStorage.removeItem('at');
      window.localStorage.removeItem('atex');

      callback()
    },

    setAt(at, atex) {
      this._state.at = at
      this._state.atex = Date.now() + (parseInt(atex) * 1000)

      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + at;
      this._state.isLoggedIn = true
      window.localStorage.setItem('at', this._state.at)
      window.localStorage.setItem('atex', this._state.atex)
    },

    get isLoggedIn() {
      let at = window.localStorage.getItem('at')
      let atex = window.localStorage.getItem('atex')

      // if (at != null && atex != null
      // ) {
      //   if (Date.now() > parseInt(window.localStorage.getItem('atex')) && '/meeting' != document.location.pathname) {
      //     this._state.isLoggedIn = false
      //   }

      //   return at != null && at != '' && atex != null && Date.now() < parseInt(window.localStorage.getItem('atex'))
      // }


      return at != null && at != '' /* && atex != null && Date.now() < parseInt(window.localStorage.getItem('atex')) */
    },

    get role() {
      let role = this._state.user.roleAsText

      return role.charAt(0).toUpperCase() + role.substring(1)
    },

    get is_admin() {
      return null === this._state.user ? false : this._state.user.is_admin
    },

    get is_superadmin() {
      return null === this._state.user ? false : this._state.user.is_superadmin
    },

    get user() {
      if (! this._state.user && this.isLoggedIn) {
        this.requireUser()
      }

      return this._state.user
    },

    get at () {
      if (this._state.at == window.localStorage.getItem('at'))
        return this._state.at
      else if (Date.now() < parseInt(window.localStorage.getItem('atex')))
        return window.localStorage.getItem('at')

      return null
    },

    get atex () {
      if (this._state.atex == window.localStorage.getItem('atex'))
        return this._state.atex

      return -1;
    },

    async requireUser() {
      await Axios.get('/auth/me').then((response) => {
        this.setUser(response.data)
      })
    },

    login(email, password, remind = false) {
      let req = Axios.post("/auth/login", {
        email: email,
        password: password,
        remind: remind,
      }).then((response) => {
        this.setAt(response.data.access_token, response.data.expires_in)
        this._state.user = response.data.user

        window.localStorage.setItem('user', JSON.stringify(this._state.user))

        if (remind && response.data.remindToken) {
          window.localStorage.setItem('remindToken', response.data.remindToken)
        }

        return response
      })
      return req
    },

    logoff() {
      Axios.post("/auth/logout").then(() => {
        window.router.push('/login')
        this._state.isLoggedIn = false;
        this._state.user = null;
        delete Axios.defaults.headers.common['Authorization']
        window.localStorage.removeItem('at')
        window.localStorage.removeItem('atex')
        window.localStorage.removeItem('user')
      })
    },
  }

  try {
    store._state.user = window.localStorage.getItem('user') != null ? JSON.parse(window.localStorage.getItem('user')) : null
  } catch (e) {
    console.log(e)
  }

  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.at
  Vue.prototype.$store = Vue.store = store
}