<template>
    <div class="bg-black/10 rounded-b-2xl backdrop-blur-lg shadow-sentiment px-3 [@media(min-width:1251px)]:px-6 scrollbar-none">
        <div class="overflow-y-scroll scrollbar-none" :class="isEmployee ? 'max-h-[calc(100vh_-_20rem)]' : 'max-h-[calc(100vh_-_18.75rem)]'">
            <template  v-for="(question, qidx) in questions">
                <div v-if="question" class="text-black font-poppins mb-10 first:mt-5 last:mb-7 bg-black/10 p-4 mx-3 rounded-xl shadow-sentiment" :key="'question-' + qidx">
                    <div class="font-semibold">
                        <p>{{ question.question }}</p>
                    </div>

                    <!--<template v-if="getAnswersGiven(question.id).length === 0">
                        <p>Nog geen antwoorden gegeven</p>
                    </template>-->

                    <div v-if="['scale', 'smiley', 'number', 'radio', 'checkbox', 'select'].includes(question.type)" class="mt-3 mb-1 inline-block w-5/6 bg-black/10 py-2 pl-3 rounded-l-xl whitespace-nowrap overflow-y-scroll md:scrollbar-none">
                        Score:
                    </div>
                    <p v-if="['scale', 'smiley', 'number', 'radio', 'checkbox', 'select'].includes(question.type)" class="mb-1 inline-block w-1/6 bg-black/10 p-2 rounded-r-xl text-center overflow-y-scroll md:scrollbar-none">
                        {{ (null != (max = getMax(question))) ? getScore(question, period.id) + ' / ' + max : 'N.v.t.' }}
                    </p>

                    <hr v-if="['scale', 'smiley', 'number', 'radio', 'checkbox', 'select'].includes(question.type)" class="border-[2.5px] rounded-full border-black/20">

                    <div v-for="(answer, aidx) in getAnswersGiven(question.id)" :key="'answer-' + aidx">
                        <p v-if="!['text', 'textarea', 'upload', 'scale', 'smiley', 'number'].includes(question.type)" class="mt-3 inline-block w-5/6 bg-black/10 py-2 pl-3 rounded-l-xl whitespace-nowrap overflow-y-scroll md:scrollbar-none">
                            {{  typeof answer.answer !== 'undefined' ? answer.answer : answer }}
                        </p>

                        <p v-if="['number', 'scale'].includes(question.type) && getCount(period.id, qidx, answer, false) > 0" class="mt-3 inline-block w-5/6 bg-black/10 py-2 px-3 rounded-l-xl whitespace-nowrap overflow-y-scroll md:scrollbar-none">
                            {{  answer }}
                        </p>

                        <template v-if="['text'].includes(question.type) && getCount(period.id, qidx, answer, false) > 0">
                            <p class="mt-3 inline-block w-full bg-black/10 py-2 px-3 rounded-xl whitespace-nowrap overflow-y-scroll md:scrollbar-none">{{ answer }}</p>
                        </template>

                        <template v-if="['textarea'].includes(question.type) && getCount(period.id, qidx, answer, false) > 0">
                            <p class="mt-3 inline-block w-full bg-black/10 py-2 px-3 rounded-xl hyphens-auto">{{ answer }}</p>
                        </template>

                        <template v-if="['upload'].includes(question.type) && getCount(period.id, qidx, answer, false) > 0">
                            <p class="mt-3 inline-block w-full bg-black/10 py-2 px-3 rounded-xl whitespace-nowrap overflow-y-scroll md:scrollbar-none hover:bg-black/20"><a :href="$appSettings.API_URL.replace('api/', '') + 'storage/' + answer">{{ answer }}</a></p>
                        </template>

                        <p v-if="question.type == 'smiley' && getCount(period.id, qidx, answer, false) > 0" class="mt-3 inline-block w-5/6  bg-black/10 py-2 pl-3 rounded-l-xl text-black/0 whitespace-nowrap overflow-y-scroll md:scrollbar-none">
                            <img :src="getSmiley(question.max, answer)" class="h-[30px] -mt-1"/>
                        </p>

                        <p v-if="['number', 'scale'].includes(question.type) && getCount(period.id, qidx, answer, false) > 0" class="inline-block w-1/6 bg-black/10 p-2 rounded-r-xl text-center overflow-y-scroll md:scrollbar-none">
                            {{ getCount(period.id, qidx, answer, true)}}
                            <template v-if="! period.anonimize && getCount(period.id, qidx, answer, false) > 0">
                                <i class="fas fa-users" @mouseover="showUsers($event)" @mouseout="hideUsers($event)"></i>
                                <div class="hidden absolute z-[500] top-10 right-5 bg-black/80 p-4 rounded-2xl shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none">
                                    <template v-for="name in submittedBy(period.id, qidx, answer).split('<br>')">
                                        {{ name }}<br>
                                    </template>
                                </div>
                            </template>
                        </p>

                        <p v-if="['smiley'].includes(question.type) && getCount(period.id, qidx, answer, false) > 0" class="inline-block w-1/6 bg-black/10 p-[9px] rounded-r-xl text-center overflow-y-scroll md:scrollbar-none">
                            {{ getCount(period.id, qidx, answer, true)}}
                            <template v-if="! period.anonimize && getCount(period.id, qidx, answer, false) > 0">
                                <i class="fas fa-users" @mouseover="showUsers($event)" @mouseout="hideUsers($event)"></i>
                                <div class="hidden absolute z-[500] top-10 right-5 bg-black/80 p-4 rounded-2xl shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none">
                                    <template v-for="name in submittedBy(period.id, qidx, answer).split('<br>')">
                                        {{ name }}<br>
                                    </template>
                                </div>
                            </template>
                        </p>

                        <p v-if="!['text', 'textarea', 'upload', 'number', 'smiley'].includes(question.type)" class="inline-block w-1/6 bg-black/10 p-2 rounded-r-xl text-center overflow-y-scroll md:scrollbar-none">
                            {{ getCount(period.id, qidx, (typeof answer == 'object' && typeof answer.answer != 'undefined' ? answer.answer : answer), false) }}
                            <template v-if="! period.anonimize && getCount(period.id, qidx, answer.answer, false) > 0">
                                <i class="fas fa-users" @mouseover="showUsers($event)" @mouseout="hideUsers($event)"></i>
                                <div class="hidden absolute z-[500] top-10 right-5 bg-black/80 p-4 rounded-2xl shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none">
                                    <template v-for="name in submittedBy(period.id, qidx, answer.answer).split('<br>')">
                                        {{ name }}<br>
                                    </template>
                                </div>
                            </template>
                        </p>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script>
import column from '@/functions/arrayColumn'
import unique from '@/functions/arrayUnique'

const array_column = column.array_column
const array_unique = unique.array_unique

export default {
    name: 'PeriodResult',

    props: {
        'period': {},
        'questions': {},
        'isEmployee': {
            type: Boolean,
            default: () => false
        },
    },

    methods: {
        getScore(question, periodId) {
            // periodId should be integer
            periodId = parseInt(periodId)

            // Check if question exists, has results for period and periodResults has
            // score and count
            if (typeof this.questions[question.id] == 'undefined' ||
                typeof this.questions[question.id].results == 'undefined' ||
                typeof this.questions[question.id].results[periodId] == 'undefined' ||
                typeof this.questions[question.id].results[periodId][0] == 'undefined' ||
                typeof this.questions[question.id].results[periodId][0].score == 'undefined' ||
                typeof this.questions[question.id].results[periodId][0].count == 'undefined'
            )
                return 0

            return this.questions[question.id].results[periodId][0].score /
                this.questions[question.id].results[periodId][0].count
        },

        getMax(question) {
            if (['smiley'].indexOf(question.type) != -1) {
                return question.max
            }

            if(typeof array_column(question.values, 'score') == 'undefined')
                return null

            return Math.max(...array_column(question.values, 'score'))
        },

        getAnswersGiven(questionId) {
            let answers = (() => {
                let answers = []
                if (typeof this.questions[questionId] == 'undefined' ||
                    typeof this.questions[questionId].results == 'undefined') return this.questions[questionId].values

                if (['radio', 'select', 'checkbox'].indexOf(this.questions[questionId].type) !== -1) {
                    if (typeof this.questions[questionId].values[0] !== 'undefined' &&
                        typeof this.questions[questionId].values[0].value !== 'undefined'
                    ) {
                        return array_column(this.questions[questionId].values, 'value')
                    }

                    return this.questions[questionId].values
                }

                for (let period in this.questions[questionId].results) {
                    if (typeof this.questions[questionId].results[period].length == 'undefined' ||
                        this.questions[questionId].results[period].length == 0)
                        continue

                    answers.push(...array_column(this.questions[questionId].results[period], 'answer'))
                }

                if ('number' == this.questions[questionId].type || 'smiley' == this.questions[questionId].type) {
                    // Convert answers to numbers (if they're not already) and sort them
                    return answers.map(a => Number(a)).sort((a, b) => a - b);
                }

                return answers
            })()

            return array_unique(answers);
        },


        getAnswers(question) {
            if ('smiley' == question.type) {
                return [...Array(question.max).keys()].map(el => el + 1)
            }
        },

        getSmiley(max, num) {
            let basePath = window.location.protocol + '//' + window.location.host + '/assets/img/smiles/'
            if (max == 10)
                return basePath + '10/' + num  + '.png'

            return basePath + '5/' + num + '.png'
        },

        answersStyle(questionId, employeeId) {
            if (typeof this.results == 'undefined' || typeof questionId == 'undefined') {
                return {}
            }

            let answers = this.getAnswersGiven(questionId, employeeId)

            if (typeof answers == 'undefined' || null === answers)
                return {}

            let rows = Object.keys(answers).length +1
            let maxWidth = '300px'

            if (this.onMobile())
                maxWidth = '50vw'

            if ('smiley' == this.questions[questionId].type) {
                rows = [...Array(this.questions[questionId].max).keys()].length + 1
            }

            let periods = Object.keys(this.periods).length

            return {
                'grid-template-rows': `repeat(${rows}, auto)`,
                'grid-template-columns': `repeat(${periods}, 1fr)`
            }
        },

        getCount(period, idx, answer, withSmileyCheck = true) {
            if (typeof this.questions[idx] == 'undefined') return 0;

            if (typeof answer == 'object' && typeof answer.answer !== 'undefined') {
                answer = answer.answer
            }

            if (withSmileyCheck && this.questions[idx].type == 'smiley') {
                let aidx

                // adx is value
                if (typeof this.questions[idx].results[period] !== 'undefined' &&
                    (aidx = this.questions[idx].results[period].findIndex(el => el.answer == answer)) != -1
                ) {
                    return this.questions[idx].results[period][aidx].count
                }

                return 0
            }

            let adx = -1

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period].findIndex !== 'undefined'
            ) {
                adx = this.questions[idx].results[period].findIndex(el => {
                    if (typeof el.answer != 'undefined')
                        return el.answer == answer

                    return el.answer == answer
                })

                if (adx !== -1 && typeof this.questions[idx].results[period][adx] !== 'undefined' &&
                        typeof this.questions[idx].results[period][adx]['count'] !== 'undefined') {
                    return this.questions[idx].results[period][adx]['count']
                }
            }

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer]['count'] !== 'undefined'
            )
                return this.questions[idx].results[period][answer]['count']

            return 0
        },

        getCountForEmployee(period, eidx, idx, adx, withSmileyCheck = true) {
            if (typeof this.employees[eidx] == 'undefined' ||
                typeof this.employees[eidx].results[period] == 'undefined'
            ) {
                return 0
            }

            if (withSmileyCheck && this.questions[idx].type == 'smiley') {

                let aidx

                // adx is value
                if (typeof this.employees[eidx].results[period][idx] !== 'undefined' &&
                    (aidx = this.employees[eidx].results[period][idx].findIndex(el => el.answer == (adx + 1))) != -1
                ) {
                    return this.employees[eidx].results[period][idx][aidx].count
                }

                return 0
            }


            if (typeof this.employees[eidx].results[period][idx] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx][adx] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx][adx]['count'] !== 'undefined'
            ) {
                return this.employees[eidx].results[period][idx][adx]['count']
            }

            if (typeof this.employees[eidx].results[period] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx] !== 'undefined'
            ) {
                let answer = this.questions[idx].values[adx]
                let answerIdx = -1
                if (typeof answer !== 'undefined') {
                    for(let aidx in this.employees[eidx].results[period][idx]) {
                        if (this.employees[eidx].results[period][idx][aidx].answer == answer) {
                            answerIdx = aidx
                            break
                        }
                    }
                }

                if (answerIdx == -1) {
                    for(let aidx in this.employees[eidx].results[period][idx]) {
                        if (this.employees[eidx].results[period][idx][aidx].answer == adx) {
                            answerIdx = aidx
                            break
                        }
                    }
                }

                if (answerIdx !== -1 && typeof this.employees[eidx].results[period][idx][answerIdx] !== 'undefined' &&
                        typeof this.employees[eidx].results[period][idx][answerIdx]['count'] !== 'undefined') {
                    return this.employees[eidx].results[period][idx][answerIdx]['count']
                }
            }

            return 0
        },

        submittedBy(period, idx, answer) {
            if (typeof this.period == 'undefined' &&
                this.period.anonimize ||
                typeof this.questions[idx] == 'undefined'
            ) {
                return ''
            }

            if (typeof answer == 'object' && typeof answer.answer !== 'undefined') {
                answer = answer.answer
            }

            let adx = -1

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period].findIndex !== 'undefined'
            ) {
                adx = this.questions[idx].results[period].findIndex(el => {
                    if (typeof el.answer != 'undefined')
                        return el.answer == answer

                    return el.answer == answer
                })

                if (adx !== -1 && typeof this.questions[idx].results[period][adx] !== 'undefined' &&
                        typeof this.questions[idx].results[period][adx]['submitted_by'] !== 'undefined') {
                    return this.questions[idx].results[period][adx]['submitted_by'].join('<br>')
                }
            }

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer]['submitted_by'] !== 'undefined'
            )
                return this.questions[idx].results[period][answer]['submitted_by'].join('<br>')

            return ''
        },

        showUsers(event) {
            let users = event.target.nextSibling
            users.style.top = (event.target.offsetTop + 20) + 'px'
            users.classList.remove('hidden')

        },

        hideUsers(event) {
            event.target.nextSibling.classList.add('hidden')
        },
    },
}
</script>