<template>
    <AppLayout x-class="w-full">
        <div class="md:h-[80vh] w-full flex flex-col place-content-center">
            <div class="text-center text-black text-3xl md:text-5xl w-full">
                Bedankt voor het invullen!<br>
                <RouterLink :to="{name: 'sentiment-dashboard'}" class="text-base text-white font-semibold bg-sentiment-green py-1 px-3 rounded-lg hover:scale-[1.05] shadow-sentiment hover:shadow-sentimentDarker ease-in-out duration-200">&lt; Terug naar het dashboard</RouterLink>
            </div>

            <div class="mt-16 md:w-4/5 md:mx-auto font-semibold text-white bg-sentiment-blue flex flex-wrap py-2 rounded-t-2xl justify-center shadow-sentiment z-10">
                Ingevuld
            </div>
            <div v-if="status.coworkers" class="md:w-4/5 md:mx-auto max-h-fit overflow-auto p-5 backdrop-blur bg-black/10 rounded-b-2xl shadow-sentiment mb-30">
                <div class="grid grid-flow-row [@media(max-width:800px)]:grid-cols-1 grid-cols-2 [@media(min-width:1400px)]:grid-cols-4 md:auto-rows-auto gap-x-14 gap-y-4 md:mb-0">
                    <div v-for="coworker in status.coworkers" class="flex justify-between bg-black/10 px-4 py-3 rounded-2xl">
                        <div class="font-semibold text-black py-2">{{ coworker.coworker.name }}</div>
                        <div v-if="coworker.status === true">
                            <span class="flex h-[37px] w-[37px] items-center justify-center relative">    
                                <!-- White circle with checkmark -->
                                <span class="inline-flex h-full w-full rounded-full bg-white shadow-sentiment"></span>
                                <i class="fa-solid fa-circle-check fa-2xl text-green-400/80 absolute"></i>
                            </span>
                        </div>
                        <div v-if="coworker.status !== true">
                            <span class="flex h-[37px] w-[37px] items-center justify-center relative">    
                                <!-- White circle with checkmark -->
                                <span class="inline-flex h-full w-full rounded-full bg-white shadow-sentiment"></span>
                                <i class="fa-solid fa-circle-xmark fa-2xl text-red-500/80 absolute"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import axios from 'axios';
import { RouterLink } from 'vue-router';

export default {
    name: 'sentiment-sent',
    data: () => ({
        'prevRoute': null,
        status: {
            "coworkers": {},
            "percentage": 50
        },
    }),
    mounted() {
        if (null !== this.prevRoute && typeof this.prevRoute.name !== 'undefined') {
            if (this.prevRoute.name == 'sentiment-form') {
                axios.get(`/sentiment/${this.prevRoute.params.period_id}/status`).then(response => {
                    this.status = response.data;
                });
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    components: { RouterLink }
}
</script>