import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login'
import LoginOptions from '@/views/LoginOptions'
import PasswordReset from '@/views/PasswordReset'
import ResetPassword from '@/views/ResetPassword'
import SetPassword from '@/views/SetPassword'
import AccessDenied from '@/views/AccessDenied'
import NotFound from '@/views/NotFound'

// import MailIndex from '@/views/Admin/Mail/Index'
// import MailEdit from '@/views/Admin/Mail/Edit'

/** Sentiment */
import Register from '@/views/Sentiment/Register'
import SentimentDashboard from '@/views/Sentiment/Dashboard'
import SentimentForm from '@/views/Sentiment/Form'
import SentimentSent from '@/views/Sentiment/Sent'

/** Common */
import RegisterCompany from '@/views/Register'
import CompanyRegistered from '@/views/Registered'
import UserProfile from '@/views/shared/Profile'

import superadmin from './superadmin'
import admin from './admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (! Vue.store.isLoggedIn) {
        next({
          name: 'Login',
          query: {
            redirect: to.path
          }
        })
        return
      }

      if (typeof Vue.store.user !== 'undefined' && null !== Vue.store.user && (Vue.store.user.is_admin == 1 || Vue.store.user.is_superadmin == 1)) {

        if ((typeof to.query.admin == 'undefined' ||! to.query.admin)) {
          if (typeof HTMLDialogElement != 'undefined') {
            const backdrop = document.createElement('div')
            const dialog = document.createElement('dialog')
            const gotoSentimentBtn = document.createElement('button')
            const gotoAdminBtn = document.createElement('button')
            const div = document.createElement('div')

            backdrop.classList.add('bg-white/50', 'w-screen', 'h-screen', 'absolute', 'top-0', 'left-0')

            div.classList.add('flex',  'justify-between', 'px-4', 'gap-4', 'mt-4')

            dialog.classList.add('absolute', 'mx-auto', 'top-1/2', '-translate-y-1/2', 'rounded-xl', 'w-full', 'md:w-1/2')
            dialog.innerText = 'Wat wil je doen?'
            dialog.appendChild(div)

            div.appendChild(gotoSentimentBtn)
            div.appendChild(gotoAdminBtn)
            gotoSentimentBtn.innerText = 'Mijn Sentiment invullen'
            gotoSentimentBtn.classList.add('bg-sentiment-green', 'p-2', 'text-white', 'rounded-lg', 'w-full', 'md:w-1/2')
            gotoSentimentBtn.onclick = () => {
              backdrop.remove()
              dialog.close()
              next({
                name: 'sentiment-dashboard'
              })
            }

            gotoAdminBtn.innerText = 'Naar het beheergedeelte'
            gotoAdminBtn.classList.add('bg-sentiment-blue', 'p-2', 'text-white', 'rounded-lg', 'w-full', 'md:w-1/2')
            gotoAdminBtn.onclick = () => {
              backdrop.remove()
              dialog.close()
              next()
            }

            backdrop.append(dialog)
            document.documentElement.append(backdrop)
            dialog.show()
          }

          return
        }

        next()
        return
      }

      next({
        name: 'sentiment-dashboard'
      })
    }
  },
  ...superadmin,
  ...admin,
  // {
  //   path: '/project',
  //   name: 'project-index',
  //   component: ProjectIndex,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   }
  // },
  // {
  //   path: '/report',
  //   name: 'report-index',
  //   component: ReportIndex,
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: [settings.ROLE_ADMIN, settings.ROLE_COORDINATOR]
  //   }
  // },
  // {
  //   path: '/report/create',
  //   name: 'report-create',
  //   component: ReportCreate,
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: [settings.ROLE_ADMIN, settings.ROLE_COORDINATOR]
  //   }
  // },
  // {
  //   path: '/mails',
  //   name: 'mail-index',
  //   component: MailIndex,
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: [settings.ROLE_ADMIN]
  //   }
  // },
  // {
  //   path: '/mails/edit/:id',
  //   name: 'mail-edit',
  //   component: MailEdit,
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: [settings.ROLE_ADMIN]
  //   }
  // },
  // {
  //   path: '/project/create',
  //   name: 'project-create',
  //   component: ProjectCreate,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   }
  // },
  // {
  //   path: '/project/archive',
  //   name: 'project-archive',
  //   component: ProjectArchive,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   }
  // },
  // {
  //   path: '/project/edit/:id',
  //   name: 'project-edit',
  //   component: ProjectEdit,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   },
  //   children: [
  //     {
  //       path: 'info',
  //       name: 'project-edit-info',
  //       component: ProjectInfo
  //     },
  //     {
  //       path: 'participants',
  //       name: 'project-edit-participants',
  //       component: ProjectParticipants
  //     },
  //     {
  //       path: 'meetings',
  //       name: 'project-edit-meetings',
  //       component: ProjectMeetings
  //     },
  //     {
  //       path: 'meetings/:meetingId',
  //       name: 'project-edit-meeting',
  //       component: ProjectMeetingsMeeting
  //     },
  //     {
  //       path: 'trainers',
  //       name: 'project-edit-trainers',
  //       component: ProjectTrainers
  //     },
  //     {
  //       path: 'forms',
  //       name: 'project-edit-forms',
  //       component: ProjectForms
  //     },
  //   ]
  // },
  // {
  //   path: '/submittedForm',
  //   component: SubmittedFormIndex,
  //   name: 'submittedForm-index',
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   }
  // },
  // {
  //   path: '/submittedForm/:id',
  //   component: SubmittedFormView,
  //   name: 'submittedForm-view',
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   }
  // },
  {
    path: '/meeting',
    name: 'meeting',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/password/reset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterCompany
  },
  {
    path: '/registered',
    name: 'Registered',
    component: CompanyRegistered
  },
  {
    path: '/password/reset/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      noRefresh: true
    }
  },
  {
    path: '/password/set/:token',
    name: 'SetPassword',
    component: SetPassword,
    meta: {
      noRefresh: true
    }
  },
  {
    path: '/accessDenied',
    name: 'accessDenied',
    component: AccessDenied
  },
  // {
  //   path: '/submittedForm/:id',
  //   component: EditSubmittedForm,
  //   name: 'edit-submitted-form',
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   }
  // },
  {
    path: '/sentiment/',
    component: SentimentDashboard,
    name: 'sentiment-dashboard',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/sentiment/sent',
    component: SentimentSent,
    name: 'sentiment-sent',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/sentiment/:period_id',
    name: 'sentiment-form',
    component: SentimentForm,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login/options',
    component: LoginOptions,
    name: 'admin-login-options',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/profile',
    component: UserProfile,
    name: 'user-profile',
    meta: {
      noRefresh: true
    }
  },
  {
    path: '/**',
    name: 'notFound',
    component: NotFound,
    meta: {
      noRefresh: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
window.router = router

// router.beforeRouteLeave(() => router.app.msg = null)

router.beforeEach((to, from, next) => {
  window.scrollTo(0,0) // scroll to top

  if(
    (from !== null && from.fullPath == '/login') ||
    (to !== null && to.fullPath == '/login') ||
    (typeof to.meta.noRefresh != 'undefined' && to.meta.noRefresh)
  ){
    return next()
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (! Vue.store.isLoggedIn) {
      next({
        path: '/login',
        query: { redirect: to.path }
      })
    } else {
      if (typeof to.meta.requiresAdmin == 'undefined' && typeof to.meta.requiresSuperAdmin == 'undefined') {
        next()
        return
      }

      if (typeof to.meta.requiresSuperAdmin == 'boolean' &&
          to.meta.requiresSuperAdmin &&
          Vue.store.user != null &&
          Vue.store.user.is_superadminadmin
      ) {
        next()
        return
      }

      if (typeof to.meta.requiresAdmin == 'boolean' &&
          to.meta.requiresAdmin &&
          Vue.store.user != null &&
          Vue.store.user.is_admin
      ) {
        next()
        return
      }
    }
  }

  next() // make sure to always call next()!
})

export default router
