<template>
    <div :class="! onMobile && isToggled == true && noPeriods > 1 ? 'grid grid-cols-2' : ''" class="z-10">
        <p
            class="text-center text-white font-semibold bg-sentiment-blue rounded-t-2xl shadow-sentiment py-3 px-6 mt-2 relative"
            :class="classes">

            <a role="button" @click="prev1()" class="text-sentiment-green inline-block mr-3">
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="translate-y-1">
                    <path d="M10 17L2 9.5L10 2" stroke="currentColor" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </a>

            <a @click="togglePeriods()" id="showPeriodsBtn" role="button" class="relative">
                {{ period1.period }}

                <ul class="absolute z-50 top-10 right-1/2 translate-x-1/2 bg-black/40 p-4 rounded-2xl backdrop-blur-lg shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none"
                    v-if="showPeriods" v-click-outside="hidePeriods">
                    <li v-for="(period, key, idx) in periods" class="font-light text-white leading-relaxed whitespace-nowrap" @click="$emit('selectPeriod', idx)">{{ period.period }}</li>
                </ul>
            </a>

            <a role="button" @click="next1()" class="text-sentiment-green inline-block ml-3">
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="translate-y-1">
                    <path d="M2 2L10 9.5L2 17" stroke="currentColor" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </a>

            <i class="fa-solid fa-person-circle-question fa-lg text-white absolute right-5 top-4 hover:cursor-pointer"
                @click="toggleElements1()" id="showElementsBtn" :title="isEmployee ? 'Wie heeft deze periode de vragenlijst ingevuld over ' + employees[employee].name : 'Wie heeft deze periode de vragenlijst ingevuld'">
            </i>
            <i class="fa-solid fa-person-circle-xmark fa-lg text-red-500 bg-sentiment-blue absolute right-5 top-4 hover:cursor-pointer"
                v-if="showElements1" v-click-outside="hideElements1" @click="toggleElements1()" id="hideElementsBtn">
            </i>
            <ul class="absolute z-50 top-10 right-5 bg-black/40 p-4 rounded-2xl backdrop-blur-lg shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none"
                v-if="showElements1" v-click-outside="hideElements1">
                Ingevuld door:
                <br>
                <span class="font-light text-white leading-relaxed" v-html="formattedSubmittedBy1"></span>
            </ul>
        </p>
        <p v-if="! onMobile && isToggled == true && noPeriods > 1" class="text-center text-white font-semibold bg-sentiment-blue rounded-t-2xl shadow-sentiment py-3 px-6 -mb-5 mx-5 mt-2 relative">
            <a role="button" @click="prev2()" class="text-sentiment-green inline-block mr-3">
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg" class="translate-y-1">
                    <path d="M10 17L2 9.5L10 2" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>

            {{ period2.period }}

            <a role="button" @click="next2()" class="text-sentiment-green inline-block ml-3">
                <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg" class="translate-y-1">
                    <path d="M2 2L10 9.5L2 17" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>

            <i class="fa-solid fa-person-circle-question fa-lg text-white absolute right-5 top-4 hover:cursor-pointer"
                @click="toggleElements2()" id="showElementsBtn2">
            </i>
            <i class="fa-solid fa-person-circle-xmark fa-lg text-red-500 bg-sentiment-blue absolute right-5 top-4 hover:cursor-pointer"
                v-if="showElements2" v-click-outside="hideElements2" @click="toggleElements2()" id="hideElementsBtn2">
            </i>

            <ul class="absolute z-50 top-10 right-5 bg-black/40 p-4 rounded-2xl backdrop-blur-lg shadow-sentiment text-sentiment-green max-h-[400px] overflow-y-scroll scrollbar-none"
                v-if="showElements2" v-click-outside="hideElements2">
                Ingevuld door:
                <br>
                <span class="font-light text-white leading-relaxed" v-html="formattedSubmittedBy2"></span>
            </ul>
        </p>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'PeriodSwitcher',

    props: {
        'employees': {},
        'employee': {},
        'periods': {},
        'period1': {},
        'period2': {},
        'questions': {},
        'onMobile': {},
        'isToggled': {},
        'noPeriods': {},
        'isEmployee': {
            type: Boolean,
            default: () => false
        },
    },

    data: () => ({
        showElements1: false,
        showElements2: false,
        showPeriods: false,
    }),

    computed: {
        classes() {
            let classes = {
                'mx-5': true,
                '-mb-5': true
            }

            if (this.isEmployee) {
                classes['mx-5'] = false,
                classes['-mb-5'] = false
            }

            return classes
        },

        formattedSubmittedBy1() {
            if (this.isEmployee) {
                if (typeof this.employees[this.employee] == 'undefined' ||
                    typeof this.employees[this.employee].submittedBy == 'undefined' ||
                    typeof this.employees[this.employee].submittedBy[this.period1.id] == 'undefined'
                )
                    return ''

                return this.employees[this.employee].submittedBy[this.period1.id].join('<br>')
            }

            return Object.values(this.period1.submitted_by).join('<br>')
        },

        formattedSubmittedBy2() {
            return Object.values(this.period2.submitted_by).join('<br>')
        },
    },

    methods: {
        togglePeriods() {
            this.showPeriods = ! this.showPeriods
        },

        hidePeriods(event) {
            if (event.target == document.querySelector('#showPeriodsBtn'))
                return false

            this.showPeriods = false
        },

        prev1() {
            this.$emit('prev1')
        },

        next1() {
            this.$emit('next1')
        },

        prev2() {
            this.$emit('prev2')
        },

        next2() {
            this.$emit('next2')
        },

        toggleElements1() {
            this.showElements1 = !this.showElements1
        },

        hideElements1(e) {
            if (e.target == document.querySelector('#showElementsBtn') ||
                e.target == document.querySelector('#showElementsBtn2')
            )
                return false

            this.showElements1 = false
        },

        toggleElements2() {
            this.showElements2 = !this.showElements2
        },

        hideElements2(e) {
            if (e.target == document.querySelector('#showElementsBtn') ||
                e.target == document.querySelector('#showElementsBtn2')
            )
                return false

            this.showElements2 = false
        },

        getCount(period, idx, answer, withSmileyCheck = true) {
            if (typeof this.questions[idx] == 'undefined') return 0;

            if (withSmileyCheck && this.questions[idx].type == 'smiley') {
                let aidx

                // adx is value
                if (typeof this.questions[idx].results[period] !== 'undefined' &&
                    (aidx = this.questions[idx].results[period].findIndex(el => el.answer == answer)) != -1
                ) {
                    return this.questions[idx].results[period][aidx].count
                }

                return 0
            }

            let adx = -1

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period].findIndex !== 'undefined'
            ) {
                adx = this.questions[idx].results[period].findIndex(el => {
                    if (typeof el.answer != 'undefined')
                        return el.answer == answer

                    return el.answer == answer
                })

                if (adx !== -1 && typeof this.questions[idx].results[period][adx] !== 'undefined' &&
                        typeof this.questions[idx].results[period][adx]['count'] !== 'undefined') {
                    return this.questions[idx].results[period][adx]['count']
                }
            }

            if (typeof this.questions[idx].results[period] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer] !== 'undefined' &&
                typeof this.questions[idx].results[period][answer]['count'] !== 'undefined'
            )
                return this.questions[idx].results[period][answer]['count']

            return 0
        },

        getCountForEmployee(period, eidx, idx, adx, withSmileyCheck = true) {
            if (typeof this.employees[eidx] == 'undefined' ||
                typeof this.employees[eidx].results[period] == 'undefined'
            ) {
                return 0
            }

            if (withSmileyCheck && this.questions[idx].type == 'smiley') {

                let aidx

                // adx is value
                if (typeof this.employees[eidx].results[period][idx] !== 'undefined' &&
                    (aidx = this.employees[eidx].results[period][idx].findIndex(el => el.answer == (adx + 1))) != -1
                ) {
                    return this.employees[eidx].results[period][idx][aidx].count
                }

                return 0
            }


            if (typeof this.employees[eidx].results[period][idx] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx][adx] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx][adx]['count'] !== 'undefined'
            ) {
                return this.employees[eidx].results[period][idx][adx]['count']
            }

            if (typeof this.employees[eidx].results[period] !== 'undefined' &&
                typeof this.employees[eidx].results[period][idx] !== 'undefined'
            ) {
                let answer = this.questions[idx].values[adx]
                let answerIdx = -1
                if (typeof answer !== 'undefined') {
                    for(let aidx in this.employees[eidx].results[period][idx]) {
                        if (this.employees[eidx].results[period][idx][aidx].answer == answer) {
                            answerIdx = aidx
                            break
                        }
                    }
                }

                if (answerIdx == -1) {
                    for(let aidx in this.employees[eidx].results[period][idx]) {
                        if (this.employees[eidx].results[period][idx][aidx].answer == adx) {
                            answerIdx = aidx
                            break
                        }
                    }
                }

                if (answerIdx !== -1 && typeof this.employees[eidx].results[period][idx][answerIdx] !== 'undefined' &&
                        typeof this.employees[eidx].results[period][idx][answerIdx]['count'] !== 'undefined') {
                    return this.employees[eidx].results[period][idx][answerIdx]['count']
                }
            }

            return 0
        },
    }
}
</script>