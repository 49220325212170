<template>
  <PlainAdmin title="Mijn Sentiment" :withMenu="false">
    <x-form ref="form" x-class="bg-white rounded-xl m-auto" @login="login" :errors="errors" />
  </PlainAdmin>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',

  data: () => ({
      email: null,
      password: null,
      otp: null,
      appName: null,
      message: null,
      error: false,
      errors: [],

      icons: null,
      needsOtp: false,
      remindPc: false,
	}),

  watch: {
    'refs.form.email': function () {
      if (this.error) {
        this.error = false
        this.password = null
      }
    },
    'refs.form.password': function () {
      if (this.error)
        this.error = false
    },
    otp: function () {
      if (this.errors && this.errors.otp !== undefined && this.errors.otp[0] !== undefined)
        this.errors = []
    },
    icons(newVal) {
      this.$nextTick(function () {
        let email = document.getElementById('email')

        if (null !== newVal && null !== email)
          email.focus()
      })
    }
  },
  methods: {
    login: function (data) {
      let [email, password, remindPc] = data

      this.error = false
      this.errors = {}
      this.$store.login(email, password, remindPc)
        .then((response) => {
          if (response.data.needsOtp !== undefined) {
            this.needsOtp = response.data.needsOtp
            return
          }

          if (response.data.remindToken !== undefined) {
            window.localStorage.setItem('remindToken', response.data.remindToken)
          }

          axios.get('common/settings').then(response => {
            this.$root.settings = response.data
          })

          this.$router.push((this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')).catch(() => {
            // ignore error in routing
          })
        })
        .catch(error => {
          if (! this.needsOtp)
            this.password = ''
          else
            this.otp = ''

          this.error = true
          if (error.response && 401 == error.response.status && typeof error.response.data.errors !== 'undefined') {
            this.errors = error.response.data.errors
          }
        })
    }
  }
};
</script>
