<template>
  <Admin :title="isNew ? 'Nieuwe gebruiker toevoegen' : (user != null ? `Gebruiker bewerken` : 'Gebruiker laden...')" :hasTooltips="true">
    <template>
      <x-user-card v-if="user !== null" :title="'Gebruiker ' + (isNew ? 'toevoegen' : 'bewerken')" :user="user"
        :departments="departments" :companies="filteredCompanies" :orgSelector="orgSelector"
        :originalUser="originalUser" :errors="errors" 
      />

      <div class="fixed bottom-6 right-6 w-max">
        <x-button-blue @click="$router.go(-1)" x-class="[@media(min-width:885px)]:inline-block mb-2 [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-4">Annuleren</x-button-blue>
        <x-button-green @click="submit" x-class="[@media(min-width:885px)]:inline-block [@media(min-width:885px)]:mb-0 [@media(min-width:885px)]:mr-2">Opslaan</x-button-green>
      </div>

      <br />
    </template>
  </Admin>
</template>

<script>
import axios from 'axios'
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/nl";
import FormModal from '@/components/modals/formModal'
import * as settings from '@/AppSettings'
import SubmittedFormView from '@/views/shared/SubmittedFormView'
import moment from 'moment';

export default {
  components: {
    DatePicker,
  },

  name: 'UserEdit',

  data() {
    return {
      datepickerFormat: "D-M-YYYY",
      reader: null,
      originalUser: null,
      user: {
          date_of_birth: null,
          firstname: '',
          prefix: '',
          lastname: '',
          is_admin: false,
          is_superadmin: false,
          company: {
            id: null,
            name: null,
            street: null,
            number: null,
            postcode: null,
            city: null,
            departments: []
          },
          company_id: null,
          department: {},
          department_id: null,
      },
      companies: null,
      isNew: false,
      errors: {},
      orgSelector: true,
      project: null,
      projects: [],
      selectedProject: null,
      notifyUser: false,
      notifyOrg: false,
      addedProjects: [],
      settings: settings,
    }
  },

  watch: {
    'user.password_type': function (newVal, oldVal) {
      if (newVal != oldVal)
        delete this.user.plainPassword
        delete this.user.plainPassword_confirmation
        this.errors = {}
    },

    'user.company_id': function (newVal) {
      if (-1 == newVal) {
        this.user.company.name = null
      }
    },

    $route: function (to) {
      this.loadUser(to.params.id)
    },
  },

  created: function () {
    let _this = this
    this.reader = new FileReader

    this.reader.onloadend = function () {
      if (typeof _this.user == 'undefined') return

      _this.user.profilePicture = _this.reader.result
    }

    axios.get('admin/company?noPaginate').then((response) => {
      this.companies = response.data
    }).then(() => {
      if (this.$route.params.id != 'create') {
        this.loadUser(this.$route.params.id)
      } else {
        this.isNew = true
        // this.companies[1].name = "Gebruiker zit niet bij een organisatie"

        if (this.$route.query.project !== undefined) {
          this.project = this.$route.query.project
        }

        if (this.$route.query.company !== undefined) {
          this.orgSelector = false
          this.user.company_id = this.$route.query.company
        }

        if (this.$route.query.is_admin !== undefined && this.$route.query.is_admin == 'true') {
          this.user.is_admin = true
        }
      }
    })
  },

  computed: {
    filteredCompanies: function () {
      let companies = []

      // if (this.user && this.user.department && this.user.department.company)
      //   return [this.user.department.company]

      for (let idx in this.companies) {
        companies.push(this.companies[idx])

        for (let cidx in this.companies[idx].companies) {
          companies.push(this.companies[idx].companies[cidx])
        }
      }
      return companies
    },

    departments: function () {
      if (this.companies && this.user.company_id) {
        let idx = this.companies.findIndex((el) => el.id == this.user.company_id)
        if (-1 != idx) {
          return this.companies[idx].departments
        }

        let sidx = -1
        idx = this.companies.findIndex((el) => {
          if (! el.companies) return false
          sidx = el.companies.findIndex((el) => el.id == this.user.company_id)

          return -1 != sidx
        })

        if (-1 != sidx) {
          return this.companies[idx].companies[sidx].departments
        }

        return []
      }
      return []
    }
  },

  methods: {
    saveAllowed() {
      if (this.settings.ROLE_COORDINATOR == this.$store.user.role) {
        return this.user.role != this.settings.ROLE_ADMIN && this.user.role != this.settings.ROLE_COORDINATOR
      }

      return true
    },

    loadUser(id) {
      axios.get(`admin/user/${id}`).then((response) => {
          this.user = response.data
          this.originalUser = Object.assign({}, response.data)

          if (null === this.user.address) {
            this.user.address = {
              street: null,
              postcode: null,
              city: null,
            }
          }

          if (! isNaN(Date.parse(this.user.date_of_birth))) {
            let date = new Date(Date.parse(this.user.date_of_birth))
            this.user.date_of_birth = date.getDate().toString().padStart(2, '0') + '-' +
                (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
                date.getFullYear()
          }
        })/* .then(() => {
          if (null == this.user.company ||
              typeof this.user.company.address == 'undefined' ||
              null == this.user.company.address
          ) {
            this.user.company = {
              name: this.user.company ? this.user.company.name : null,
              address: {
                street: null,
                number: null,
                postcode: null,
                city: null,
              }
            }
          }
        }) */
    },

    findProject(term, loading) {
      let params = new URLSearchParams()
      params.append('noPaginate', true)
      params.append('filter[name]', term)
      for (let idx in this.user.pillars) {
        params.append('filter[pillar][]', this.user.pillars[idx].id ?? this.user.pillars[idx])
      }

      loading(true)
      axios.get(`admin/project`, { params: params }).then((response) => {
        loading(false)
        this.projects = response.data
      })
    },

    addProject(value) {
      this.addedProjects.push(value)
      this.user.participates_in.push(value)
      this.selectedProject = null
    },

    removeProject(idx) {
      this.user.participates_in.splice(idx, 1)
    },

    previewImg: function () {
      let file = this.$refs.file.files[0]
      if (file) {
        this.reader.readAsDataURL(this.$refs.file.files[0])
      }
    },

    userCreated: function (response) {
      if (response.data.form !== undefined) {
        this.showForm(response)
      }

      if (this.project) {
        axios.put(`/project/${this.project}/participant`, {
          participant: [
            response.data.id
          ]
        }).then(() => {
          this.$router.push({
            name: 'project-edit-participants',
            params: {
              id: this.project
            }
          })
        })
        return
      }

      this.$router.push({name: 'user-list'})

      this.$notify({
        type: 'success',
        title: 'Gebruiker aangemaakt',
        text: `Gebruiker krijg straks mail om zijn wachtwoord in te stellen.`
      })
    },

    userUpdated: function (response) {
      if (response.data.form !== undefined) {
        this.showForm(response)
      }

      this.$router.go(-1)

      this.$notify({
        type: 'success',
        title: 'Gebruiker geupdate',
        text: response.data.message
      })
    },

    showForm(response) {
      let _self = this
      let _res = response
        this.$modal.show({
          template: `<form-modal :form="form" @send-form="send" ref="frmCmp"></form-component>`,
          components: { FormModal },
          methods: {
            async send(values) {
              let response = await _self.sendForm(values, _res.data, _self.addedProjects[0].id, _res.data.form)

              if (response === true)
                this.$emit('close')
              else {
                this.$refs.frmCmp.currentFieldIdx = 0
                this.$refs.frmCmp.errors = response.data.errors
              }
            }
          },
          props: ['form']
        }, {
            form: response.data.form
        }, {
            height: 'auto',
        })
    },

    sendForm(values, participant, project, form) {
      let data = {
        form_id: form.id
      }

      data.fields = []
      for (let key in values) {
        data.fields.push({
          id: key,
          value: values[key]
        })
      }

      return axios.post(`admin//admin/project/${project}/participant/${participant.id}/form`, data).then(response => {
        if (201 == response.status) {
          this.$notify({
            text: 'Extra formulier opgeslagen'
          });

          return true
        }
      }).catch(error => {
        return error.response
      })
    },

    buildFormData: function (formData, data, parent = '') {
      for(let key in data) {

        if (typeof data[key] == 'object'){
          this.buildFormData(formData, data[key], key)
        }else
          formData.append(parent != '' ? `${parent}[${key}]` : key, data[key])
      }
    },

    submit: function () {
      let data = Object.assign({}, this.user)

      delete data.profilePicture

      if (! this.isNew && this.originalUser.company_id == data.company_id) {
        delete data.company
      }

      if (null === data.date_of_birth) {
        delete data.date_of_birth
      }

      data.is_admin = data.is_admin ? 1 : 0

      if (-1 == this.user.company_id) {
        data.company = this.user.company
      } else {
        data.company = {id: this.user.company_id}
      }

      if (-1 == this.user.department_id) {
        data.department = this.user.department
      } else {
        data.department = {id: this.user.department_id}
      }

      delete data.company_id, data.department_id

      // if (this.user.participates_in.length > 0) {
      //   data.projects = this.user.participates_in.map(el => el.id)
      // }

      if (this.isNew) {
        data.notifyUser = this.notifyUser
        data.notifyParent = this.notifyParent
        data.notifyOrg = this.notifyOrg

        // if (this.$refs.file.files.length > 0) {
        //   let formData = new FormData;
        //   this.buildFormData(formData, data)

        //   formData.append('profilePicture', this.$refs.file.files[0])

        //   axios.post('admin/user', formData, {headers: { 'content-type': 'multipart/form-data'}}).then((response) => {
        //     this.userCreated(response)
        //   }, this.error)

        //   return
        // }

        axios.post('admin/user', data).then((response) => {
          this.userCreated(response)
        }, this.error)
      } else {
        // if (this.$refs.file.files.length > 0) {
        //   let formData = new FormData;
        //   this.buildFormData(formData, data)

        //   formData.append('profilePicture', this.$refs.file.files[0])
        //   formData.append('_method', 'PUT')

        //   axios.post(`admin/user/${this.user.id}`, formData, {headers: { 'content-type': 'multipart/form-data'}}).then((response) => {
        //     this.userUpdated(response)
        //   }, this.error)

        //   return
        // }

        axios.put(`admin/user/${this.user.id}`, data).then((response) => {
          this.userUpdated(response)
        }, this.error)
      }
    },

    error(error) {
      this.errors = {}
      if (this.$refs.icons !== undefined) {
        this.$refs.icons.code = ''
        this.$refs.iconsConfirm.code = ''
      } else {
        this.user.plainPassword = ''
        this.user.plainPassword_confirmation = ''
      }

      this.errors = error.response.data.errors
    },

    getProjectLabel(project) {
      if (project.main_project)
        return  '<strong>' + project.main_project.name + '</strong> \\ ' + project.name

      return project.name
    },

    getDate (form) {
      return moment(form.created_at).format('D MMMM YYYY')
    },

    showSubmittedForm (form) {
      this.$modal.show({
        template: `<SubmittedFormView @form-sent="() => this.$emit('close')" :submittedFormProp="form" />`,
        props: ['form'],
        components: {SubmittedFormView}
      }, {
        form: form
      }, {
        height: 'auto',
        'max-height': 600,
        'overflow-y': 'scroll'
      })
    },

    removedPillar(value) {
      if (typeof value == 'undefined')
        return

      let idx = this.$store.user.pillars.findIndex(el => el.id == value.id)
      if (-1 != idx || this.$store.user.role == this.settings.ROLE_ADMIN) {
        idx = this.user.pillars.findIndex(el => {
          if (Number.isInteger(el)) {
            return el == value.id
          }
          return el.id == value.id})
        this.user.pillars.splice(idx, 1)
      }
    },

    getCompanyLabel(option) {
      if (option.company) {
        return `${option.company.name} > ${option.name}`
      }

      return option.name
    },

    getDepartmentLabel(option) {
      let name = option.name

      if (option.company.company_id) {
        name += ` (${option.company.name})`
      }

      return name
    },

    selectSettling(option) {
      if (option.id == this.originalUser.company_id) {
        this.user.department_id = this.originalUser.department_id
        return
      }

      this.user.department_id = null

    }
  },
}
</script>

<style scoped>
sup {
  color: red;
}
</style>