<template>
    <div class="mt-4 text-black box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl p-8 overscroll-none w-full bg-white mb-[115px] [@media(min-width:1350px)]:mb-4">
        <form action="" class="md:grid [@media(max-width:1050px)]:grid-cols-1 md:grid-cols-2 [@media(min-width:1350px)]:grid-cols-3 -mx-4">
            <div class="mb-4 block mx-4">
                <label class="font-bold text-base flex text-montserrat">Voornaam *</label>
                <input type="text" id="voornaam" v-model="user.firstname" name="voornaam" placeholder="Voornaam"
                    class=" bg-gray-100 rounded-full w-full h-input py-2 px-4">
                <span  :class="hasError('firstname')">
                    {{ getError('firstname') }}
                </span>
            </div>
            <div class="mb-4 mx-4">
                <label class="font-bold text-base flex text-montserrat ">Achternaam *</label>
                <input type="text" id="achternaam" v-model="user.lastname" name="achternaam" placeholder="achternaam"
                    class=" bg-gray-100 rounded-full items-center w-full h-input  py-2 px-4">
                <span :class="hasError('lastname')">
                    {{ getError('lastname') }}
                </span>
            </div>
            <div class="mb-4 mx-4">
                <label class=" font-bold text-base flex text-montserrat ">E-mailadres *</label>
                <input type="text" id=" E-mailadres" v-model="user.email" name="E-mailadres" placeholder="E-mailadres"
                    class="  bg-gray-100 rounded-full  w-full h-input  py-2 px-4">
            </div>
            <div class="mb-4 mx-4">
                <label class=" font-bold text-base flex text-montserrat ">Vestiging *</label>
                <v-select
                    class="bg-gray-100 rounded-full  w-full h-input  pt-1 pl-4 pr-3"
                    v-model="user.company_id"
                    :options="companies"
                    :reduce="company => company.id"
                    :getOptionLabel="getCompanyLabel"
                    @option:selected="selectSettling">
                    <template #option="company">
                    <span v-if="company.company_id">
                        {{ company.company.name}} >
                    </span>
                        {{ company.name }}
                    </template>
                </v-select>
            </div>
            <div class="mb-4 mx-4">
                <label class=" font-bold text-base flex text-montserrat ">Functie rol (optioneel)</label>
                <input type="text" id="Functie rol" v-model="user.function" name="Functie rol" placeholder="Functie rol"
                    class="bg-gray-100 rounded-full items-center w-full h-input  py-2 px-4">
            </div>

            <div class="mb-4 mx-4">
                <label class=" font-bold text-base flex text-montserrat ">Afdeling (optioneel)</label>
                <v-select
                    class="bg-gray-100 rounded-full items-center w-full h-input  pt-1 px-4"
                    v-model="user.department_id"
                    :options="departments"
                    :reduce="department => department.id"
                    label="name"
                    :clearable="true"
                    :getOptionLabel="getDepartmentLabel">
                    <template #option="department">
                    {{ department.name }}
                    <span v-if="department.company.company_id">
                        ({{ department.company.name }})
                    </span>
                    <span v-else>
                        (hoofdvestiging)
                    </span>
                    </template>
                </v-select>

            </div>
            <div>
                <label><input type="checkbox" id="check" name="checkbox" v-model="user.is_admin" :disabled="user.id == $store.user.id" class="rounded-md hover:border-blue-600 hover:cursor-pointer bg-gray-500 mx-4">Beheerder</label>
            </div>
            <div v-if="$store.user.is_superadmin">
                <label><input type="checkbox" id="check" name="checkbox" v-model="user.is_superadmin" :disabled="user.id == $store.user.id" class="rounded-md hover:border-blue-600 hover:cursor-pointer bg-gray-500 mx-4">Superadmin</label>
            </div>

            <slot />
        </form>
    </div>
</template>

<script>
export default {
    props: [
        'title', 'user', 'departments', 'companies',
        'orgSelector', 'originalUser', 'errors'
    ],

    methods: {
        hasError(field) {
            if (
                typeof this.errors !== 'undefined' &&
                typeof this.errors[field] !== 'undefined'
            ) {
                return {
                    'text-red-700': true,
                    'text-italic': true,
                    'text-xs': true,
                    hidden: false,
                }
            } else {
                return {
                    hidden: true
                }
            }
        },

        getError(field) {
            if (
                typeof this.errors !== 'undefined' &&
                typeof this.errors[field] !== 'undefined'
            ) {
                return this.errors[field][0]
            }
        },

        getCompanyLabel(option) {
            if (option.company) {
                return `${option.company.name} > ${option.name}`
            }

            return option.name
        },

        getDepartmentLabel(option) {
            let name = option.name

            // if (typeof option.company !== 'undefined' &&
            //     typeof option.company.company_id !== 'undefined' &&
            //     typeof option.company.company_id
            // ) {
            //     name += ` (${option.company.name})`
            // }

            return name
        },

        selectSettling(option) {
            if (null !== this.originalUser && option.id == this.originalUser.company_id) {
                this.user.department_id = this.originalUser.department_id
                return
            }

            this.user.department_id = null
        }
    }
}
</script>