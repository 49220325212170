import UserIndex from '@/views/Admin/User/Index'
import UserEdit from '@/views/Admin/User/Edit'
import CompanyIndex from '@/views/Admin/Company/Index'
import CompanyEdit from '@/views/Admin/Company/Edit'
import DepartmentIndex from '@/views/Admin/Department/Index'
import DepartmentEdit from '@/views/Admin/Department/Edit'
import PeriodIndex from '@/views/Admin/Period/Index'
import PeriodEdit from '@/views/Admin/Period/Edit'
import ResultIndex from '@/views/Admin/ResearchQuestion/Index'
import ResultView from '@/views/Admin/ResearchQuestion/Result'
import CompensationIndex from '@/views/Admin/Compensation/Index'
import ImportView from '@/views/Admin/Import'

import FormIndex from '@/views/Admin/Form/Index'
import FormEdit from '@/views/Admin/Form/Edit'

export default [
  {
    path: '/user',
    component: UserIndex,
    name:'user-list',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user/edit/:id',
    component: UserEdit,
    name:'user-edit',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user/import',
    component: ImportView,
    name: 'user-import',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/company',
    component: CompanyIndex,
    name: 'company-index',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/company/:id',
    component: CompanyEdit,
    name: 'company-edit',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/department',
    name: 'department-index',
    component: DepartmentIndex,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/department/:id',
    name: 'department-edit',
    component: DepartmentEdit,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/period',
    name: 'period-index',
    component: PeriodIndex,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/period/:id',
    name: 'period-edit',
    component: PeriodEdit,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/result',
    name: 'result-index',
    component: ResultIndex,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/result/:id',
    name: 'result-view',
    component: ResultView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      noBg: true
    }
  },
  {
    path: '/compensation',
    name: 'compensation-index',
    component: CompensationIndex,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/form',
    name: 'form-index',
    component: FormIndex
  },
  {
    path: '/form/:id',
    name: 'form-edit',
    component: FormEdit
  },
]