<template>
    <draggable :list="field.fields" :group="{name: 'g1'}" @start="drag = true" :delay="delay" @end="dragEnd" :empty-insert-threshold="600">
        <div v-for="(field, index) in field.fields" :key="index" class="bg-white border-slate-100 mb-8 p-10 rounded-xl text-black box-shadow shadow-sentiment hover:shadow-sentimentDarker hover:scale-[1.01] ease-in-out duration-200">
            <div class="grid grid-cols-6 items-center border-b border-b-slate-100 mb-2">
                <div class="col-span-2"><span class="sort">{{ index + 1}}.</span></div>
                <div class="col-span-2"><span class="field-type">{{ getFieldTypeName(field.type) }}</span></div>
                <div class="col-span-2 flex justify-end gap-6 mb-1">
                    <button class="btn btn-sm btn-outline-danger scale-150 hover:text-green-400 ease-in-out duration-150" @click="duplicate(field)" title="Veld dupliceren"><i class="far fa-clone"></i></button>
                    <button class="btn btn-sm btn-outline-danger scale-150 hover:text-red-400 ease-in-out duration-150" @click="remove(index)" title="Veld verwijderen"><i class="far fa-trash-alt"></i></button>
                </div>
            </div>

            <div v-if="field.type != 'additional' && field.type != 'group' && field.type != 'employees'" class="pt-2 grid grid-cols-6 items-center w-full">
                <label class="md:block">Vraag:</label>
                <div class="md:col-start-2 md:col-end-7 col-span-full mt-2">
                    <input type="text" v-model="field.name" :class="{error: fieldError(index)}" class="w-full bg-gray-100 rounded-full h-input py-2 px-4" />
                    <span class="error" v-if="fieldError(index)">{{ fieldError(index) }}</span>
                </div>
            </div>

            <div v-if="field.type == 'additional'" class="pt-2">
                <label class="md:block">Waarde:</label>
                <div class="col-md-11 col-10">
                    <textarea v-model="field.values[0].value" rows="12"
                        class="w-full bg-gray-100 rounded-xl py-2 px-4"
                    ></textarea>
                </div>
                <div class="offset-md-1 offset-2 col-md-11 col-10">
                    <div class="form-text d-block">
                        In dit veld kan je <strong>*vet*</strong> of <i>_cursief_</i> gebruiken<br>
                        Als je aan het begin van de regel #&lt;spatie&gt; zet wordt die regel een kopje
                    </div>
                </div>
            </div>

            <div v-if="field.type == 'group' || field.type == 'employees'" class="pt-2 -mb-4" style="padding-top: 0;">
                <label class="col-form-label col-2 col-md-1 d-md-block">Naam</label>
                <div class="col-md-11 col-10 mt-2">
                    <input type="text" v-model="field.name" :class="{error: fieldError(index)}" class="w-full bg-gray-100 rounded-full h-input py-2 px-4" />
                    <span class="error" v-if="fieldError(index)">{{ fieldError(index) }}</span>
                </div>

                <form-field :ref="'field' + index" :field="field" style="width: 100%; margin: 15px 15px 0;" :style="getStyle(field)" @duplicate="duplicate" />
            </div>

            <div v-if="field.type == 'number'" class="pt-2">

                <div class="col-6">
                    <div class="row">
                        <div class="col-2 mb-2">Minimum waarde:</div>
                        <div class="col-10">
                            <input type="number" v-model="field.values[0].value" class="w-full bg-gray-100 rounded-full h-input py-2 px-4 mb-4" />
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="row">
                        <div class="col-2 mb-2">Maximum waarde:</div>
                        <div class="col-10">
                            <input type="number" v-model="field.values[1].value" class="w-full bg-gray-100 rounded-full h-input py-2 px-4" />
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="field.type == 'smiley'" class="pt-2">

                    <div class="absolute" :class="{ 'text-sentiment-green': typeof field.values.max == 'undefined' && typeof field.values[1].value !== 'undefined' ? field.values[1].value == 5 : field.values.max == 5 }">
                        <h3 role="button" @click="click(index, 5)">1 - 5</h3>
                    </div>
                    <div class="absolute ml-10">of</div>
                    <div class="ml-[65px]" :class="{ 'text-sentiment-green': typeof field.values.max == 'undefined' && typeof field.values[1].value !== 'undefined' ? field.values[1].value == 10 : field.values.max == 10 }">
                        <h3 role="button" @click="click(index, 10)">1 - 10</h3>
                    </div>

            </div>

            <div v-if="field.type == 'select' || field.type == 'checkbox' || field.type == 'radio' || field.type == 'scale'" class="w-full">

                <div class="w-full mt-4">
                    <label class="mr-2">Waardes:</label>

                    <template v-for="(value, index) in field.values">
                        <div class="relative mb-4 md:flex rounded-full bg-slate-100 md:h-input mt-2" :key="index" v-if="typeof value.deleted == 'undefined' || value.deleted !== true">
                            {{  value.deleted ? 'deleted' : '' }}
                            <input type="text" class="bg-slate-100 rounded-t-xl md:rounded-none md:rounded-l-full py-1 pl-4 w-full" v-model="field.values[index].value" />
                            <div v-if="['scale'].indexOf(field.type) == -1" class="bg-slate-100 p-1 flex rounded-b-xl md:rounded-non md:rounded-r-full pl-4 md:pl-0">
                                <div class="input-group-text md:pt-[5px] whitespace-nowrap mr-1">
                                    Met invoerveld:
                                </div>
                                <input type="checkbox" class="bg-slate-100 text-right pr-1 mt-2 mr-2" v-model="field.values[index].with_input" />
                            </div>
                            <div class="bg-slate-100 p-1 flex rounded-b-xl md:rounded-non md:rounded-r-full pl-4 md:pl-0">
                                <div class="input-group-text md:pt-[5px]">
                                    Score:
                                </div>
                                <input type="number" :max="field.values.length" min="0" class="bg-slate-100 text-right pr-1" v-model="field.values[index].score" />
                                <button class="btn btn-outline-danger delete pr-3 rounded-r-full hover:text-red-400 ease-in-out duration-150" @click="deleteValue(field, index)"><i class="fas fa-times scale-[1.3]"></i></button>
                            </div>
                        </div>
                    </template>

                    <button class="bg-slate-200 p-2 px-4 mt-2 rounded-xl md:rounded-non md:rounded-full md:hover:shadow-sentiment md:hover:scale-[1.05] hover:bg-slate-300 md:ease-in-out md:duration-200" @click="addValueField(field)">Waarde toevoegen</button>
                </div>
            </div>

            <div class="mt-4" v-if="['group', 'additional', 'employees'].indexOf(field.type) == -1">
                <div class="col-sm-6">
                    <div class="checkbox required">
                        <label class="hover:cursor-pointer">
                            <input class="h-5 w-5 rounded-md hover:cursor-pointer" type="checkbox" v-model="field.required">
                            Verplicht
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'form-field',

    components: {
        draggable
    },

    props: [
        'errors',
        'field',
        'delay',
    ],

    data: () => ({
        id: null,
        order: 0,
        name: null,
        required: 0,
        values: {},
        type: '',
        deleted: false,
        fields: [],
        drag: false,
        dragTimeout: null,
	}),

    methods: {
        click(index, max) {
            this.field.fields[index].values = {
                min: 1,
                max: max,
            }
        },

        getStyle(field) {
            return {
                height: typeof field.fields !== 'undefined' && field.fields.length == 0 ? '150px' : '',
                border: (typeof field.fields !== 'undefined' && field.fields.length == 0) ? '1px dashed grey' : 'none',
                borderRadius: (typeof field.fields !== 'undefined' && field.fields.length == 0) ? '10px' : '0',
                minHeight: typeof field.fields == 'undefined' || field.fields.length == 0 ? '150px' : (field.fields.length * 30 + 250) + 'px',
            }
        },

        dragEnd() {
            if (null !== this.dragTimeout) {
                clearTimeout(this.dragTimeout)
                this.dragTimeout = null
                return;
            }

            this.drag = false

            for (let i = 0; i < this.field.fields.length; i++) {
                this.field.fields[i].order = i
                this.field.fields[i].form_field_group_id = this.field.id
            }
        },

        getFieldTypeName(type) {
            let types = {
                text: 'Tekstregel',
                textarea: 'Tekstvak',
                select: 'Selectbox',
                radio: 'Radiobutton',
                checkbox: 'Checkbox',
                number: 'Cijfer',
                smiley: 'Smileys',
                group: 'Groep',
                scale: 'Schaal',
                additional: 'Aanvullende tekst'
            }

            return types[type]
        },

        fieldError(){
            return typeof this.$props.errors !== 'undefined' &&
                typeof this.$props.errors[0] !== 'undefined' ? this.errors[0] : false
        },

        remove(index) {
            if (
                ['group', 'employees'].indexOf(this.field.type) != -1 &&
                (typeof this.$parent.$parent.$parent.$options.name == 'undefined' ||
                'Admin' != this.$parent.$parent.$parent.$options.name)
            ) {
                // if (this.removeFieldFromGroup(this.field, this.field.fields[index]))
                this.field.fields.splice(index, 1)
                return
            }

            if (
                typeof this.$parent.$options.name !== 'undefined' &&
                'Admin' == this.$parent.$parent.$parent.$options.name
            )
                this.$parent.$parent.$parent.$parent.remove(index)
        },

        removeFieldFromGroup(group, field) {
            if (typeof this.$parent.$parent.removeFieldFromGroup == 'function')
                return this.$parent.$parent.removeFieldFromGroup(group, field);
            else if (typeof this.$parent.$parent.$parent.$parent.removeFieldFromGroup == 'function') {
                console.log('calling $parent.$parent.$parent.$parent')
                return this.$parent.$parent.$parent.$parent.removeFieldFromGroup(group, field)
            }
        },

        fieldValueTemplate()
        {
            return {
                id: null,
                value: null,
                deleted: false,
                score:1,
            }
        },

        addValueField(field)
        {
            if(!Array.isArray(field.values)){
                field.values = []
            }

            field.values.push(this.fieldValueTemplate())
        },

        duplicate(field) {
            if (['group', 'employees'].indexOf(this.field.type) != -1) {
                this.field.fields.push(JSON.parse(JSON.stringify(field)))
                this.field.fields[this.field.fields.length - 1].id = null
            }
        },

        deleteValue(field, index) {
            let idx = this.field.fields.findIndex(el => el.id == field.id || el.fields.findIndex(sel => sel.id == field.id) !== -1)
            if (-1 == idx) {
                // field does not exist?
                console.log('Field not found!')
                return
            }

            if (['employee', 'group'].indexOf(this.field.fields[idx].type) !== -1) {
                let sidx = this.field.fields[idx].fields.findIndex(el => el.id == field.id)

                field = this.field.fields[idx].fields[sidx]
            } else
                field = this.field.fields[idx]

            if (typeof field.values !== 'undefined' &&
                typeof field.values[index] !== 'undefined'
            ) {
                if (typeof field.values[index]['id'] !== 'undefined') {
                    field.values[index].deleted = true
                    this.$forceUpdate()
                } else
                    field.values.splice(index, 1)

                return
            }

            console.log('value not found on ', field)
        }
    }
}
</script>