<template>
    <div class="box-shadow shadow-sentiment md:hover:shadow-sentimentDarker md:hover:scale-[1.02] md:ease-in-out md:duration-200 rounded-xl p-4 md:p-8 overscroll-none w-full bg-white mb-4" :class="xClass">
        <p class="font-bold text-black font-montserrat text-lg mb-4">Afdelingen</p>

        <div class="grid md:grid-cols-4" v-if="departments.length > 0">
            <x-list-item v-for="department in departments" :key="department.id"
                id="id" name="name" :item="department" @edit="editDepartment(department.id)"
                @remove="removeDepartment(department.id)"
                :isRemoveAllowed="typeof department.employees != 'undefined' && department.employees.length == 0" />
        </div>

        <p class="text-black text-base" v-else>Er bestaan geen afdelingen in deze vestiging</p>
    </div>
</template>

<script>
export default {
    props: ['departments', 'x-class'],

    methods: {
        editDepartment: function (id) {
            this.$emit('editDepartment', id)
        },

        removeDepartment(id) {
            this.$emit('removeDepartment', id)

        },
    }
}
</script>