<template>
  <footer class="footer footer-transparent">
    <div class="container-fluid">
      <div class="copyright" :class="{'text-white': !!textWhite }">
          © made with love by Toscani
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",

  props: [
    'textWhite'
  ]
}
</script>

<style scoped>
.login-page .footer .copyright {
  text-align: center !important;
  float: none !important;
}
</style>