<template>
    <AppLayout :noLogo="true" xClass="pb-0">
        <template>
            <template v-if="checkDate() && form && (('employee' == form.type) ||! period.user_finshed)">
                <Form :form="form" :formValues="values[form.id]" :failures="errors" @send-form="send" :coworkers="coworkers" @value-changed="valueChanged" :bus-component="bus"/>
            </template>

            <div v-if="period.user_finished && (typeof form == 'undefined' || null == form || 'employee' !== form.type)">
                <p class="alert alert-info">Je hebt de vragenlijst al ingevuld. Jouw uitslag krijg je binnenkort in je mail, wanneer minstens 50 anderen het ook hebben ingevuld.</p>
                <router-link :to="{
                    name: 'sentiment-dashboard'
                }">&blacktriangleleft; Terug naar het dashboard</router-link>
            </div>

            <div v-if="! checkDate()">
                <p class="alert alert-info">De periode om deze vragenlijst in te vullen is al voorbij. Je kunt de vragenlijst nu dus niet meer invullen.</p>
            </div>
        </template>
    </AppLayout>
</template>

<script>
import axios from 'axios'
import Form from '../../components/Form'
import moment from 'moment'

export default {
    name: 'sentiment-form',

    components: {
        Form
    },

    data: () => ({
            form: {},

            period: {},
            errors: {},

            currentFieldIdx: 0,
            values: {},

            coworkers: [],
            main: null,
            submittedFormId: null,

            debounceTimeout: null,
	}),

    created: function () {
        this.getForm()
        this.main = this
        this.$root.bus = this
    },

    computed: {
        bus() {
            return this.main
        },

        field() {
            return typeof this.form != 'undefined' &&
                typeof this.form.own_fields != 'undefined' &&
                this.form.own_fields.length >= this.currentFieldIdx ?
                    this.form.own_fields[this.currentFieldIdx] : false
        }
    },

    beforeRouteUpdate() {
        this.form = null
    },

    methods: {
        longValid() {
            let till = moment(this.period.till)

            return Math.floor(moment(till).diff(moment(), 'years')) > 1
        },

        checkDate() {
            return moment().isSameOrBefore(this.period.till, 'day')
        },

        getForm() {
            axios.get(`/sentiment/${this.$route.params.period_id}/form`).then(response => {
                this.form = response.data.form
                this.period = response.data.period
                if (typeof response.data.submittedFormId != 'undefined')
                    this.submittedFormId = response.data.submittedFormId

                // for (let idx in this.forms) {
                let form = this.form
                if (typeof form !== 'undefined' && null !== form) {
                    if ('employee' == this.form.type) {
                        // load employees
                        axios.get(`/sentiment/${this.$route.params.period_id}/coworkers`).then(res2 => {
                            this.coworkers = this.shuffle(res2.data)

                            this.values = {}
                            this.values[form.id] = {}
                            for (let employee of res2.data) {
                                this.values[this.form.id][employee.id] = {}

                                if (typeof response.data.submission !== 'undefined' &&
                                    typeof response.data.submission[employee.id] !== 'undefined'
                                ) {
                                    for (let index in response.data.submission[employee.id]) {
                                        let value = null
                                        if(typeof response.data.submission[employee.id][index].subvalue !== 'undefined') {
                                            value = response.data.submission[employee.id][index]
                                        } else if (typeof response.data.submission[employee.id][index].id !== 'undefined') {
                                                value = response.data.submission[employee.id][index]
                                        } else if (typeof response.data.submission[employee.id][index].value !== 'undefined')
                                            value = response.data.submission[employee.id][index].value
                                        else if (Array.isArray(response.data.submission[employee.id][index])) {
                                            value = {}
                                            response.data.submission[employee.id][index].forEach(el => {
                                                value[el.id] = el
                                            })
                                        } else
                                            value = response.data.submission[employee.id][index]

                                        this.values[this.form.id][employee.id][index] = value
                                    }
                                }
                            }
                        })
                        return
                    }

                    for (let key in this.form.own_fields) {
                        let field = form.own_fields[key]
                        if (typeof this.values[form.id] == 'undefined')
                            this.values[form.id] = {}

                        if (typeof response.data.submission != 'undefined' &&
                            typeof response.data.submission[field.id] != 'undefined'
                        ) {
                            this.values[form.id][field.id] = null

                            if (['checkbox', 'radio', 'select'].indexOf(field.type) !== -1) {
                                if (typeof response.data.submission[field.id] !== 'undefined')
                                    this.values[form.id][field.id] = response.data.submission[field.id]

                                continue
                            }

                            if (typeof response.data.submission[field.id].value !== 'undefined')
                                this.values[form.id][field.id] = response.data.submission[field.id].value

                            continue
                        }

                        if (field.type == 'checkbox') {
                            this.values[form.id][field.id] = {}
                            for (let value in field.values) {
                                this.values[form.id][field.id][field.values[value].id] = false
                            }

                            continue
                        }

                        if (field.type == 'group' || field.type == 'employees') {
                            this.values[form.id][field.id] = {}
                            for (let field in field.fields) {
                                this.values[form.id][field.id][field.fields[field].id] = null
                            }

                            continue
                        }

                        this.values[form.id][field.id] = null
                    }


                    return
                }

                if (this.period.user_finished && typeof this.form !== 'undefined' && null !== this.form && this.form.type !== 'employee') {
                    this.$router.push({
                        name: 'sentiment-dashboard'
                    })
                }
            })
        },

        valueChanged(field, value, employee = null) {
            if (null !== employee) {
                if (-1 == this.coworkers.findIndex(coworker => coworker.id == employee.id))
                    return

                if (typeof this.values == 'undefined')
                        this.values = {}

                    if (typeof this.values[this.form.id] == 'undefined')
                        this.values[this.form.id] = {}

                    if (typeof this.values[this.form.id][employee.id] == 'undefined')
                    this.values[this.form.id][employee.id] = {}

                this.values[this.form.id][employee.id][field.id] = value
                return
            }

            let data = {
                value: value
            }

            if (null !== employee)
                data.employee = employee.id

            if (this.debounceTimeout !== null)
                clearTimeout(this.debounceTimeout)

            this.debounceTimeout = setTimeout(() => {
                if (this.submittedFormId !== null && typeof field.id !== 'undefined') {
                    axios.put(`/sentiment/${this.$route.params.period_id}/${this.submittedFormId}/${field.id}`, data)
                } else if (typeof field.id !== 'undefined') {
                    axios.post(`/sentiment/${this.$route.params.period_id}/${field.id}`, data).then(response => {
                        this.submittedFormId = response.data.id
                    })
                }
            }, 500)

            if (field.form_field_group_id !== null) {
                if (typeof this.values[field.form_id] == 'undefined')
                this.values[field.form_id] = {}

                if (typeof this.values[field.form_id][field.form_field_group_id] == 'undefined')
                    this.values[field.form_id][field.form_field_group_id] = {}

                this.values[field.form_id][field.form_field_group_id][field.id] = value
                return
            }

            this.values[field.form_id][field.id] = value
        },

        async send(values, employee = null, callback = null, catcher = null) {
            let data = {
                form_id: this.form.id
            }

            data.fields = {}
            for(let idx in this.form.own_fields) {
                let field = this.form.own_fields[idx]

                if (['group', 'employee'].indexOf(field.type)) {
                    for(let jdx in field.fields) {
                        let subField = field.fields[jdx]
                        data.fields[subField.id] = values[subField.id]
                    }
                //     data.fields[field.id] = values[field.id]
                //     continue
                }

                data.fields[field.id] = values[field.id]
            }

            if ('employee' == this.form.type) {
                data.about = employee.id
            }

            await axios.post(`/sentiment/${this.$route.params.period_id}`, data).then(response => {
                if (response.status == 201 || 200 == response.status) {
                    this.errors = {}
                    this.$notify({
                        type: "success",
                        title: "Sentiment opgeslagen",
                    })

                    if (null !== callback) {
                        callback() // call callback
                    } else
                        // go to sentiment-sent
                        this.$router.push({
                            name: 'sentiment-sent'
                        })
                }

            }).catch(error => {
                if (error.response && error.response.status == 422 && error.response.data.errors) {
                    this.currentFieldIdx = 0;
                    for (let key in error.response.data.errors) {
                        this.errors[key] = error.response.data.errors[key]
                    }
                }
                if (typeof catcher == 'function')
                    catcher() // call catcher function
                else
                    this.$forceUpdate() // force re-render
            })

        },

        shuffle(a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        },
    },
}
</script>