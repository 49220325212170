<template>
  <Admin :title="'E-mail bewerken'">
    <template>
      <div class="text-black w-3/4">

          <form @submit.prevent="update">
              <p class="text-black italic text-sm">
                <span v-if="mail.slug == 'mail.footer'">Dit is de footer onder elke verzonden mail<br></span>
                <span v-if="mail.slug == 'mail.header'">Dit is de header boven elke verzonden mail<br></span>
                <span v-if="mail.slug == 'user.created'">Deze mail word verstuurd naar de nieuwe gebruikers<br></span>

                In de body van de mail kunnen de volgende html-tags gebruikt worden: &lt;a&gt;, &lt;img&gt; & &lt;br&gt;
              </p>

              <div class="mb-4 mt-2">
                  <label class="font-bold text-base text-monsterrat mr-4">Onderwerp</label>
                  <input type="text" v-model="mail.subject" class="bg-gray-100 rounded-full box-shadow shadow-sentiment hover:shadow-sentimentDarker hover:scale-[1.01] ease-in-out duration-200 w-full h-input py-2 px-4 mt-1" :class="{ 'is-invalid': null !== errors.subject }" />

                  <div class="invalid-feedback d-block" v-if="null !== errors.subject">
                    {{ errors.subject[0] }}
                  </div>
              </div>
              <div class="mb-2">
                  <label class="font-bold text-base text-monsterrat">Body</label>
                  <textarea rows="10" v-model="mail.body" v-html="mail.body" class="bg-gray-100 rounded-xl box-shadow shadow-sentiment hover:shadow-sentimentDarker hover:scale-[1.01] ease-in-out duration-200 w-full py-2 px-4 mt-1" :class="{ 'is-invalid': null !== errors.body }"></textarea>

                  <div class="invalid-feedback d-block" v-if="null !== errors.body">
                    {{ errors.body[0] }}
                  </div>
              </div>

              <div class="mb-20">
                Je kunt de volgende placeholders gebruiken: <br>
                <div>- <strong v-html="'{{ app_url }}*'"></strong>: voor de url van de applicatie</div>
                <div>- <strong v-html="'{{ app_name }}*'"></strong>: voor de naam van de applicatie, kan ook in het onderwerp worden gebruikt</div>
                <div>- <strong v-html="'{{ name }}*'"></strong>: voor de naam van de ontvanger</div>
                <div>- <strong v-html="'{{ company }}*'"></strong>: voor de bedrijfsnaam</div>
                <div v-if="mail.slug == 'period.multiple_starting'">- <strong v-html="'{{ periods }}'"></strong>: voor de periodes (verplicht!)</div>

                <div class="[@media(max-width:865px)]:mb-[180px]"><small><strong>*</strong> Kan in elke mail gebruikt worden</small></div>
              </div>

              <div class="bottom-6 right-6 fixed w-max">
                <x-button-blue class="[@media(min-width:1050px)]:mr-2 [@media(min-width:1050px)]:inline-block [@media(max-width:1049px)]:mb-2" @click="$router.push({name: 'toscani-mail-index'})">Annuleren</x-button-blue>
                <x-button-green class="[@media(min-width:865px)]:mr-2 [@media(min-width:865px)]:inline-block [@media(max-width:864px)]:mb-2" v-if="mail.slug != 'mail.header' && mail.slug != 'mail.footer'" @click="preview">Voorbeeld</x-button-green>
                <x-button-green class="col-start-2 md:inline-block" @click="submit">Opslaan</x-button-green>
              </div>
          </form>
      </div>
    </template>
  </Admin>
</template>

<script>
import Axios from "axios";

export default {
  name: "MailEdit",

  data() {
    return {
        mail: {},
        errors: {
          subject: null,
          body: null,
        }
     };
  },

  created: function () {

    Axios.get(`/toscani/mails/${this.$route.params.id}`).then(
     (response) => {
       this.mail = response.data;
     }
    );
  },
  methods: {
      submit(){
          let _self = this;
          Axios.put(`/toscani/mails/${this.$route.params.id}`, this.mail).then(function(){
            _self.$notify({
                    text: 'E-mail opgeslagen',
                    type: 'success',
            })
            _self.$router.push({
                name: 'toscani-mail-index'
            })
          }).catch(error => {
            if (422 == error.response.status) {
              Object.assign(this.errors, error.response.data.errors)
            }
          })
      },

      preview(ev) {
        ev.preventDefault()
        Axios.put(`/toscani/mails/${this.$route.params.id}/preview`, this.mail).then(response => {
          this.$modal.show({
            template: `
            <div class="p-4 text-black">
              <div><strong>Onderwerp:</strong> {{ subject }}</div>
              <div><strong>Bericht:</strong></div>
              <div v-html="preview" id="mail-body"></div>
            </div>
            `,
            props: [
              'subject',
              'preview'
            ],
            mounted() {
              console.log(this)
              document.querySelectorAll('#mail-body a').forEach(el => {
                el.addEventListener('click', function ($event) {
                  $event.preventDefault()
                })
              })
            }
          }, {
            subject: response.data.subject,
            preview: response.data.preview,
          },
          {
            adaptive: true,
            minHeight: 300,
            height: 'auto',
            width: '60%',
            scrollable: true
          })
        })
      }
  }
};
</script>

<style scoped>
textarea.bg-gray-100 rounded-full w-input h-input py-2 px-4 {
  max-height: initial;
}
</style>