<template>
    <FormField :name="field.name" :idx="idx" :errors="errors" :bus="bus">
        <template #input>
            <input type="number" @change="change($event)" v-model="value_"
                :min="field.values[0] === undefined ? 0 : field.values[0].value"
                :max="field.values[1] === undefined ? 5 : field.values[1].value" class="form-control"
                :class="iClass">
        </template>
    </FormField>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
    name: 'NumberField',

    mixins: [formMixin],
}
</script>