<template>
    <div class="w-full h-full">
        <aside v-if="$slots.menu" class="fixed bg-sentiment-blue bg-gradient-to-r from-sentiment-dark-gradient h-screen w-menu shadow-sentimentAside hidden md:flex py-6 pl-3.5 overflow-y-auto">
            <slot name="menu" />
        </aside>

        <main id="main-element" class="flex flex-col px-6 md:px-12 justify-items-center h-screen overflow-y-auto" :class="mainClass" @click="closeMenuIfOpen">
            <p class="font-semibold text-title text-3xl leading-title"><slot name="pagetitle" />{{ pagetitle }}</p>

            <p class="font-semibold text-2xl md:text-4xl text-sentiment-blue mb-4 md:mb-6" v-html="title || $slots.title"/>

            <slot />
        </main>

        <div v-if="$slots.menu"
            class="fixed bottom-0 left-0 top-0 z-[1045] flex max-h-full max-w-full -translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out md:hidden"
            tabindex="-1"
            :class="isOpen ? 'transform-none' : 'invisible'">

            <div class="flex flex-col pt-[92px] pl-[4px] pb-[25px] justify-between bg-sentiment-blue bg-gradient-to-r from-sentiment-dark-gradient w-[289px] h-screen font-poppins font-medium overflow-y-auto shadow-[0_0_25px_5px_rgba(0,0,0,0.7)]">
                <button
                    type="button"
                    class="absolute top-[25px] left-[235px] text-sentiment-green box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    @click="isOpen = false">
                    <span
                        class="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 22.4L9.44 9.408V12.544L0.448 0H5.152L11.84 9.28L9.92 9.312L16.544 0H21.056L12.128 12.32V9.312L21.632 22.4H16.832L9.792 12.544H11.68L4.736 22.4H0Z" fill="currentColor"/>
                        </svg>
                    </span>
                </button>

                <slot name="menu" />
            </div>
        </div>

        <div class="w-full z-[-1] fixed top-0 bg-white/80 h-screen overflow-y-auto"></div>

        <router-link :to="{name: 'Home', query: {admin: 1}}" v-if="$route.path.substring(1, 10) == 'sentiment' && typeof $store.user !== 'undefined' && ($store.is_admin || $store.is_superadmin)">
            <img :src="$root.settings.logo ? $root.settings.logo : '/assets/img/sentiment-logo.png'" alt="logo" :class="{ 'md:left-80': $slots.menu, 'hidden': noLogo, 'md:block': noLogo }" class="left-[24px] bottom-6 fixed w-30 h-logo">
        </router-link>
        <img v-else :src="$root.settings.logo ? $root.settings.logo : '/assets/img/sentiment-logo.png'" alt="logo" :class="{ 'md:left-80': $slots.menu, 'hidden': noLogo, 'md:block': noLogo }" class="left-[24px] bottom-6 fixed w-30 h-logo">

        <a v-if="$slots.menu" class="block md:hidden w-[22px] h-[12px] fixed left-[24px] top-[25px]" href="#" @click="isOpen = !isOpen">
            <div class="w-[22px] h-[0px] border-[1px] border-[#0A0A49] mb-[4px] rounded"></div>
            <div class="w-[22px] h-[0px] border-[1px] border-[#0A0A49] mb-[4px] rounded"></div>
            <div class="w-[22px] h-[0px] border-[1px] border-[#0A0A49] rounded"></div>
        </a>

    </div>
</template>

<script>
export default {
    data: () => ({
            isOpen: false,
	}),

    props: {
        'noLogo': {
            type: Boolean,
            default: false,
        },
        'pagetitle': {
            type: String,
            default: '',
        },
        'title': {
            type: String,
            default: '',
        },
        'x-class': {
            type: [String, Object],
            default: 'md:max-w-[85%] pb-20 pt-12'
        }
    },

    computed: {
        mainClass() {
            let classes = {}, pt12 = false
            let given = typeof this.xClass == 'string' ?
                this.xClass.split(' ') :
                Object.keys(this.xClass)

            for (let key in given) {
                if ('pt-' == key.substring(0, 2))
                    pt12 = true

                classes[given[key]] = true
            }

            classes['md:ml-72'] = typeof this.$slots.menu !== 'undefined'
            if (! pt12) {
                classes['pt-12'] = true
            }

            return classes
        }
    },

    methods: {
        closeMenuIfOpen() {
            if (this.isOpen)
                this.isOpen = false
        }
    }
}
</script>