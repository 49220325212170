<template>
    <Admin title="Resultaten">
        <template>
            <div class="md:block mr-18v">
                <span class="font-bold">Vraag</span>
            </div>

            <div v-for="question in questions" class="grid grid-cols-Results md:grid-cols-4 md:mr-18v text-black pt-5" :key="question.id">
                <router-link :to="{ name: 'result-view', params: {id: question.id}}" class="md:col-span-3 font-semibold md:font-normal hover:text-sentiment-green ease-in-out duration-200">{{ question.name }}</router-link>
                <router-link :to="{ name: 'result-view', params: {id: question.id}}">
                    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill-sentiment-green" d="M28.0838 21.0585H0V0H3.15986V17.8987H28.0838V21.0585Z"/>
                        <path class="fill-sentiment-blue" d="M15.8844 13.6357L10.7448 8.93228L6.98637 12.6223L4.77148 10.369L10.6679 4.57947L15.8844 9.35131L21.0454 4.6265L23.179 6.95684L15.8844 13.6357Z"/>
                        <path class="fill-sentiment-blue" d="M27.6003 0.769657C24.1412 2.17641 19.4463 3.47199 15.876 3.55751L21.3961 6.44798L23.7906 12.199C24.1925 8.65004 25.8986 4.08771 27.6003 0.765381V0.769657Z"/>
                    </svg>
                </router-link>
            </div>
        </template>

    </Admin>
</template>

<script>
import axios from 'axios';

export default {
    data: () => {
        return {
            laravelData: null,
        };
    },

    computed: {
        questions() {
            if (null == this.laravelData)
                return [];

            return this.laravelData.data
        }
    },

    created: function () {
        axios.get("admin/form?onlyWithSubmission").then(response => {
            this.laravelData = response.data;
        });
    },
}
</script>