<template>
    <Admin title="Login opties">
        <template #content>
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="useOtp" @click="change($event)">
                            <label class="custom-control-label pt-1" for="customSwitch1">Twee-factor-authenticatie gebruiken</label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Admin>
</template>

<script>
import Axios from 'axios'
export default {
    name: 'LoginOptions',

    data: () => ({
            useOtp: null,
	}),

    created() {
        if (this.$store.user && this.$store.user.google2fa_secret)
            this.useOtp = true
        else
            this.useOtp = false

    },

    watch: {
        useOtp(newValue, oldValue) {
            let _self = this
            if (newValue === true && oldValue === false) {
                Axios.post('/auth/2fa').then(response => {
                    if (response.data['qr-code'] !== undefined) {
                        this.$modal.show({
                            template: `
                            <div>
                                <h1>Twee factor authenticatie</h1>
                                <img :src="src">
                                <p>Scan de qr-code met de Authenticator-app</p>
                                <input type="text" v-model="otp" placeholder="Valideer">
                                <button class="btn btn-primary" @click="validate()">Valideer</button>
                            </div>
                            `,
                                props: ['src'],
                                data: () => ({
                                        otp: null
	}),
                                beforeDestroy() {
                                    if (this.otp == '' || this.otp === null) {
                                        _self.useOtp = false
                                        alert('Je hebt de twee factor authenticatie niet gevalideerd, dus wordt hij niet aangezet.\nWil je toch dat twee factor authenticatie aangaat, zet het dan weer aan, scan de code, en valideer.')
                                    }
                                },
                                methods: {
                                    validate() {
                                        Axios.post('/auth/2fa/validate', {otp: this.otp}).then(() => {
                                            _self.$notify({
                                                text: 'Twee factor authenticatie aangezet',
                                                type: 'success'
                                            })
                                            this.$emit('close')
                                        }).catch(() => {
                                            _self.useOtp = false
                                            _self.$notify({
                                                text: 'Twee factor authenticatie niet aangezet.<br>Probeer het nog eens',
                                                type: 'danger'
                                            })
                                            this.$emit('close')
                                        })
                                    }
                                }
                            }, {
                                src: response.data['qr-code']
                            }, {
                                height: 'auto',
                            })
                    }
                })
            } else if (oldValue === true) {
                window.localStorage.removeItem('remindToken')
                Axios.delete('/auth/2fa').then(() => {
                    _self.$notify({
                        text: 'Twee factor authenticatie uitgezet.',
                        type: 'success'
                    })
                })
            }
        }
    },

    methods: {
        change($event) {
            if (! $event.target.checked && this.useOtp && confirm('Weet je zeker dat je twee factor authenticatie wilt uitzetten?') ||
                ($event.target.checked &&! this.useOtp)
            ) {
                return true
            }

            $event.preventDefault()
            return false
        }
    }
}
</script>