<template>
    <Admin title="Periodes">
      <template>
        <div class="mb-8 grid grid-cols-2 gap-8">
          <div class="flex flex-col [@media(min-width:1175px)]:flex-row gap-2 [@media(min-width:1175px)]:gap-4">
            <input id="name" placeholder="Zoeken op naam" @keyup="fnFilter($event)" class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray" v-model="filter.name">
            <input id="from" placeholder="Vanaf" :type="from_type" @focus="() => {from_type = 'date'}" @change="fnFilter($event)" class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray" v-model="filter.from">
            <input id="till" placeholder="Tot" :type="till_type" @focus="() => {till_type = 'date'}" @change="fnFilter($event)" class="rounded-lg border-sentiment-green border-3 w-full [@media(min-width:1175px)]:w-46 px-2 active:border-sentiment-green text-sentiment-gray" v-model="filter.till">
          </div>
          <x-button-green @click="create()" class="justify-self-end col-start-2 [@media(max-width:450px)]:min-w-max">Toevoegen</x-button-green>
        </div>

        <div class="hidden md:grid grid-cols-4 xl:mr-10v mb-2" ref="listHeader">
          <span class="font-bold">Naam</span>
          <span class="font-bold">Periode</span>
          <span class="font-bold">Vraag</span>
          <span>&nbsp;</span>
        </div>

        <div class="hidden md:grid grid-cols-4 xl:mr-10v overflow-y-scroll md:scrollbar-none">
          <template v-for="period in periods">
              <router-link class="block font-semibold md:font-normal text-black mb-2 hover:text-sentiment-green ease-in-out duration-200" :key="'a' + period.id" :to="toEdit(period.id)">{{ period.name }}</router-link>
              <span class="block my-1 text-black" :key="'b' + period.id">{{ period.fromFormatted }} t/m {{ period.tillFormatted }}</span>
              <span class="block my-1 text-black" :key="'c' + period.id">{{ period.form.name }}</span>

              <span :key="'d' + period.id">
                <a @click="edit(period.id)" :title="'\'' + period.name + '\' bewerken'" class="mr-2 cursor-pointer hover:scale-[1.15] ease-in-out duration-150">
                  <i class="fa-regular fa-pen-to-square text-[23px]"></i>
                </a>

                <a role="button" :title="'\'' + period.name + '\' verwijderen'" @click="remove(period.id)" class="cursor-pointer hover:scale-[1.15] hover:text-red-400 ease-in-out duration-150">
                  <i class="fa-solid fa-trash text-[23px] hover:text-red-400 ease-in-out duration-200"></i>
                </a>
              </span>
          </template>
        </div>

        <div class="md:hidden overflow-y-scroll mb-2" ref="listMobile">
          <template v-for="period in periods">
            <div class="grid grid-cols-3 mb-5" :key="period.id">
                <span class="col-span-2">
                    <router-link class="font-semibold block text-black text-sm" :to="toEdit(period.id)">{{ period.name }}</router-link>
                    <p class="block text-black text-sm">{{ period.fromFormatted }} t/m {{ period.tillFormatted }}</p>
                    <p class="block text-black text-sm">{{ period.form.name }}</p>
                </span>

                <span class="self-center grid grid-cols-4">
                    <a @click="edit(period.id)" :title="'\'' + period.name + '\' bewerken'">
                        <svg width="23.82" height="23" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline">
                    <path d="M22.3474 27.6818H0V2.93945H15.1032V4.59799H1.5602V26.0233H20.793V17.521H22.3474V27.6818Z" fill="currentColor"/>
                    <path d="M28.4314 5.02354L24.0002 0.54118C23.2752 -0.192521 22.1558 -0.18019 21.4482 0.578174L9.23335 13.5628C8.38075 14.4692 7.91675 15.6715 7.91675 16.9477V21.0786C7.91675 21.7075 8.39815 22.2131 8.98395 22.2131H13.044C14.2504 22.2131 15.3814 21.7137 16.2282 20.8074L28.4662 7.79804C28.82 7.42811 29.0056 6.9287 28.9998 6.40462C28.994 5.87439 28.791 5.38731 28.4314 5.02354ZM15.1204 19.6421C14.5636 20.234 13.8212 20.5607 13.0382 20.5607H9.47115V16.9539C9.47115 16.1154 9.77855 15.3323 10.3353 14.7405L19.0876 5.43663L23.7914 10.4369L15.1204 19.6421ZM27.3642 6.63275L24.8934 9.25928L20.1896 4.25901L22.556 1.74346C22.6604 1.63248 22.8228 1.63248 22.933 1.7373L27.3642 6.21966C27.4338 6.29364 27.4454 6.37996 27.4454 6.42312C27.4396 6.47245 27.4338 6.5526 27.3642 6.63275Z" fill="currentColor"/>
                </svg>
                </a>

                <a role="button" :title="'\'' + period.name + '\' verwijderen'" @click="remove(period.id)">
                  <svg width="18.24" height="23" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline">
                    <path d="M1.89062 7.32544H20.8046L19.544 29C14.0816 29 8.61362 29 3.15119 29C2.731 21.7732 2.31081 14.5522 1.89062 7.32544Z" fill="currentColor"/>
                    <path d="M14.8835 1.7284V0H7.80664V1.7284H0V4.988H22.6956V1.7284H14.8835Z" fill="currentColor"/>
                  </svg>
                </a>
              </span>
            </div>
          </template>
        </div>

        <Pagination :data="laravelData" :limit="-1" @pagination-change-page="getResults"></Pagination>
      </template>
    </Admin>
  </template>

  <script>
  import Axios from "axios";

  export default {
    name: "PeriodIndex",

    data() {
      return {
        all: false,
        filter: {
          name: null,
          from: null,
          till: null,
        },

        from_type: 'text',
        till_type: 'text',
        laravelData: {},

        debounceTimeout: null,
      };
    },

    mounted: function () {
      this.getResults()
    },

    computed: {
      periods: function () {
        return this.laravelData.data;
      },

      listHeight() {
        if (
          typeof this.$refs.listHeader.checkVisibility !== 'undefined' &&
          this.$refs.listHeader.checkVisibility()
        ) {
          return ((document.documentElement.clientHeight - this.$refs.listHeader.offsetTop) + 300)
        }

        return Math.floor((document.documentElement.clientHeight - (this.$refs.listMobile.offsetTop * 2.3)))
      }
    },

    methods: {
        getResults(page = null) {
          let params = new URLSearchParams([['page', page], ['per_page', Math.max(1, Math.floor(this.listHeight / 56))]])

          if (this.all) {
            params.set('all', 1)
          }

          if (null !== this.filter.name && '' !== this.filter.name)
            params.set('filter[name]', this.filter.name)

          if (null !== this.filter.from && '' !== this.filter.from)
            params.set('filter[from]', this.filter.from)

          if (null !== this.filter.till && '' !== this.filter.till)
            params.set('filter[till]', this.filter.till)

          Axios.get(`admin/period`, {params}).then(response => {
            this.laravelData = response.data
            window.scrollTo(0,0)
          })
        },

        create() {
          this.$router.push({
              name: 'period-edit',
              params: {
                  id: 'create'
              },
          })
        },

        edit: function (id) {
            this.$router.push(this.toEdit(id))
        },

        toEdit(id) {
          return {
              name: 'period-edit',
              params: {
                  id: id
              },
          }
        },

        remove: function (id) {
          let idx = this.periods.findIndex((period) => period.id == id)
            let _self = this
            if (confirm(`Weet je zeker dat je '${this.periods[idx].name}' wilt verwijderen?`)) {
                Axios.delete(`admin/period/${this.periods[idx].id}`).then(() => {
                    _self.periods.splice(idx, 1);
                })
            }
        },

        fnFilter(event) {
          if (null !== this.debounceTimeout)
            clearTimeout(this.debounceTimeout)

          if ((this.filter.name == null || this.filter.name.length < 3 ) &&
            (this.filter.from == null/*  || this.filter.from == '' */) &&
            (this.filter.till == null/*  || this.filter.till == '' */)
          )
            return;

          this.debounceTimeout = setTimeout(() => {
            this.getResults()
          }, 250)
        },
    },
  };
  </script>